import React from "react";
import { Container, Row, Col, Tab, Tabs, Nav } from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import "../assets/sass/profile_page.scss";


const KycPage = () => {
  
  return (
    <Layout>
      <div className="kyc-profile">
        <Container>
        <iframe className="responsive-iframe" src="https://in.sumsub.com/idensic/l/#/sbx_xh3Nd8bGzk91Ogc0" />
        </Container>
      </div>
    </Layout>
  );
};

export default KycPage;
