import Cookies from "js-cookie";
import "./App.scss";
import HomePage from "./pages/HomePage";
import Layout from "./components/Layout/Layout";
import React, { useEffect } from "react";

require("./bootstrap");
window.Buffer = window.Buffer || require("buffer").Buffer;

function getToken() {
  return Cookies.get("access_token", { domain: window.location.hostname });
}
const App = () => {
  return (
    <Layout>
      <HomePage />
    </Layout>
  );
};

export default App;
