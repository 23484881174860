'use strict'

import _ from 'lodash'
//import { auth } from './../services/Authen'
import Cookies from "js-cookie";

export default {

    getProducts(cb, errorCb, request = {}) {
        var params = {};
        let access_token = Cookies.get("access_token", { domain: window.location.hostname });
        if (access_token == "null" || access_token === undefined) {
            params = request;
        } else {

            let isLogin = { is_login: true };
            params = Object.assign({}, request, isLogin);
        }
            window.axios.get('v1.1/products', { params: params })
                .then(response => cb(response))
                .catch(err => {
                    if (typeof errorCb === 'function') {
                        errorCb(err.response)
                    }
                })

    },

    getProduct(cb, errorCb,request = {}) {
        let header = {
            'Accept-Language': 'en',
            'Content-Type': 'application/json',

        }
        const id    = _.get(request,'id')
        window.axios.get('v1.1/products/'+id, { headers: header })
                .then(response => cb(response))
                .catch(err => {
                    if (typeof errorCb === 'function') {
                        errorCb(err.response)
                    }
                })

    },
    getProductDetail(cb, errorCb, request={}, id = ''){
        let header = {
            'Accept-Language': 'en',
            'Content-Type': 'application/json',
        }
        var params = {};
        let access_token = Cookies.get("access_token", { domain: window.location.hostname });
        if (access_token == "null" || access_token === undefined) {

            params = request;
        } else {
            let isLogin = { is_login: true };
            params = Object.assign({}, request, isLogin);
        }
        window.axios.get('v1.1/products/' + id, { headers: header, params: params })
        .then(response => cb(response))
        .catch(err => {
            if (typeof errorCb === 'function') {
                errorCb(err.response)
            }
        })
    },
    getActivity(cb, errorCb, request={}) {
        let header = {
            'Accept-Language': 'en',
            'Content-Type': 'application/json',
        }

        window.axios.get('v1.1/activity?collection_id=' + request.collection_id, { headers: header})
        .then(response => cb(response))
        .catch(err => {
            if (typeof errorCb === 'function') {
                errorCb(err.response)
            }
        })
    }
}
