import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../assets/sass/item_nft.scss";
import ic_heart from "../assets/images/icon-heart.svg";
import ic_heart_active from "../assets/images/icon-heart-active.svg";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import env from '../env'
import _ from "lodash";
import { Link } from "react-router-dom";

const ItemNft = ({ products, collection_id, is_search_item }) => {
  function imageSrc(data) {
    let logo = _.filter(data, { tag: "cover" })[0];
    return env.RES_URL + '/w_340,h_340,c_thumb/'+_.get(logo,'id')+'/'+_.get(logo, 'name')
  }

  if (products.length == 0) {
    return (
      <Container>
        <div className="text-center mt-5 pt-5">{is_search_item ?`No Result` : `No Item`}</div>
        <div className="text-center mt-5 pt-5"></div>
      </Container>
    );
  } 

  return products.map((item, index) => (
    <Col xs={12} md={6} lg={4} key={item.id}>
      <Link to={
        `/campaign/${collection_id}/${_.get(item, 'id')}`}
      >
        <div className="box-item">
          {_.get(item,'is_type') ==='video' || _.get(item,'is_type') ==='audio'? 
          <PlayCircleFilledWhiteIcon className="icon-play"/> : null } 
          <img
            src={imageSrc(_.get(item, "images"))}
            className="nft-image"
          ></img>
          <p className="nft-name">{_.get(item, "model")}</p>
          <Container className="_box-item-nft">
            <Row>
              <Col xs="5">
                <div className="airdrop align-self-center text-right">
                  {" "}
                  AIRDROP <span> {_.get(item,'stock')}/{_.get(item,'quantity')} Supply</span>
                </div>
              </Col>
              <Col xs="3">
                <div className="favorite">
                  <div className="center">
                    <img src={_.get(item, "is_favorite", false) ? ic_heart_active : ic_heart}></img>
                  </div>
                  <span className="favorite-sum">({_.get(item, "favorited")})</span>
                </div>
              </Col>
              <Col xs="3" className="d-flex align-items-center">
              {_.get(item,'quantity') > 0? 
                <button className="btn-claim">CLAIM</button>:
                <button className="btn-claim _sold-out">SOLD</button> }
              </Col>
            </Row>
            </Container>
        </div>
      </Link>
    </Col>
  ));
};

export default ItemNft;
