"use strict";

import _ from "lodash";

export default {
  walletConnect(cb, errorCb, request = "") {
    window.axios
      .post("v1.1/wallet/connect", request)
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },
};
