import React,{ useCallback, useState } from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import api from "../api"
import "../assets/sass/home_page.scss";
import _ from "lodash"

const Privacy = () => {
  const [content, setContent] = useState([]);
  const [title, setTitle] = useState([]);
  const [error, setError] = useState(null);

  React.useEffect( ()=> {
    
    let request =  {
      'lang': 'th',
      'group_code': 'page',
      'page': 1,
      'slug':'privacy'
    }

    api.contents.getContent(
        (response) => {
          let data = _.get(response,'data.data.record');
          let find = _.find(data,'fields');
          let content_description = _.find(find.fields, function (d) { return d.name == "description"; });
          let content_title = _.find(find.fields, function (d) { return d.name == "title"; });

          setContent(_.get(content_description,'lang'));
          setTitle(_.get(content_title,'lang'));

        },
        (error) => {
          setError(error);
        }, request)
   
  },[])

  return (
    <Layout>
      <div className="content-section">
        <Container>
          <h2>{title}{''}</h2>
          <div
            dangerouslySetInnerHTML={{__html: content}}
          />
  
        </Container>
      </div>
    </Layout>
  );
};

export default Privacy;
