import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import api from "../api";
import "../assets/sass/item_nft.scss";
import ic_heart from "../assets/images/icon-heart.svg";
import ic_heart_active from "../assets/images/icon-heart-active.svg";
import _ from "lodash";
import { Link } from "react-router-dom";
import env from "../env";

export const FavoritesNft = ({ address }) => {
  const [nftList, setNftList] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    var request = {};
    if (address === undefined) {
    } else {
      request = { uid: address };
    }
    api.users.getUserFavorites(
      (response) => {
        setNftList(response.data.data.record);
      },
      (error) => {
        setError(error);
      },
      request
    );
  }, []);

  function imageSrc(data) {
    var logo = _.first(data);
    return _.get(logo, "url");
  }

  if (nftList.length == 0) {
    return (
      <Container>
        <div className="text-center mt-5 pt-5">No Favorite NFTs</div>
      </Container>
    );
  }

  return nftList?.map((data, index) => (
    <Col xs={12} md={6} lg={4} key={index}>
      <div>
        {data.fileable.image == "" || data.fileable.image === undefined ? (
          <div className="box-item">
            <div className="nft-untitled">
              <div className="p-3">
                <p>Look like we couldn’t process metadata for this NFT</p>
              </div>
            </div>

            <div className="nft-untitled-name">Untited</div>
          </div>
        ) : (
          <Link to={"/campaign/" + _.get(data.collection, "collection_id") + "/"+ _.get(data.fileable, "id")}>
            <div className="box-item _favourite-box">
              <div>
                <img
                  // src={data.fileable.image.id}
                  src={
                    env.RES_URL +
                    "/w_360,h_360,c_thumb/" +
                    data.fileable.image.id +
                    "/" +
                    data.fileable.image.name
                  }
                  className="nft-image"
                ></img>
                <p className="nft-name">{_.get(data.fileable, "model")}</p>
                <Container>
                  <Row>
                    <Col xs="5">
                      <div className="airdrop align-self-center text-right">
                        {" "}
                        AIRDROP <span> {_.get(data.fileable,'stock')}/{_.get(data.fileable,'quantity')} Supply</span>
                      </div>
                    </Col>
                    <Col xs="3">
                      <div className="favorite">
                        <div className="center">
                          <img src={ic_heart_active}></img>
                        </div>
                        <span className="favorite-sum">({_.get(data.fileable, "favorited")})</span>
                      </div>
                    </Col>
                    <Col xs="3" className="d-flex align-items-center">
                    {_.get(data.fileable,'quantity') > 0? 
                      <button className="btn-claim">CLAIM</button>:
                      <button className="btn-claim _sold-out">SOLD</button> }
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Link>
        )}
      </div>
    </Col>
  ));
};
