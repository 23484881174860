import env from "./env.js";
import Cookies from "js-cookie";

window._ = require("lodash");
window.axios = require("axios");
window.$ = window.jQuery = require("jquery");

window.cookie_expired = 365;

window.axios.defaults.baseURL = env.API_URL;
window.domain = window.location.hostname.substr(
  window.location.hostname.indexOf(".") + 1
);
window.axios.defaults.headers.common["Authorization"] =
  "Bearer " + Cookies.get("access_token", { domain: window.location.hostname });

if (window.ethereum) {
  handleEthereum();
} else {
  window.addEventListener("ethereum#initialized", handleEthereum, {
    once: true,
  });

  // If the event is not dispatched by the end of the timeout,
  // the user probably doesn't have MetaMask installed.
  setTimeout(handleEthereum, 3000); // 3 seconds
}

function handleEthereum() {
  const { ethereum } = window;
  if (ethereum && ethereum.isMetaMask) {
    console.log("Ethereum successfully detected!");
    // Access the decentralized web!
  } else {
    console.log("Please install MetaMask!");
  }
}
