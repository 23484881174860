import React, { useEffect, useState } from "react";
import "../assets/sass/table_events.scss";
import avatar from "../assets/images/avatar.png";
import { Col, Row, Table } from "react-bootstrap";
import api from "../api";
import _ from "lodash";
import moment from "moment";
import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import { useMoralisWeb3Api } from "react-moralis";
import { useWeb3React } from "@web3-react/core";
import { supportedChainlist } from "../utils/web3Connect";

const UserActivity = ({ address }) => {
  const { chainId } = useWeb3React();
  const Web3Api = useMoralisWeb3Api();

  const [record, setRecord] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchNFTTransfers();
  }, []);

  const fetchNFTTransfers = async () => {
    if (address === undefined) {
      return;
    }

    if (chainId === undefined) {
      var allActivity = [];

      for (let index in supportedChainlist) {
        let chainHexId1 = "0x" + supportedChainlist[index].toString(16);

        const options = {
          chain: chainHexId1,
          address: address,
          limit: "20",
        };
        // get mainnet NFT transfers for the current user
        const transfersNFT = await Web3Api.account.getNFTTransfers(options);
        allActivity = allActivity.concat(transfersNFT.result ?? []);
        //block_timestamp
        allActivity.sort(function (a, b) {
          var c = new Date(a.block_timestamp);
          var d = new Date(b.block_timestamp);
          return d - c;
        });

        setRecord(allActivity);
      }
    } else {
      let chainHexId1 = "0x" + chainId.toString(16);

      const options = {
        chain: chainHexId1,
        address: address,
        limit: "20",
      };
      // get mainnet NFT transfers for the current user
      const transfersNFT = await Web3Api.account.getNFTTransfers(options);
      setRecord(transfersNFT.result ?? []);
    }
  };

  function handleEvnentString(status) {
    if (status == "0x0000000000000000000000000000000000000000") {
      return (
        <div className="d-flex event-icon">
          <ChildFriendlyIcon /> <span>Minted</span>
        </div>
      );
    } else {
      return (
        <div className="d-flex event-icon">
          <MultipleStopIcon /> <span>Transfer</span>
        </div>
      );
    }
  }

  function handleAddress(address) {
    if (address === "0x0000000000000000000000000000000000000000")
      return "nullAddress";
    if (address === null) return "nullAddress";
    return address;
  }

  function timeAgo(time) {
    return moment(time).fromNow();
  }

  const RowItem = () => {
    return record.map((item, index) => {
      return (
        <tr key={`item-row-${index}`}>
          <td scope="row">{handleEvnentString(_.get(item, "from_address"))}</td>
          <td>
            <div className="d-flex">
              {/* <img src={avatar} alt="Avatar" className="activity_avatar"></img>  */}
              <p>{handleAddress(_.get(item, "from_address"))}</p>
            </div>
          </td>
          <td>
            <div className="d-flex">
              {/* <img src={avatar} alt="Avatar" className="activity_avatar"></img>  */}
              <p>{handleAddress(_.get(item, "to_address"))}</p>
            </div>
          </td>
          <td>
            <p>{timeAgo(_.get(item, "block_timestamp"))}</p>
          </td>
        </tr>
      );
    });
  };

  return (
    <div>
      <Table striped hover responsive variant="dark" className="event-table">
        <thead>
          <tr>
            <th scope="col">Events</th>
            <th scope="col">From</th>
            <th scope="col">To</th>
            <th scope="col">Time</th>
          </tr>
        </thead>
        <tbody>
          <RowItem />
        </tbody>
      </Table>
    </div>
  );
};

export default UserActivity;
