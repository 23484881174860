"use strict";

import _ from "lodash";
import env from "../env";
import axios from 'axios';

export default {
  requestOtp(cb, errorCb, request = {}) {
    window.axios
      .post("v1.1/otp", request)
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },
  verifyOtp(cb, errorCb, request = {}) {
    window.axios
      .post("v1.1/users/phone-verify", request)
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },
  checkPhone(cb, errorCb, request = "") {
    window.axios
      .get("v1.1/users/check" + request, null)
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },
};