import React, { cloneElement } from "react";
import { GlowProvider } from "@glow-app/glow-react";
import { Web3ReactProvider } from "@web3-react/core";
import { getLibrary } from "../utils/web3Connect";
import { WalletProvider } from "./WalletProvider";
import { MoralisApiClientProvider } from "./MoralisApiClientProvider";
import { TheConcertApiClientProvider } from "./TheConcertApiClientProvider";
import { MoralisProvider } from "react-moralis";
import { AuthenProvider } from "./AuthenProvider";
import env from "../env";

function ProviderComposer({ contexts, children }) {
  return contexts.reduce(
    (kids, parent) =>
      cloneElement(parent, {
        children: kids,
      }),
    children
  );
}
export default function ContextProvider({ children }) {
  const serverUrl = env.REACT_APP_MORALIS_SERVER_URL;
  const appId = env.REACT_APP_MORALIS_APP_ID;

  return (
    <ProviderComposer
      // add providers to array of contexts
      contexts={[
        <WalletProvider />,
        <MoralisProvider serverUrl={serverUrl} appId={appId} />,
        <GlowProvider />,
        <Web3ReactProvider getLibrary={getLibrary} />,
        <MoralisApiClientProvider />,
        <TheConcertApiClientProvider />,
        <AuthenProvider />,
      ]}
    >
      {children}
    </ProviderComposer>
  );
}
