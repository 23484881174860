import React, { useCallback, useState } from "react";
import {
  Container,
  Form,
  FormControl,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import api from "../api";
import "../assets/sass/discover.scss";
import _ from "lodash";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import env from "../env";
const Discover = () => {
  const navigate = useNavigate();
  const [q] = useSearchParams();
  const keyword = q.get("q");
  const category_id = q.get("category_ids");
  const [query, setQeury] = useState("");
  const [collections, setCollections] = useState([]);
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);

  let query_request = "";

  const handleClassActive = (e) => {
    let category_ids = window.$("#collection-cateogry .category");
    let category_id = window.$(e.target).data("id");

    if (category_ids.length > 0) {
      category_ids.map((item, i) => {
        window.$(i).removeClass("active");
      });
    }
    window.$(e.target).addClass("active");
    if (category_id === "all") {
      navigate("/discover");
    } else {
      navigate("?category_ids=" + category_id);
    }
  };

  window.$('input[id="searchCollection"]').attr("value", keyword);
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setQeury(query);
  };

  if (category_id) {
    query_request = query_request + "&category_ids=" + category_id;
  }

  React.useEffect(() => {
    let keyword = q.get("q");
    let request = "";
    if (keyword) {
      request = "?q=" + keyword;
    }
    api.collections.getCollections(
      (response) => {
        let data = _.get(response, "data.data.record");
        setCollections(data);
      },
      (error) => {
        setError(error);
      },
      request
    );

    api.categories.getCategories(
      (response) => {
        let data = _.get(response, "data.data.record");
        setCategories(data);
      },
      (error) => {}
    );
  }, []);

  React.useEffect(() => {
    let request = "?" + query_request;
    api.collections.getCollections(
      (response) => {
        setCollections(response.data.data.record);
      },
      (error) => {
        setError(error);
      },
      request
    );
  }, [category_id]);

  const meta = {
    title: "DISCOVER ALL COLLECTION THECONCERT.IO",
    description:
      "NFT WALLET & PLATFORM FOR MUSIC, CONCERT AND NIGHTLIFE COMMUNITY",
    canonical: env.FRONT_URL + `discover`,
    meta: {
      charset: "utf-8",
      name: {
        keywords: "NFT, CONCERT, MUSIC",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <Layout>
        <div className="login-section discover-section">
          <Container>
            <h2 className="title">DISCOVER ALL COLLECTION</h2>
            <div className="search-collection">
              <Form className="d-flex _search-c">
                <FormControl
                  id="searchCollection"
                  type="search"
                  name="q"
                  placeholder="Search Collections"
                  className="me-2"
                  aria-label="Search"
                  onChange={handleSearchChange}
                />
              </Form>
            </div>
            <div id="collection-cateogry" className="collection-cateogry">
              <div
                key="collection-category-all"
                onClick={handleClassActive}
                data-id="all"
                className="category active"
              >
                All
              </div>
              {categories
                ? categories.map((category, index) => {
                    return (
                      <div
                        key={`collection-category-${_.get(category, "id")}`}
                        onClick={handleClassActive}
                        data-id={_.get(category, "id")}
                        className="category"
                      >
                        {_.get(category, "name.th")}
                      </div>
                    );
                  })
                : null}
            </div>

            <div className="_my-collections">
              <Row>
                {collections.length > 0 ? (
                  collections.map((item, index) => {
                    let images = _.get(item, "images");
                    let logoImage = _.filter(images, { tag: "logo" })[0];
                    let coverImage = _.filter(images, { tag: "featured" })[0];

                    let user_avatar =
                      env.RES_URL +
                      "/w_200,h_200,c_thumb/" +
                      logoImage.id +
                      "/" +
                      logoImage.name;
                    let user_cover = !_.isEmpty(coverImage)
                      ? env.RES_URL +
                        "/w_600,h_400,c_thumb/" +
                        coverImage.id +
                        "/" +
                        coverImage.name
                      : env.RES_URL +
                        "/w_400,h_200,c_thumb/" +
                        logoImage.id +
                        "/" +
                        logoImage.name;

                    return (
                      <Col
                        md={4}
                        key={"collection_discover-" + _.get(item, "id")}
                      >
                        <Link to={"/collection/" + _.get(item, "id")}>
                          <div className="collection">
                            <div className="avatar">
                              <img src={user_avatar} />
                            </div>
                            <div className="about">
                              <h2>
                                <span>{_.get(item, "name")}</span>{" "}
                                <i className="_icon _icon-verify"></i>
                              </h2>
                              {/* <Link
                                to={"/profile/" + _.get(item.connects, "uid")}
                              > */}
                                <div className="owner">
                                  OWNED BY : {_.get(item, "store.name")}
                                </div>
                              {/* </Link> */}
                              <div className="description">
                                {_.get(item, "description")}
                              </div>
                            </div>
                            <div className="cover">
                              <img src={user_cover} />
                            </div>
                          </div>
                        </Link>
                      </Col>
                    );
                  })
                ) : (
                  <div className="mt-5 text-center _font-large">No Result</div>
                )}
              </Row>
            </div>
          </Container>
        </div>
      </Layout>
    </DocumentMeta>
  );
};

export default Discover;
