import React from "react";

import "../assets/sass/profile_page.scss";
import { Container } from "react-bootstrap";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useWeb3React } from "@web3-react/core";
import WalletProviderList from "./WalletProviderList";

const MyWallet = ({ address, clickCopy, tokenBalanceList }) => {
  return (
    <React.Fragment>
      {address ? (
        <div>
          <h2>MY WALLET</h2>
          {/* <div className="card-box-my-wallet">
                    <p>ADDRESS : {address}</p><div onClick={clickCopy}><i className="btn-copy"></i></div>  
                </div> */}

          <div className="address">
            <div onClick={clickCopy}>
              <div className="address-text">
                ADDRESS : {address}{" "}
                <ContentCopyIcon className="_font-medium _copy" />
              </div>
            </div>
          </div>

          <div className="card-box-my-wallet-balance">
            <h2>WALLET BALANCE</h2>
            <div>
              {tokenBalanceList.map((s, index) => (
                <p className="my-wallet-balance" key={index}>
                  {s.balance} {s.symbol}
                </p>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h2>Connect your wallet</h2>
          <br />
          <div className="card-center">
            <WalletProviderList isLogin={true} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MyWallet;
