import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormControl,
  Tabs,
  Modal,
  CloseButton,
} from "react-bootstrap";
import ItemNft from "../components/ItemNft";
import Layout from "../components/Layout/Layout";
import "../assets/sass/collection_detail.scss";
import api from "../api";
import ic_arrow_left from "../assets/images/icon-arrow-left.svg";
import ic_arrow_right from "../assets/images/icon-arrow-right.svg";
import ic_grid4 from "../assets/images/icon-grid4.svg";
import ic_grid8 from "../assets/images/icon-grid8.svg";
import { Tab } from "bootstrap";
import _ from "lodash";
import { useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import TableEvents from "../components/TableEvents";
import DocumentMeta from "react-document-meta";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
} from "react-share";
import env from "../env";
import Cookies from "js-cookie";
import CollectionActivity from "../components/CollectionActivity";

const CollectionDetail = () => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { id } = useParams();
  const [collection, setCollection] = useState(null);
  const [error, setError] = useState(null);
  const [logo, setLogo] = useState();
  const [cover, setCover] = useState();
  const [showModalShare, setShowModalShare] = useState(false);
  const closeModalShare = () => setShowModalShare(false);
  const [shareURL, setShareURL] = useState();
  const [query, setQeury] = useState("");
  const [type, setType] = useState("recently_created");
  const [products, setProducts] = useState([]);
  const [productsID, setProductsID] = useState([]);
  const [pagination, setPagination] = useState();
  const [isSearchItem, setIsSearchItem] = useState(false);
  const nl2br = require("react-nl2br");
  const [isFavorite, setIsFavorite] = useState(false);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // })

  React.useEffect(() => {
    let request = { id: id };
    api.collections.getCollectionDetail(
      (response) => {
        let data = response.data.data;
        setCollection(data);
        let collectionAvatar = data.images;
        let logoImage = _.filter(collectionAvatar, { tag: "logo" })[0];
        let coverImage = _.filter(collectionAvatar, { tag: "cover" })[0];
        setLogo(logoImage);
        setCover(
          coverImage == null || coverImage === undefined ? "" : coverImage.url
        );

        setShareURL(env.FRONT_URL + `collection/${id}`);
        setIsFavorite(data.is_favorite);
      },
      (error) => {
        // setError(error);
      },
      request
    );
  }, []);

  useEffect(() => {}, [collection]);

  useEffect(() => {
    var orderQuery = "";
    var sortQuery = "";

    switch (type) {
      case "recently_created":
        orderQuery = "created_at";
        sortQuery = "desc";
        break;
      case "oldest_first":
        orderQuery = "created_at";
        sortQuery = "asc";
        break;
      case "most_favorited":
        orderQuery = "favorited";
        sortQuery = "desc";
        break;
      default:
        orderQuery = "created_at";
        sortQuery = "desc";
    }

    let request = {
      collection_id: id,
      q: query,
      order: orderQuery,
      sort: sortQuery,
    };

    api.products.getProducts(
      (response) => {


        let access_token = Cookies.get("access_token", { domain: window.location.hostname });
        let data = response.data.data;

        if (data != "null") {
          if (access_token != null || access_token !== undefined) {
            let mapProductId = data.record.map((product) => {
              return product.id;
            })
            setProductsID(mapProductId);
            let requestProductId = {
              product_ids: mapProductId
            };



            checkUserFavorites(response.data.data, requestProductId);
          } else {
            setProducts(response.data.data.record);
            setPagination(response.data.data.pagination);
          }
        }


      },
      (error) => {
        setError(error);
      },
      request
    );
  }, []);

  const shareToSocial = () => {
    setShowModalShare(true);
  };

  const getProducts = () => {
    var orderQuery = "";
    var sortQuery = "";

    switch (type) {
      case "recently_created":
        orderQuery = "created_at";
        sortQuery = "desc";
        break;
      case "oldest_first":
        orderQuery = "created_at";
        sortQuery = "asc";
        break;
      case "most_favorited":
        orderQuery = "favorited";
        sortQuery = "desc";
        break;
      default:
        orderQuery = "created_at";
        sortQuery = "desc";
    }

    let request = {
      collection_id: id,
      q: query,
      order: orderQuery,
      sort: sortQuery,
    };

    api.products.getProducts(
      (response) => {

        let access_token = Cookies.get("access_token", { domain: window.location.hostname });
        let data = response.data.data;
        if (data != "null") {
          if (access_token != null || access_token !== undefined) {
            let mapProductId = data.record.map((product) => {
              return product.id;
            })
            setProductsID(mapProductId);
            let requestProductId = {
              product_ids: mapProductId
            };

            // console.log("requestProductId --- ", requestProductId);

            checkUserFavorites(response.data.data, requestProductId);

          } else {
            setProducts(response.data.data.record);
            setPagination(response.data.data.pagination);
          }
        }
      },
      (error) => {
        setError(error);
      },
      request
    );
  };

  const checkUserFavorites = async (productsData, requestProductId) => {


    try {

      await api.users.getProductFavorites((favoritesResponse) => {
        //console.log("favoritesResponse --- ", favoritesResponse.data.data.record);

        let favorites = favoritesResponse.data.data;

        if (productsData.record.length > 0) {
          // update product is_favorite
          for (var i = 0; i < productsData.record.length; i++) {

            if (productsData.record[i].id == favorites.record[i].id) {
              productsData.record[i].is_favorite = favorites.record[i].status;

            }
          }

          setProducts(productsData.record);
          setPagination(productsData.pagination);
        }  else {
          setProducts(productsData.record);
          setPagination(productsData.pagination);
        }

      }, (error) => {
        setError(error);
        // setProducts(productsData.record);
        // setPagination(productsData.pagination);
      },
        requestProductId
      );



    } catch (error) {
      setProducts(productsData.record);
      setPagination(productsData.pagination);
    }
  }

  const handleSearchChange = (event) => {
    const queryString = event.target.value;
    setQeury(queryString);
    let handleLength = queryString.length;
    setIsSearchItem(handleLength > 0 ? true : false);
  };

  const handleSelectFilter = (event) => {
    setType(event.target.value);
  };

  useEffect(() => {}, [query]);

  useEffect(() => {
    getProducts();
  }, [type]);

  const SubmitSearch = async (data) => {
    setIsSearchItem(true);
    getProducts();
  };

  const meta = {
    title: _.get(collection, "name") + " | THECONCERT.IO",
    description: _.get(collection, "description"),
    canonical: env.FRONT_URL + `collection/${id}`,
    meta: {
      charset: "utf-8",
      name: {
        keywords: "Collection," + _.get(collection, "name"),
      },
      property: {
        "og:title": _.get(collection, "name"),
        "og:url": env.FRONT_URL + `collection/${id}`,
        "og:image": cover,
        "og:description": _.get(collection, "description"),
        "og:type": "website",
        "og:image:width": "1200",
        "og:image:height": "630",
        "og:site_name": "theconcert.io",

        "twitter:card": "summary_large_image",
        "twitter:title": _.get(collection, "name"),
        "twitter:description": _.get(collection, "description"),
        "twitter:image": cover,
        "twitter:site": "theconcert.io",
        "twitter:creator": "theconcert.io",
        "twitter:domain": env.FRONT_URL + `collection/${id}`,
      },
    },
  };

  const favoriteCollection = () => {
    let access_token = Cookies.get("access_token", { domain: window.location.hostname });
    if (access_token == "null" || access_token === undefined) {
    } else {
      setIsFavorite(isFavorite ? false : true);
      let request = {
        collection_id: id,
        action: !isFavorite ? "add" : "remove",
      };

      api.users.userFavorites(
        (response) => {
          let data = response.data.data;
        },
        (error) => {},
        request
      );
    }
  };

  // useEffect(() => {
  //   if (window.ethereum) {
  //     handleEthereum();
  //   } else {
  //     window.addEventListener("ethereum#initialized", handleEthereum, {
  //       once: true,
  //     });
  //     setTimeout(handleEthereum, 3000); // 3 seconds
  //   }
  // }, []);

  // function handleEthereum() {
  //   const { ethereum } = window;
  //   if (ethereum && ethereum.isMetaMask) {
  //     console.log("Ethereum successfully detected!");
  //   } else {
  //     console.log("Please install MetaMask!");
  //   }
  // }

  return (
    <React.Fragment>
      <DocumentMeta {...meta}>
        <Layout>
          <Col>
            <div
              className="header-section"
              style={{
                backgroundImage: `url("${cover}")`,
              }}
            >
              <Container className="header-section-content">
                <Row>
                  <Col xs={12} sm={3}>
                    {logo == null || logo === undefined ? null : (
                      <img
                        className="collection-avatar"
                        src={
                          env.RES_URL +
                          "/w_285,h_285,c_thumb/" +
                          logo.id +
                          "/" +
                          logo.name
                        }
                        alt={"Avatar"}
                      />
                    )}
                  </Col>
                  <Col sm={9}>
                    <div>
                      <h2 className="collection-title">
                        {_.get(collection, "name")}{" "}
                        <i className="_icon _icon-verify"></i>
                      </h2>
                      {/* <Link
                        to={"/profile/" + _.get(collection, "connects.uid")}
                      > */}
                      <div className="collection-by">
                        OWNED BY : {_.get(collection, "store.name")}
                      </div>
                      {/* </Link> */}
                      <div className="collection-desc">
                        {nl2br(_.get(collection, "description"))}
                      </div>
                      <div className="shares mt-3">
                        {isFavorite ? (
                          <i
                            className="_icon _icon-star-active"
                            onClick={favoriteCollection}
                          ></i>
                        ) : (
                          <i
                            className="_icon _icon-star"
                            onClick={favoriteCollection}
                          ></i>
                        )}
                        <i
                          className="_icon _icon-share"
                          onClick={shareToSocial}
                        ></i>
                        <TwitterShareButton
                          title={_.get(collection, "name")}
                          url={shareURL}
                          hashtags={[
                            _.get(collection, "name")
                              ? _.get(collection, "name").replace(/\s/g, "")
                              : null,
                            "Theconcert",
                          ]}
                        >
                          <i className="_icon _icon-tweet"></i>
                        </TwitterShareButton>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="box-collec-detail">
              <Container>
                <Row>
                  <Col xs={6} className="center">
                    <span className="value">
                      {/* {_.get(collection, "item_total", 0)} */}
                      {_.get(pagination, "total", 0)}
                    </span>
                    <br />
                    <span className="value-title">ITEM</span>
                  </Col>
                  <Col xs={6} className="center border-left">
                    <span className="value">
                      {_.get(collection, "owner_total", 0)}
                    </span>
                    <br />
                    <span className="value-title">OWNER</span>
                  </Col>
                  {/* <Col xs={3} className="center border-left">
                      <span className="value">-</span>
                      <br />
                      <span className="value-title">FLOOR PRICE</span>
                    </Col>
                    <Col xs={3} className="center border-left">
                      <span className="value">-</span>
                      <br />
                      <span className="value-title">VOLUME TRADE</span>
                    </Col> */}
                </Row>
              </Container>
            </div>

            <div className="box-collection-info">
              <Container>
                <Tabs defaultActiveKey="items" id="collection-nft">
                  <Tab eventKey="items" title="ITEMS">
                    <Container className="filter-section">
                      <Row>
                        <Col md={9} className="_search-collection">
                          <Form
                            className="_search-item"
                            onSubmit={handleSubmit(SubmitSearch)}
                          >
                            <i className="icon-search-item"></i>
                            <FormControl
                              type="search"
                              placeholder="SEARCH BY NAME"
                              className="input-search"
                              aria-label="SEARCH BY NAME"
                              name="search_product"
                              {...register("search_product", {
                                onChange: (e) => {
                                  handleSearchChange(e);
                                },
                              })}
                            />
                          </Form>
                        </Col>
                        <Col md={3} xs={6}>
                          <div className="filter-item">
                            <Form.Select
                              aria-label="Default select"
                              value={type}
                              onChange={handleSelectFilter}
                            >
                              <option value="recently_created">
                                Recently Created
                              </option>
                              <option value="oldest_first">Oldest First</option>
                              <option value="most_favorited">
                                Most Favorited
                              </option>
                            </Form.Select>
                          </div>
                        </Col>
                      </Row>
                    </Container>

                    <div className="section-control-item">
                      <Row className="justify-content-md-center">
                        <Col xs={6}>
                          {/* <img src={ic_arrow_left} className="_icon"></img>
                            <img src={ic_arrow_right} className="_icon"></img> */}
                          <span className="sum-items">
                            {_.get(pagination, "total", 0)} items
                          </span>
                        </Col>
                        <Col xs={6}>
                          {/* <div className="filter-box">
                              <img src={ic_grid4} className="_icon"></img>
                              <img src={ic_grid8} className="_icon"></img>
                            </div> */}
                        </Col>
                      </Row>
                    </div>

                    <Container className="item-section">
                      <Row>
                        <ItemNft
                          products={products}
                          collection_id={id}
                          is_search_item={isSearchItem}
                        />
                      </Row>
                    </Container>
                  </Tab>
                  <Tab eventKey="activiey" title="ACTIVITY">
                    {/* <div className="activity text-center">ACTIVITY</div> */}
                    <div>
                      {/* <TableEvents collectionId={_.get(collection, "id")} /> */}
                      {_.get(collection, "contract_address") ? (
                        <CollectionActivity
                          address={_.get(collection, "contract_address")}
                        />
                      ) : (
                        <div />
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </Container>
            </div>
          </Col>
          <Modal
            show={showModalShare}
            onHide={closeModalShare}
            backdrop="static"
            keyboard={false}
            className="modal-alert"
          >
            <Modal.Header>
              <Modal.Title>Share This Collection</Modal.Title>
              <CloseButton variant="white" onClick={closeModalShare} />
            </Modal.Header>
            <Modal.Body>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Link</Form.Label>
                <Form.Control type="text" value={shareURL} disabled readonly />
              </Form.Group>
              <div>
                <h3>Share</h3>
                <FacebookShareButton
                  url={shareURL}
                  quote={_.get(collection, "name")}
                  hashtag={
                    _.get(collection, "name")
                      ? "#" + _.get(collection, "name").replace(/\s/g, "")
                      : null
                  }
                  description={_.get(collection, "description")}
                >
                  <i className="_icon-share-footer _icon-share-footer-facebook"></i>
                </FacebookShareButton>
                <TwitterShareButton
                  title={_.get(collection, "name")}
                  url={shareURL}
                  hashtags={[
                    _.get(collection, "name")
                      ? _.get(collection, "name").replace(/\s/g, "")
                      : null,
                    "Theconcert",
                  ]}
                >
                  <i className="_icon-share-footer _icon-share-footer-twitter"></i>
                </TwitterShareButton>
                <TelegramShareButton
                  url={shareURL}
                  title={_.get(collection, "name")}
                >
                  <i className="_icon-share-footer _icon-share-footer-telegram"></i>
                </TelegramShareButton>
              </div>
            </Modal.Body>
          </Modal>
        </Layout>
      </DocumentMeta>
    </React.Fragment>
  );
};

export default CollectionDetail;
