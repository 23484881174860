import React,{ useCallback, useState } from "react";
import moment from 'moment';
import api from "../api"
import _ from "lodash"
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import YouTube from 'react-youtube';
import AirDropNft from "../pages/AirDropNft";
import { useMediaQuery } from 'react-responsive';
import { Container} from "react-bootstrap";

 

const _onReady = (event) => {
  event.target.pauseVideo();
};

const Banner = () => {
  const [startDate, setStart] = useState([]);
  const [endDate, setEnd] = useState([]);
  const [content, setContent] = useState([]);
  const [youtube, setYoutube] = useState([]);
  const [error, setError] = useState(null);
  const [image, setImage] = useState([]);
  const [showTimer, setShowTimer] = useState(false);

  React.useEffect( ()=> {
    let request =  {
      'lang': 'th',
      'group_code': 'collection-countdown',
      'page': 1,
      'status':true,
      'limit': 1
    }

    api.contents.getContent(
        (response) => {
          let data = _.get(response,'data.data.record');
          let find = _.find(data,'fields');
          let start_date = _.find(find.fields, function (d) { return d.name == "datetime_start"; });
          let end_date = _.find(find.fields, function (d) { return d.name == "datetime_end"; });
          let content = _.find(find.fields, function (d) { return d.name == "description"; });
          let youtube_id = _.find(find.fields, function (d) { return d.name == "youtude_url"; });

          setStart(_.get(start_date, 'lang_default'));
          setEnd(_.get(end_date, 'lang_default'));
          setContent(_.get(content, 'lang'));
          setYoutube(_.get(youtube_id, 'lang'));
          setImage(_.get(data, '0.images.0.url'));

          setShowTimer(true);
        },
        (error) => {
          setError(error);
        }, request)
  
  },[])

  const opts = {
    height: '683',
    width: '1116',
    playerVars: {
      autoplay: 0,
      controls: 0,
      rel: 0,
      showinfo: 0,
      mute: 0,
      loop: 1
    }
  };
  
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
      <Container>
        <AirDropNft/> 
        <div className="banner _banner"><img src={image}/></div>

        {content?
        <div className="mb-3"
        dangerouslySetInnerHTML={{__html: content}} />: null }

        <div className="youtube-box">
          <YouTube id='youtube_vdo' videoId={youtube} opts={opts} onReady={_onReady} />
        </div>

      </Container>
  );
}
export default Banner;