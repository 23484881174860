import axios from "axios";
import React, { createContext, useEffect, useMemo } from "react";
import { useAuthen } from "../hooks/useAuthen";

export const AuthentContext = createContext({});

export const AuthenProvider = ({ children }) => {
  const { authed } = useAuthen();

  return (
    <AuthentContext.Provider value={authed}>{children}</AuthentContext.Provider>
  );
};
