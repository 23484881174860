import React, { useEffect } from "react";

import ReactGA from "react-ga";
import env from "./env";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import SettingsPage from "./pages/Settings";
import AirDropCampaign from "./pages/AirDropCampaign";
import CollectionDetail from "./pages/CollectionDetail";
import ProductDetail from "./pages/ProductDetail";
import LoginPage from "./pages/LoginPage";
import Signup from "./pages/SignUp";
import PageNotFound from "./pages/PageNotFound";
import ResetPasswordPage from "./pages/ResetPassword";
import SearchPage from "./pages/Search";
import SearchResult from "./pages/SearchResult";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import KycPage from "./pages/KycPage";
import Discover from "./pages/Discover";
import PublicProductDetail from "./pages/PublicProductDetail";
import UserProfile from "./pages/UserProfile";
import PublicProfile from "./pages/PublicProfile";
import LabPage from "./pages/LabPage";
import LabLogin from "./pages/LabLogin";
import Sandbox from "./pages/Sandbox";

ReactGA.initialize(env.REACT_APP_GA_TRACKING_NO);
const browserHistory = createBrowserHistory();
browserHistory.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const IndexRoutes = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="signup" element={<Signup />} />
        <Route path="settings" element={<SettingsPage />} />
        <Route path="profile" element={<UserProfile />} />

  
        <Route
          path="resetpassword/:token/:email"
          element={<ResetPasswordPage />}
        />
        <Route path="search" element={<SearchPage />} />
        <Route
          path="campaign/:collection_id/:id"
          element={<AirDropCampaign />}
        />
        <Route path="product/:id" element={<ProductDetail />} />
        <Route
          path="assets/:chain/:address_token/:id"
          element={<PublicProductDetail />}
        />

        <Route path="search/filter" element={<SearchResult />} />
        <Route path="terms" element={<Terms />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="discover" element={<Discover />} />
        <Route path="collection/:id" element={<CollectionDetail />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="kyc" element={<KycPage />} />
        <Route path="profile/:uid" element={<PublicProfile />} />
        <Route path="lab" element={<LabPage />} />
        <Route path="lab-login" element={<LabLogin />} />
        <Route path="sandbox" element={<Sandbox></Sandbox>} />
      </Routes>
    </Router>
  );
};

export default IndexRoutes;
