import { create } from "ipfs-http-client";
import axios from "axios";
import _ from "lodash";

export const ipfs = create({
  host: "ipfs.infura.io",
  port: "5001",
  protocol: "https",
});

// helper function to "Get" from IPFS
// you usually go content.toString() after this...
export const getFromIPFS = async (hashToGet) => {
  const response = await fetch("https://cloudflare-ipfs.com/ipfs/" + hashToGet);

  if (!response.ok) throw new Error(response.statusText);

  try {
    const json = await response.json();
    const fullPath = json.image.replace(
      "ipfs://",
      "https://cloudflare-ipfs.com/ipfs/"
    );
    // console.log({ status: "success", image: fullPath, name: json.name });
    return { status: "success", image: fullPath, name: json.name };
  } catch (e) {
    // console.log({ status: "fail", image: "" });
    return { status: "fail", image: "" };
  }
};

export const getMetaDataFromTokenUri = async (hashToGet) => {
  const controller = new AbortController();

  // 5 second timeout:
  const timeoutId = setTimeout(() => controller.abort(), 5000);

  try {
    const response = await fetch(
      hashToGet.replace(
        "https://ipfs.moralis.io:2053/",
        "https://cloudflare-ipfs.com/"
      ),
      { signal: controller.signal }
    );

    const json = await response.json();

    let image = _.get(json, "image");

    clearTimeout(timeoutId);

    if (image) {
      const fullPath = image
        .replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/")
        .replace("/ipfs/ipfs", "/ipfs");
      return {
        status: "success",
        image: fullPath,
        name: json.name ?? "",
        description: json.description ?? undefined,
      };
    } else {
      return { status: "remove" };
    }
  } catch (e) {
    return { status: "remove" };
  }
};
