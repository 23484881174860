import { useEffect } from "react";
import axios from "axios";

const useConvertToken = () => {
  const convertPriceToUSD = async () => {
    const response = await axios.get(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=matic-network"
    );

    const data = response.data;
    if (Array.isArray(data) && data.length) {
      // array exists and is not empty
      const currentPriceMaticOfUSD = data[0].current_price;

      return { price: currentPriceMaticOfUSD };
    }
    return { price: data };
  };

  return { convertPriceToUSD };
};

export { useConvertToken };
