"use strict";

import _ from "lodash";
import env from "../env";
import axios from 'axios';

export default {
  register(cb, errorCb, request = "") {
    window.axios
      .post("v1.1/users/register", request)
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },
  authen(cb, errorCb, request = "") {
    window.axios
      .post("v1.1/users/authen", request)
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },

  getProfile(cb, errorCb, request = "") {
    window.axios
      .get("v1.1/users/profile", null)
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },

    // /v1.1/users/order-status?order_id=1246
    getOrderStatus(cb, errorCb, id){
      window.axios.get('v1.1/users/order-status?order_id=' + id)
      .then(response => cb(response))
      .catch(err => {
          if (typeof errorCb === 'function') {
              errorCb(err.response)
          }
      })
  },

  updataProfile(cb, errorCb, request = "") {
    window.axios
      .put("v1.1/users/profile", request)
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },

  uploadAvatar(cb, errorCb, request = "") {
    window.axios
      .put("v1.1/users/avatar", request)
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },

  uploadCover(cb, errorCb, request = "") {
    window.axios
      .put("v1.1/users/cover", request)
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },

  userForgotPassword(cb, errorCb, request = "") {
    window.axios
      .post("v1.1/users/forgot-password", request)
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },

  userResetPassword(cb, errorCb, request = "") {
    window.axios
      .put("v1.1/users/reset-password", request)
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },

  userClaim(cb, errorCb, request = "") {
    window.axios
      .post("/v1.1/users/claim", request)
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },


  userFavorites(cb, errorCb, request = "") {
    window.axios
      .post("/v1.1/users/favorites", request)
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },

  getUserFavorites(cb, errorCb, request = {}) {
    var params = {};
    var path = "/v1.1/users/favorites";
    params = Object.assign({}, request);
    if (request.uid === undefined) {
    } else {
      path = "/v1.1/users/public/favorite";
    }
    window.axios
      .get(path, { params: params })
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },

  getPublicProfile(cb, errorCb, request = "") {
    window.axios
      .get(`v1.1/users/${request}`)
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },

   // post product id for favorites check
   getProductFavorites(cb, errorCb, request = {}) {
  
      window.axios.post('v1.1/users/favorites/check', request)
          .then(response => cb(response))
          .catch(err => {
              if (typeof errorCb === 'function') {
                  errorCb(err.response)
              }
          });
     
    },

    getCountries(cb, errorCb, request = "") {
      window.axios 
        .get("v1.1/countries", null)
        .then((response) => cb(response))
        .catch((err) => {
          if (typeof errorCb === "function") {
            errorCb(err.response);
          }
        });
    },
    
//   postRecaptcha(cb, errorCb, token = "") {
// let headers = {
//     // 'Access-Control-Allow-Origin': 'http://localhost:3000',
//     'Access-Control-Allow-Origin': '*',
//     'Access-Control-Allow-Credentials': 'true',
//     'Access-Control-Allow-Methods': 'POST'
//   };

//     axios
//       .post(
        
//         `https://www.google.com/recaptcha/api/siteverify?secret=${env.GOOGLE_CAPTCHA_SECRET_KEY}&response=${token}`,
//         {},
//       {
//         headers: {
//           "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
//         },
//       }
   
//   ) 
//       .then((response) => cb(response))
//       .catch((err) => {
//         if (typeof errorCb === "function") {
//           errorCb(err.response);
//         }
//       });
//   }
};
