'use strict'

import _ from 'lodash'

export default {
    getCategories(cb, errorCb,request = '') {
        window.axios.get(`v1.1/categories${request}`)
            .then(response => cb(response))
            .catch(err => {
                if (typeof errorCb === 'function') {
                    errorCb(err.response)
                }
            })

    },

}
