import React, { useContext, useEffect, useState } from "react";
import "../assets/sass/login_wallet.scss";
import { ListGroup, Button, Modal } from "react-bootstrap";

import buttonPopular from "../assets/images/btn-connect.png";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { WalletContext } from "../providers/WalletProvider";

import "../assets/sass/modal.scss";
import iconError from "../assets/images/icon-error.svg";
import { ConnectorNames } from "../constants";
import Cookies from "js-cookie";
import { useAuthen } from "../hooks/useAuthen";
import api from "../api";
import _ from "lodash";
import { useWeb3React } from "@web3-react/core";
import env from "../env";
import { useMediaQuery } from "react-responsive";
import { connectorsByName } from "../utils/web3Connect";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LabPage = ({ isLogin }) => {
  const [userAddress, setUserAddress] = useState("");

  useEffect(() => {
    //
  }, [userAddress]);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const { library, chainId } = useWeb3React();

  let location = useLocation();
  let query = useQuery();
  let hasReturn = query.get("return");

  const {
    walletActive,
    address,
    providerWalletList,
    currentConnector,
    connectWallet,
    disconnectWallet,
    switchNetwork,
    setupNetwork,
  } = useContext(WalletContext);
  const { account, activate, active } = useWeb3React();

  const {
    authed,
    clearToken,
    walletConnectTheConcert,
    checkAddressHasConnectedEmail,
  } = useAuthen();

  const walletConnect = (request) => {
    api.wallets.walletConnect(
      (response) => {
        let access_token = _.get(response, "data.data.access_token");
        let refresh_token = _.get(response, "data.data.refresh_token");
        if (access_token && refresh_token) {
          Cookies.set("access_token", access_token, {
            domain: window.location.hostname,
          });
          Cookies.set("refresh_token", refresh_token, {
            domain: window.location.hostname,
          });

          if (hasReturn === "true") {
            navigate(-1);
          } else {
            if (
              location.pathname != "/profile" &&
              location.pathname != "/settings"
            ) {
              window.location.href = "/profile";
            }
          }
        }
      },
      (error) => {
        let statusCode = _.get(error, "data.error.code");
        let message = _.get(error, "data.error.message");

        if (statusCode === 400) {
          setErrorMessage(message);
          handleShowModalError();
        }
      },
      request
    );
  };

  useEffect(() => {
    let access_token = Cookies.get("access_token", { domain: window.location.hostname });

    if (currentConnector && account) {
      const request = walletConnectTheConcert(currentConnector, isLogin);
      if (request) {
        walletConnect(request);
      }
    }
  }, [currentConnector, account]);

  const [showModalSupportWebBrowser, setShowModalSupportWebBrowser] =
    useState(false);

  const handleCloseModalSupportWebBrowser = () =>
    setShowModalSupportWebBrowser(false);
  const handleShowModalSupportWebBrowser = () =>
    setShowModalSupportWebBrowser(true);

  const navigate = useNavigate();

  const handleConnectWallet = async (data) => {
    if (isMobile && checkMetaMaskSupport() == false) {
      //   window.location.assign(env.METAMASK_DEEPLINK);
      //   window.open(env.METAMASK_DEEPLINK, "_self", "noopener,noreferrer");
      //   window.open(env.METAMASK_DEEPLINK, "_blank", "noopener,noreferrer");

      window.open(env.METAMASK_DEEPLINK, "_self", "noopener,noreferrer");
    } else {
      if (checkMetaMaskSupport()) {
        if (walletActive) {
          navigate("/profile", { replace: true });
        } else {
          const isProduction = env.APP == "PRODUCTION";
          const defaulChain = isProduction
            ? env.REACT_APP_CHAIN_ID_DEFAULT
            : 80001;
          const hasSetup = await setupNetwork(defaulChain);
          if (hasSetup) {
            await switchNetwork(defaulChain);
            await new Promise((resolve) => setTimeout(resolve, 500));
          }
          connectWallet(data.connector);
        }
      } else if (data.connector == ConnectorNames.Injected) {
        handleShowModalSupportWebBrowser();
      } else {
        if (walletActive) {
          navigate("/profile", { replace: true });
        } else {
          connectWallet(data.connector);
        }
      }
    }
  };

  const renderProviderWalletListCustom = [
    <ListGroup.Item
      onClick={() => {
        window.location.assign(env.METAMASK_DEEPLINK);
      }}
      key={1}
      className="list-logins"
    >
      <span className="textWallet">Assing deeplink</span>
    </ListGroup.Item>,
    <ListGroup.Item
      onClick={() => {
        window.open(env.METAMASK_DEEPLINK, "_self", "noopener,noreferrer");
      }}
      key={2}
      className="list-logins"
    >
      <span className="textWallet">Open self</span>
    </ListGroup.Item>,
    <ListGroup.Item
      onClick={() => {
        window.open(env.METAMASK_DEEPLINK, "_blank", "noopener,noreferrer");
      }}
      key={3}
      className="list-logins"
    >
      <span className="textWallet">Open blank</span>
    </ListGroup.Item>,
    <ListGroup.Item key={4} className="list-logins">
      <span className="textWallet">{1}</span>
      <a href={env.METAMASK_DEEPLINK}>Open href</a>
    </ListGroup.Item>,
    <ListGroup.Item
      onClick={() => {
        window.location.assign(
          env.METAMASK_DEEPLINK.replace(
            "https://metamask.app.link/dapp/",
            "dapp://metamask.app.link/dapp/"
          )
        );
      }}
      key={5}
      className="list-logins"
    >
      <span className="textWallet">Assign metamask://</span>
    </ListGroup.Item>,
    <ListGroup.Item
      onClick={() => {
        window.open(
          env.METAMASK_DEEPLINK.replace(
            "https://metamask.app.link/dapp/",
            "dapp://metamask.app.link/dapp/"
          ),
          "_self",
          "noopener,noreferrer"
        );
      }}
      key={6}
      className="list-logins"
    >
      <span className="textWallet">Open self metamask://</span>
    </ListGroup.Item>,
    <ListGroup.Item
      onClick={() => {
        window.open(
          env.METAMASK_DEEPLINK.replace(
            "https://metamask.app.link/dapp/",
            "dapp://metamask.app.link/dapp/"
          ),
          "_blank",
          "noopener,noreferrer"
        );
      }}
      key={7}
      className="list-logins"
    >
      <span className="textWallet">Open blank metamask://</span>
    </ListGroup.Item>,
    <ListGroup.Item
      onClick={() => {
        openMetaMaskUrl(env.METAMASK_DEEPLINK);
      }}
      key={8}
      className="list-logins"
    >
      <span className="textWallet">openMetaMaskUrl</span>
    </ListGroup.Item>,
    <ListGroup.Item
      onClick={() => {
        window.location.href = env.METAMASK_DEEPLINK;
      }}
      key={9}
      className="list-logins"
    >
      <span className="textWallet">open window location</span>
    </ListGroup.Item>,
    <ListGroup.Item
      onClick={() => {
        window.location.replace(env.METAMASK_DEEPLINK);
      }}
      key={10}
      className="list-logins"
    >
      <span className="textWallet">open window location replease</span>
    </ListGroup.Item>,
    <ListGroup.Item key={11} className="list-logins">
      <Link to={{ pathname: env.METAMASK_DEEPLINK }} target="_blank">
        <span className="textWallet">Link</span>
      </Link>
      ,
    </ListGroup.Item>,
    <ListGroup.Item
      onClick={() => {
        console.log("connect");
        activate(
          connectorsByName[ConnectorNames.Injected],
          (error) => {
            alert(error);
          },
          true
        );
      }}
      key={12}
      className="list-logins"
    >
      <span className="textWallet">Open only</span>
    </ListGroup.Item>,
  ];

  const checkMetaMaskSupport = () => {
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
      return true;
    } else {
      return false;
    }
  };

  const [showModalError, setShowModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleShowModalError = () => setShowModalError(true);
  const handleCloseModalError = () => {
    setShowModalError(false);
    disconnectWallet();
  };

  useEffect(() => {
    if (window.ethereum) {
      handleEthereum();
    } else {
      window.addEventListener("ethereum#initialized", handleEthereum, {
        once: true,
      });
      // If the event is not dispatched by the end of the timeout,
      // the user probably doesn't have MetaMask installed.
      setTimeout(handleEthereum, 3000); // 3 seconds
    }
  }, []);

  function handleEthereum() {
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
      console.log("Ethereum successfully detected!");
      handleShowModalError();
    } else {
      console.log("Please install MetaMask!");
    }
  }

  function openMetaMaskUrl(url) {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_self";
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  return (
    <React.Fragment>
      <ListGroup variant="flush">{renderProviderWalletListCustom}</ListGroup>

      <Modal
        show={showModalSupportWebBrowser}
        onHide={handleCloseModalSupportWebBrowser}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            <span className="text-modal-title">Error</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className="text-modal show-grid">
            Please install Metamask wallet
          </span>
          <p />
          {/* <Container>
              <Row md={4}>
                {supportedChainlist.map((id, index) => {
                  return (
                    <Col md={6} key={id}>
                      <Button onClick={() => {}} variant="primary">
                        <span>{networkMap[id].chainName}</span>
                      </Button>
                      <p />
                    </Col>
                  );
                })}
              </Row>
            </Container> */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleCloseModalSupportWebBrowser}
            className="btn-optional"
          >
            <span>Close</span>
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModalError}
        onHide={handleCloseModalError}
        backdrop="static"
        keyboard={false}
        className="modal-alert"
      >
        <Modal.Body>
          <div className="text-center">
            {/* <img src={iconError} className="icon-error" /> */}
            {/* <h2>WARNING</h2> */}
            <p className="_font-medium-x _gray">{errorMessage}</p>
            <Button className="btn-nft mt-3" onClick={handleCloseModalError}>
              Metamask available
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default LabPage;
