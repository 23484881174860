import React, { useContext, useEffect, useState, useRef } from "react";
import Layout from "../components/Layout/Layout";
import "../assets/sass/login_wallet.scss";
import {
  Container,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { WalletContext } from "../providers/WalletProvider";
import api from "../api";
import _ from "lodash";
import "../assets/sass/modal.scss";
import iconSuccess from "../assets/images/icon-success.svg";
import iconError from "../assets/images/icon-error.svg";
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom'

const ResetPasswordPage = () => {

  const { token, email } = useParams();
  const { register, handleSubmit, setFocus, watch, formState: { errors } } = useForm();

  const [alert, setAlert] = useState(false);
  const [dialogerror, setDialogError] = useState(false);
  const errorClose = () => setDialogError(false);
  const errorShow = () => setDialogError(true);
  const alertClose = () => setAlert(false);
  const alertShow = () => setAlert(true);
  const [alertTitle, setAlertTitle] = useState();
  const [alertDesc, setAlertDesc] = useState();
  const [errorTitle, setErrorTitle] = useState();
  const [errorDesc, setErrorDesc] = useState();


  const navigate = useNavigate();

  const Submit = async data => {
   
    let request = {
      email: email,
      token: token,
      password: data.password,

    };
    api.users.userResetPassword(
      (response) => {


        setAlertTitle("Succeed");
        setAlertDesc("Create new password successful");
        alertShow();
        setTimeout(() => {
          window.location.href = "/login";
        }, 1000);
        // alert('เข้าสู่ระบบสำเร็จ')

      },
      (error) => {
        setErrorTitle("Failed");
        setErrorDesc("Invalid password");
        errorShow();
        //setInputEmail(email)
        
      },
      request
    );
  };




  return (
    <React.Fragment>
      <Layout>
        <div className="login-section">
          <Container>
          <div id="emailsignup" className="email-sign-up">
              <div className="box-login-select">
                <div className="box-policy text-center">
                <h2 className="_font-large-x">Reset Password</h2>
            <span className="policy-title">
              Go back to <a href="/login">Log in</a>
            </span>
                </div>
                <div className="card-center">
                <Form 
            className="login-email-form" 
            onSubmit={handleSubmit(Submit)}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Enter New Password</Form.Label>


                <Form.Control type="password" name="password" placeholder="Enter a password"
                  

                  {...register('password', {
                    required: true,
                    minLength: 8,
                    maxLength: 20,
                    pattern: {
                      value: /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{8,}$/i,
                      message: "invalid password",
                    },
                  })} />
                <div className="text-danger">
                  {errors.password?.message}
                  {errors.password?.type === "required" &&
                    "Password is required"}
                  {errors.password?.type === "minLength" &&
                    "Password must have at least 8 characters "}
                  {errors.password?.type === "maxLength" &&
                    "Value must be less than 20 characters"}
                </div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
              
                <Form.Label>Confirm New Password</Form.Label>

                <Form.Control type="password"  name="passwordConfirm"  placeholder="Enter a confirm password"
                  

                  {...register('passwordConfirm', {
                    // validate: value =>
                    //   value === password.current || "The passwords do not match",
                    
                    required: true,
                    minLength: 8,
                    maxLength: 20,
                    pattern: {
                      value: /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{8,}$/i,
                      message: "invalid confirm password",
                    },
                    validate: (val) => {
                      if (watch("password") != val) {
                        return "Passwords do no match";
                      }
                    },
                  })} />
                <div className="text-danger">
                  {errors.passwordConfirm?.message}
                  {errors.passwordConfirm?.type === "required" &&
                    "Confirm Password is required"}
                  {errors.passwordConfirm?.type === "minLength" &&
                    "Confirm Password must have at least 8 characters "}
                  {errors.passwordConfirm?.type === "maxLength" &&
                    "Value must be less than 20 characters"}
                </div>
              </Form.Group>

              <div className="text-center pt-3">
                <Button className="btn-nft" type="submit">
                  Update Password
                </Button>

              </div>
            </Form>
                </div>
              </div>
          </div>
            <Modal
          show={alert}
          onHide={alertClose}
          backdrop="static"
          keyboard={false}
          className="modal-alert"
        >
          <Modal.Body>
            <div className="text-center">
              <img src={iconSuccess} className="icon-success" />
              <h2>{alertTitle}</h2>
              <p className="_font-medium-x _gray">{alertDesc}</p>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={dialogerror}
          onHide={errorClose}
          backdrop="static"
          keyboard={false}
          className="modal-alert"
        >
          <Modal.Body>
            <div className="text-center">
              <img src={iconError} className="icon-error" />
              <h2>{errorTitle}</h2>
              <p className="_font-medium-x _gray">{errorDesc}</p>
              <Button className="btn-nft mt-3" onClick={errorClose}>
                TRY AGAIN
              </Button>
            </div>
          </Modal.Body>
        </Modal>
          </Container>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default ResetPasswordPage;
