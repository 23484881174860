import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormControl,
  Tabs,
  Modal,
  CloseButton,
} from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import "../assets/sass/collection_detail.scss";
import api from "../api";
import { Tab } from "bootstrap";
import _ from "lodash";
import {
  useParams,
  useNavigate,
  useSearchParams,
  Link,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import DocumentMeta from "react-document-meta";
import Cookies from "js-cookie";
import { useWeb3React } from "@web3-react/core";
import { useAuthen } from "../hooks/useAuthen";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { WalletContext } from "../providers/WalletProvider";
import defaultAvatar from "../assets/images/default-avatar.png";
import { ProfileItemNft } from "../components/ProfileItemNft";
import { FavoritesNft } from "../components/FavoritesNft";
import { useGetNFTs } from "../hooks/useGetNFTs";
import { networkMap } from "../utils/web3Connect";
import AlertBox from "../components/AlertBox";
import UserActivity from "../components/UserActivity";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
} from "react-share";
import env from "../env";
const delay = 3;

const UserProfile = () => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { chainId } = useWeb3React();
  const [copysuccess, showCopySuccess] = useState(false);

  const { id } = useParams();
  const [collection, setCollection] = useState(null);
  const [error, setError] = useState(null);
  const [cover, setCover] = useState("");
  const [showModalShare, setShowModalShare] = useState(false);
  const closeModalShare = () => setShowModalShare(false);
  const [shareURL, setShareURL] = useState();
  const [query, setQeury] = useState("");
  const [type, setType] = useState("recently_created");
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState();
  const [isSearchItem, setIsSearchItem] = useState(false);
  const nl2br = require("react-nl2br");
  const [fullname, setFullname] = useState();
  const [bio, setBio] = useState();
  const [avatar, setAvatar] = useState();
  const [username, setUsername] = useState();
  const [profile, setProfile] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const { getAllNftInAddress } = useGetNFTs();
  const [nftsTotal, setNftsTotal] = useState(0);
  const [profileId, setProfileId] = useState();
  // -------
  const [nftList, setNftList] = useState([]);
  const [masterNftList, setMasterNftList] = useState([]);

  const [queries] = useSearchParams();
  const [collections, setCollections] = useState([]);
  const [hasLoadMore, setHasLoadMore] = useState(false);
  const [cursorPage, setCursorPage] = useState("");
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState();


  const {
    walletActive,
    address,
    tokenBalanceList,
    disconnectWallet,
    currentConnector,
  } = useContext(WalletContext);
  const { checkAddressHasConnectedEmail, clearToken, walletConnectTheConcert } =
    useAuthen();

  const queryUrl = queries.get("tab");

  let tabParam = queryUrl;
  let tabActive = "items";

  if (queryUrl === "watchlist") {
    tabActive = "watchlist";
  } else if (queryUrl === "favourited") {
    tabActive = "favourited";
  }

  let navigate = useNavigate();
  const getProfile = (isInit) => {
    api.users.getProfile(
      (response) => {
        let data = response.data;

        setProfile(data.data);
        let firstName = data.data.first_name;
        let lastName = data.data.last_name;
        let username = data.data.username;
        let getId = data.data.id;
        let phone = data.data.phone;

        let bio = data.data.bio;
        let userAvatar = data.data.avatar;
        let fullname = firstName + " " + lastName;

        if (!_.isEmpty(data.data.avatar)) {
          let user_avatar =
            env.RES_URL +
            "/w_120,h_120,c_thumb/" +
            data.data.avatar.id +
            "/" +
            data.data.avatar.name;
          sessionStorage.setItem("user_avatar", user_avatar);
        }
        if (!_.isEmpty(data.data.phone)) {

          sessionStorage.setItem("is_user_verify", true);
        }else {
          sessionStorage.removeItem("is_user_verify");
        }
        if (!_.isEmpty(data.data.cover)) {
          let userCover = data.data.cover.url;
          setCover(userCover);
        }
        setFullname(firstName && lastName ? fullname : undefined);
        setBio(bio);
        setAvatar(userAvatar);
        setUsername(username);
        setProfileId(getId);
        setVerified(phone)
      },
      (error) => {
        if (isInit) {
          setError(error);
        }
      },
      []
    );
  };
  useEffect(() => {
    setNftList([]);
    setMasterNftList([]);
    setNftsTotal(0);
    let access_token = Cookies.get("access_token", { domain: window.location.hostname });
    if (
      access_token === "null" ||
      access_token === undefined ||
      access_token === null
    ) {
      navigate("/login", { replace: true });
    } else {
      getProfile(true);
    }
  }, []);

  useEffect(() => {
    if (currentConnector && address) {
      const request = walletConnectTheConcert(currentConnector, true);
      if (request) {
        walletConnect(request);
      }
    }
  }, [address, currentConnector]);

  const walletConnect = (request) => {
    api.wallets.walletConnect(
      (response) => {
        let access_token = _.get(response, "data.data.access_token");
        let refresh_token = _.get(response, "data.data.refresh_token");
        if (access_token && refresh_token) {
          sessionStorage.setItem("access_token", access_token);
          sessionStorage.setItem("refresh_token", refresh_token);
          Cookies.set("access_token", access_token, {
            domain: window.location.hostname,
          });
          Cookies.set("refresh_token", refresh_token, {
            domain: window.location.hostname,
          });
          navigate("/profile", { replace: true });
        }
      },
      (error) => {
        let statusCode = _.get(error, "data.error.code");
        let message = _.get(error, "data.error.message");

        if (statusCode === 400) {
          setErrorMessage(message);
          handleShowModalError();
        }
      },
      request
    );
  };

  const handleShowModalError = () => setShowModalError(true);
  const handleCloseModalError = () => {
    setShowModalError(false);
    disconnectWallet();
    clearToken();
    navigate("/login", { replace: true });
  };

  const handleCopy = (e) => {
    showCopySuccess(!copysuccess);
    navigator.clipboard.writeText(address);
    let timer1 = setTimeout(() => showCopySuccess(false), delay * 1000);
    return () => {
      clearTimeout(timer1);
    };
  };

  const shareToSocial = () => {
    setShowModalShare(true);
  };

  const getProducts = () => {
    var orderQuery = "";
    var sortQuery = "";

    switch (type) {
      case "recently_created":
        orderQuery = "created_at";
        sortQuery = "desc";
        break;
      case "oldest_first":
        orderQuery = "created_at";
        sortQuery = "asc";
        break;
      case "most_favorited":
        orderQuery = "favorited";
        sortQuery = "desc";
        break;
      default:
        orderQuery = "created_at";
        sortQuery = "desc";
    }

    let request = {
      collection_id: id,
      q: query,
      order: orderQuery,
      sort: sortQuery,
    };

    api.products.getProducts(
      (response) => {
        setProducts(response.data.data.record);
        setPagination(response.data.data.pagination);
      },
      (error) => {
        setError(error);
      },
      request
    );
  };

  const handleSearchChange = (event) => {
    const queryString = event.target.value;
    setQeury(queryString);
    let handleLength = queryString.length;
    setIsSearchItem(handleLength > 0 ? true : false);

    if (handleLength === 0 && isSearchItem === true) {
      resetNFTlist();
    }
  };

  const handleSelectFilter = (event) => {
    setType(event.target.value);
  };

  useEffect(() => {}, [query]);

  // useEffect(() => {

  //   getProducts();
  // }, [type])

  const SubmitSearch = async (data) => {
    setIsSearchItem(true);

    searchNftFromQuery(data["search_product"]);
  };

  const resetNFTlist = () => {
    setNftList([]);
    setNftsTotal(0);
    setNftList(masterNftList);
    setNftsTotal(masterNftList.length);
  };

  useEffect(() => {
    setNftList([]);
    setMasterNftList([]);
    setNftsTotal(0);
    if (chainId && address) {
      setShareURL(env.FRONT_URL + `profile/${address ?? ""}`);
      getNFTlist();
    }
    getWatchListCollection();
  }, []);

  useEffect(() => {
    setNftList([]);
    setMasterNftList([]);
    setNftsTotal(0);
    if (chainId && address) {
      setShareURL(env.FRONT_URL + `profile/${address ?? ""}`);

      getNFTlist();
    }
  }, [chainId, address]);

  const getNFTlist = async () => {
    var allNft = [];

    const { data } = await getAllNftInAddress(
      address,
      networkMap[chainId].chainId,
      cursorPage
    );
    setLoading(false);
    setHasLoadMore(data.result.length < data.total && data.cursor != null);
    setCursorPage(data.cursor);
    allNft = allNft.concat(data.result);
    setNftList((oldArray) => [...oldArray, ...allNft]);
    setMasterNftList((oldArray) => [...oldArray, ...allNft]);
    setNftsTotal(data.total);
  };

  useEffect(() => {
    if (chainId && address) {
      setShareURL(env.FRONT_URL + `profile/${address ?? ""}`);
    } else {
      setShareURL(env.FRONT_URL + `profile/${profileId ?? ""}`);
    }
  }, [chainId, address, shareURL, profileId]);

  const searchNftFromQuery = (query) => {
    let matchingStrings = [];

    masterNftList.forEach((list) => {
      if (
        list.name.toLocaleLowerCase().search(query.toLocaleLowerCase()) > -1
      ) {
        matchingStrings.push(list);
      }
    });

    setNftList([]);
    setNftsTotal(0);
    setNftList(matchingStrings);
    setNftsTotal(matchingStrings.length);
  };

  const meta = {
    title: fullname ? fullname : "UNNAMED" + "| THECONCERT.IO",
    description: bio,
    canonical: env.FRONT_URL + `profile`,
    meta: {
      charset: "utf-8",
      name: {
        keywords: "TCC, TheConcert",
      },
    },
  };

  const getWatchListCollection = () => {
    api.users.getUserFavorites(
      (response) => {
        setCollections(response.data.data.record);
      },
      (error) => {
        setError(error);
      },
      { fileable_type: "collections" }
    );
  };

  useEffect(() => {}, [cursorPage]);

  const loadFunc = async () => {
    if (loading == false && cursorPage != "") {
      getNFTlist();
    }
  };

  const ModalShareItem = (props) => {
    return (
      <Modal
        {...props}
        backdrop="static"
        keyboard={false}
        className="modal-alert"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Share This Collection</Modal.Title>
          <CloseButton variant="white" onClick={closeModalShare} />
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Link</Form.Label>
            <Form.Control type="text" value={shareURL} disabled readOnly />
          </Form.Group>
          <div>
            <h3>Share</h3>
            <FacebookShareButton
              url={shareURL}
              quote={_.get(collection, "name")}
              hashtag={
                _.get(collection, "name")
                  ? "#" + _.get(collection, "name").replace(/\s/g, "")
                  : null
              }
              description={_.get(collection, "description")}
            >
              <i className="_icon-share-footer _icon-share-footer-facebook"></i>
            </FacebookShareButton>
            <TwitterShareButton
              title={_.get(collection, "name")}
              url={shareURL}
              hashtags={[
                _.get(collection, "name")
                  ? _.get(collection, "name").replace(/\s/g, "")
                  : null,
                "Theconcert",
              ]}
            >
              <i className="_icon-share-footer _icon-share-footer-twitter"></i>
            </TwitterShareButton>
            <TelegramShareButton
              url={shareURL}
              title={_.get(collection, "name")}
            >
              <i className="_icon-share-footer _icon-share-footer-telegram"></i>
            </TelegramShareButton>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  // useEffect(() => {
  //   if (window.ethereum) {
  //     handleEthereum();
  //   } else {
  //     window.addEventListener("ethereum#initialized", handleEthereum, {
  //       once: true,
  //     });
  //     setTimeout(handleEthereum, 3000); // 3 seconds
  //   }
  // }, []);

  // function handleEthereum() {
  //   const { ethereum } = window;
  //   if (ethereum && ethereum.isMetaMask) {
  //     console.log("Ethereum successfully detected!");
  //   } else {
  //     console.log("Please install MetaMask!");
  //   }
  // }

  return (
    <React.Fragment>
      <DocumentMeta {...meta}>
        <Layout>
          <Col>
            <div
              className="header-section"
              style={{ backgroundImage: `url("${cover}")` }}
            >
              <Container className="header-section-content">
                <Row>
                  <Col xs={12} sm={3}>
                    <img
                      className="collection-avatar"
                      src={
                        _.isEmpty(avatar)
                          ? defaultAvatar
                          : env.RES_URL +
                            "/w_285,h_285,c_thumb/" +
                            avatar.id +
                            "/" +
                            avatar.name
                      }
                    />
                  </Col>
                  <Col sm={9}>
                    <div>
                      <h2 className="collection-title">
                        {fullname == null || fullname === undefined
                          ? "unnamed"
                          : fullname}{" "}
                      </h2>
                      {address ? (
                        <div className="hash-profile" onClick={handleCopy}>
                          {chainId === 1 ? (
                            <i className="_user-icon _user-icon-polygon"></i>
                          ) : (
                            <i className="_user-icon _user-icon-polygon"></i>
                          )}
                          {address} <ContentCopyIcon />
                        </div>
                      ) : null}
                      <div className="collection-desc">
                        {bio == null ? "" : nl2br(bio)}
                      </div>
                      <div className="shares mt-3">
                        <i
                          className="_icon _icon-share"
                          onClick={shareToSocial}
                        ></i>
                        <Link to={"/settings"}>
                          {verified === null || verified === '' ?(<span className="noti">1</span>):null}
                          <i className="_icon _icon-settings"></i>
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="box-collection-info">
              <Container>
                <Tabs
                  defaultActiveKey={queryUrl === tabParam ? tabActive : "items"}
                  id="user-collection-nft"
                >
                  <Tab eventKey="items" title="COLLECTED">
                    <Container className="filter-section">
                      <Row>
                        <Col md={12} className="_search-collection">
                          <Form
                            className="_search-item"
                            onSubmit={handleSubmit(SubmitSearch)}
                          >
                            <i className="icon-search-item"></i>
                            <FormControl
                              type="search"
                              placeholder="SEARCH BY NAME"
                              className="input-search"
                              aria-label="SEARCH BY NAME"
                              name="search_product"
                              {...register("search_product", {
                                onChange: (e) => {
                                  handleSearchChange(e);
                                },
                              })}
                            />
                          </Form>
                        </Col>
                        {/* <Col md={3} xs={6}>
                            <div className="filter-item">
                              <Form.Select aria-label="Default select" value={type} onChange={handleSelectFilter}>
                                <option value="recently_created">Recently Created</option>
                                <option value="oldest_first">Oldest First</option>
                                <option value="most_favorited">Most Favorited</option>
                              </Form.Select>
                            </div>
                          </Col> */}
                      </Row>
                    </Container>

                    <div className="section-control-item">
                      <Row className="justify-content-md-center">
                        <Col xs={6}>
                          <span className="sum-items">{nftsTotal} items</span>
                        </Col>
                        <Col xs={6}></Col>
                      </Row>
                    </div>

                    <Container className="item-section mt-5 mb-5">
                      <InfiniteScroll
                        dataLength={nftList.length}
                        next={loadFunc}
                        hasMore={hasLoadMore}
                        loader={<h4>Loading...</h4>}
                      >
                        <Row>
                          <ProfileItemNft nftList={nftList} />
                        </Row>
                      </InfiniteScroll>
                    </Container>
                  </Tab>

                  <Tab eventKey="favourited" title="FAVOURITED">
                    <div className="mt-5 mb-5">
                      <Row>
                        <FavoritesNft />
                      </Row>
                    </div>
                  </Tab>

                  <Tab eventKey="watchlist" title="WATCHLIST">
                    <div className="_my-collections">
                      <Row>
                        {collections.length > 0 ? (
                          collections.map((item, index) => {
                            let images = _.get(item.fileable, "images");
                            let logoImage = _.filter(images, {
                              tag: "logo",
                            })[0];
                            let coverImage = _.filter(images, {
                              tag: "featured",
                            })[0];

                            let user_cover = !_.isEmpty(coverImage)
                              ? env.RES_URL +
                                "/w_400,h_200,c_thumb/" +
                                coverImage.id +
                                "/" +
                                coverImage.name
                              : env.RES_URL +
                                "/w_400,h_200,c_thumb/" +
                                logoImage.id +
                                "/" +
                                logoImage.name;

                            let user_avatar =
                              env.RES_URL +
                              "/w_200,h_200,c_thumb/" +
                              logoImage.id +
                              "/" +
                              logoImage.name;

                            return (
                              <Col
                                md={4}
                                key={
                                  "collection_discover-" +
                                  _.get(item.fileable, "id")
                                }
                              >
                                <Link
                                  to={
                                    "/collection/" + _.get(item.fileable, "id")
                                  }
                                >
                                  <div className="collection">
                                    <div className="avatar">
                                      <img src={user_avatar} />
                                    </div>
                                    <div className="about">
                                      <h2>
                                        {_.get(item.fileable, "model")}{" "}
                                        <i className="_icon _icon-verify"></i>
                                      </h2>
                                      <div className="owner">
                                        OWNED BY :{" The Concert IO "}
                                        {_.get(item.fileable, "-")}
                                      </div>
                                      <div className="description">
                                        {_.get(item.fileable, "description")}
                                      </div>
                                    </div>
                                    <div className="cover">
                                      <img src={user_cover} />
                                    </div>
                                  </div>
                                </Link>
                              </Col>
                            );
                          })
                        ) : (
                          <div className="p-5 text-center"> No Item</div>
                        )}
                      </Row>
                    </div>
                  </Tab>

                  <Tab eventKey="activity" title="ACTIVITY">
                    {address ? (
                      <UserActivity address={address} />
                    ) : (
                      <div className="p-5 text-center"> No Item</div>
                    )}
                  </Tab>
                </Tabs>
              </Container>
            </div>
          </Col>

          <ModalShareItem
            show={showModalShare}
            onHide={closeModalShare}
          ></ModalShareItem>

          {copysuccess ? (
            <AlertBox title="COPY ADDRESS SUCCESS" variant="success"></AlertBox>
          ) : null}
        </Layout>
      </DocumentMeta>
    </React.Fragment>
  );
};

export default UserProfile;
