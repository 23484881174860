import { InjectedConnector } from "@web3-react/injected-connector";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { Button, Container, Image } from "react-bootstrap";
import { useEffect, useState } from "react";

const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};

const injected = new InjectedConnector({
  supportedChainIds: [1, 137, 80001],
});

export default function Sandbox({}) {
  const { library, account, activate, deactivate, active } = useWeb3React();
  const [buttonShow, setButtonShow] = useState(false);

  useEffect(() => {
    console.log(active);
    console.log(account);
    console.log(library);
  }, [active, account, library]);

  const ethereumButton = async () => {
    console.log("data");
    const ethereum = window.ethereum;

    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const account = accounts[0];
    console.log(accounts);
    console.log(account);

    alert(accounts);
  };

  return (
    <Container>
      <Button
        onClick={() => {
          ethereumButton();
        }}
      >
        {buttonShow ? "Enable Ethereum" : "Disable Ethereum "}
      </Button>

      <Button
        onClick={() => {
          if (library) {
            deactivate();
          } else {
            activate(injected);
          }
        }}
      >
        {library ? "Active" : "No Active"} {account}
      </Button>
    </Container>
  );
}
