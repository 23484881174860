import products from "./products";
import users from "./users";
import wallets from "./wallets";
import collections from "./collections";
import categories from "./categories";
import blockchains from "./blockchains";
import contents from "./contents"
import otp from "./otp";

export default {
  products,
  users,
  wallets,
  collections,
  categories,
  blockchains,
  contents,
  otp
};
