import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/sass/airdrop_nft.scss";

import {
  Modal,
  Button,
  Carousel,
  Card,
  Stack,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import logoBlue from "../assets/images/logo_blue.jpg";
import collectionLogo from "../assets/images/avatar-logo.png";

import "../index";
import api from "../api"
import _ from "lodash"
import { Link } from "react-router-dom";
import env from '../env'

const AirDropNft = () => {
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const handleCloseModalSuccess = () => setShowModalSuccess(false);
  const [showModalFail, setShowModalFail] = useState(false);
  const handleCloseModalFail = () => setShowModalFail(false);
  const [activeIndex, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const [products, setProducts] = useState([]);

  React.useEffect(() => {

    let request = "?highlight=true"
    api.collections.getCollections(
        (response) => {
          setProducts(_.get(response,'data.data.record'))
        },
        (error) => {
        }, request)
},[])


  const nftCampagin = products.map((data, index) => {

    return (
      <Carousel.Item key={index}>
        <Link to={
          "/collection/" + _.get(data, 'id')}
        >
       
          <Card className="bg">
            <Card.Body>
              <Container>
                <Row className="justify-content-md-center">
                  <Col md={5} className="box-logo-collection"> 
                    <img className="logo-radius" src={env.RES_URL + '/w_420,h_420,c_thumb/'+data.images[1].id+'/'+data.images[1].name}></img>
                  </Col>
                  <Col md={7}>
                    <Stack>
                      <div className="logo-collection d-none d-md-block">
                        <img src={env.RES_URL + '/w_98,h_98,c_thumb/'+data.images[0].id+'/'+data.images[0].name}></img>
                      </div>
                      <div className="title">{data.name}  <i className="_icon _icon-verify"></i></div>
                      <Link to={
          "/profile/" + _.get(data.connects, 'uid')}
        >
                      <div className="title-owner">
                        OWNED BY :  {_.get(data, 'store.name')}
                      </div>
                      </Link>
                      <div className="desc-collection">
                        {data.description}
                      </div>

                      <Container className="card-claim">
                        <Row>
                          <Col>
                            <span className="airdrop-title">AIRDROP</span>
                          </Col>
                          <Col>
                            <div className="button-claim-image box">
                              <div className="button-claim-title">CLAIM</div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </Stack>
                  </Col>
                </Row>
                {/* </Link> */}
              </Container>

            </Card.Body>
          </Card>
        </Link>
      </Carousel.Item>
    );
  });

  return (
    <>
      <div className="container text-center _box-airdrop-nft">
        <Modal
          show={showModalSuccess}
          onHide={handleCloseModalSuccess}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>Your has Received NFT 1 ea</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseModalSuccess}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showModalFail}
          onHide={handleCloseModalFail}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Warning
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>Please check code for receive nft</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModalFail}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="_reccommeded-collection">
          <h2>RECOMMENDED COLLECTIONS</h2>
        </div>

        <Carousel
          controls={false}
          indicators={true}
          activeIndex={activeIndex}
          onSelect={handleSelect}
        >
          {nftCampagin}
        </Carousel>

        {/* <Row className="justify-content-center">{indicators}</Row> */}
      </div>
    </>
  );
};

export default AirDropNft;
