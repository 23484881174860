import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ContextProvider from "./providers/ContextProvider";
import { MoralisProvider } from "react-moralis";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import env from "./env";
import IndexRoutes from "./IndexRoutes";

const serverUrl = env.REACT_APP_MORALIS_SERVER_URL;
const appId = env.REACT_APP_MORALIS_APP_ID;

Sentry.init({
  dsn: env.REACT_APP_SENTRY_DNS,
  integrations: [new BrowserTracing()],
  environment: env.REACT_APP_SENTRY_ENVIRONMENT,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ContextProvider>
    <MoralisProvider serverUrl={serverUrl} appId={appId}>
      <IndexRoutes />
    </MoralisProvider>
  </ContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
