import React, { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import "../assets/sass/login_wallet.scss";
import {
  Col,
  Container,
  ListGroup,
  Row,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import _ from "lodash";

import { useForm } from "react-hook-form";
import "../assets/sass/modal.scss";
import iconSuccess from "../assets/images/icon-success.svg";
import iconError from "../assets/images/icon-error.svg";

import api from "../api";

const SignUp = () => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const errorClose = () => setError(false);
  const errorShow = () => setError(true);
  const alertClose = () => setAlert(false);
  const alertShow = () => setAlert(true);
  const [alertTitle, setAlertTitle] = useState();
  const [alertDesc, setAlertDesc] = useState();
  const [errorTitle, setErrorTitle] = useState();
  const [errorDesc, setErrorDesc] = useState();

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordConfirmType, setPasswordConfirmType] = useState("password");
  const [passwordConfirmInput, setPasswordConfirmInput] = useState("");


  const handlePasswordChange =(evnt)=>{
      setPasswordInput(evnt.target.value);
  }
  const handlePasswordConfirmChange =(evnt)=>{
    setPasswordConfirmInput(evnt.target.value);
  }

  const togglePassword =()=>{
    if(passwordType==="password") {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }
  const toggleConfirmPassword =()=>{
    if(passwordConfirmType==="password") {
      setPasswordConfirmType("text")
     return;
    }
    setPasswordConfirmType("password")
  }

  const Submit = async (data) => {
    
    let request = {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      password: data.password,
    };
    api.users.register(
      (response) => {
        let user = _.get(response, "data.data");
        if (!_.isEmpty(user)) {
          setAlertTitle("Succeed");
          setAlertDesc("registered successfully");
          alertShow();
          setTimeout(() => {
            window.location.href = "/login?action=login";
          }, 1000);
        }
      },
      (error) => {
        /*if(_.get(error,'data.error.field')== 'email'){
                    alert('อีเมลนี้ไม่สามารถใช้ได้')
                }else{
                    setErrorTitle("Failed")
                    setErrorDesc("Failed to register")
                    errorShow()
                }*/
        setErrorTitle("Failed to register");
        setErrorDesc("");
        errorShow();
        
      },
      request
    );
  };

  return (
    <React.Fragment>
      <Layout>
        <div className="login-section">
          <Container>
            <div id="emailsignup" className="email-sign-up">
              <div className="box-login-select">
                <div className="box-policy text-center">
                  <h2 className="_font-large-x">SIGN UP</h2>
                  <span className="policy-title">
                    already have an account? <a href="/login">sign in</a>
                  </span>
                </div>
                <div className="card-center">
                  <Form
                    className="login-email-form"
                    onSubmit={handleSubmit(Submit)}
                  >
                    <Form.Group className="mb-3" controlId="formFirstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="first_name"
                        placeholder="Enter Your first name"
                        maxlength="50"
                        {...register("first_name", {
                          required: true,
                          minLength: 3,
                          maxLength: 50,
                        })}
                      />
                      <div className="text-danger">
                        {errors.first_name?.message}
                        {errors.first_name?.type === "required" &&
                          "First name is required"}
                        {errors.first_name?.type === "minLength" &&
                          "Enter a name that is at least 3 letters "}
                        {errors.first_name?.type === "maxLength" &&
                          "Value must be less than 50 characters"}
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formLastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="last_name"
                        placeholder="Enter Your last name"
                        maxlength="50"
                        {...register("last_name", {
                          required: true,
                          minLength: 3,
                          maxLength: 50,
                        })}
                      />
                      <div className="text-danger">
                        {errors.last_name?.message}
                        {errors.last_name?.type === "required" &&
                          "Last name is required"}
                        {errors.last_name?.type === "minLength" &&
                          "Enter a last name that is at least 3 letters "}
                        {errors.last_name?.type === "maxLength" &&
                          "Value must be less than 50 characters"}
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        maxlength="200"
                        {...register("email", {
                          required: true,
                          minLength: 3,
                          maxLength: 200,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      <div className="text-danger">
                        {errors.email?.message}
                        {errors.email?.type === "required" &&
                          "Email is required"}
                        {errors.email?.type === "minLength" &&
                          "Enter a email that is at least 3 letters "}
                        {errors.email?.type === "maxLength" &&
                          "Value must be less than 200 characters"}
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formPassword">
                      <Form.Label>Create Password</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          onChange={handlePasswordChange}
                          type={passwordType}
                          name="password"
                          placeholder="Password"
                          maxlength="20"
                          {...register("password", {
                            required: true,
                            minLength: 8,
                            maxLength: 20,
                          })}
                        />
                        {passwordType==="password"?<i className="_icon _icon-close-eye" onClick={togglePassword}></i>:<i className="_icon _icon-eye" onClick={togglePassword}></i> }
                      </div>
                      <div className="text-danger">
                        {errors.password?.message}
                        {errors.password?.type === "required" &&
                          "Password is required"}
                        {errors.password?.type === "minLength" &&
                          "Enter a password that is at least 8 letters "}
                        {errors.password?.type === "maxLength" &&
                          "Value must be less than 20 characters"}
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formConfirmPassword">
                      <Form.Label>Confirm Password</Form.Label>
                      <div className="position-relative">    
                        <Form.Control
                          onChange={handlePasswordConfirmChange}
                          type={passwordConfirmType}                      
                          placeholder="Confirm Password"
                          name="confirm_password"
                          maxlength="20"
                          {...register("confirm_password", {
                            required: true,
                            minLength: 8,
                            maxLength: 20,
                            validate: (val) => {
                              if (watch("password") != val) {
                                return "Passwords do no match";
                              }
                            },
                          })}
                        />
                        {passwordConfirmType==="password"?<i className="_icon _icon-close-eye" onClick={toggleConfirmPassword}></i>:<i className="_icon _icon-eye" onClick={toggleConfirmPassword}></i> }
                      </div>  
                      <div className="text-danger">
                        {errors.confirm_password?.message}
                        {errors.confirm_password?.type === "required" &&
                          "Confirm password is required"}
                        {errors.confirm_password?.type === "minLength" &&
                          "Enter a confirm password that is at least 8 letters "}
                        {errors.confirm_password?.type === "maxLength" &&
                          "Value must be less than 20 characters"}
                      </div>
                    </Form.Group>

                    <div className="text-center pt-3">
                      <Button className="btn-nft" type="submit">
                        SIGN UP
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <Modal
              show={alert}
              onHide={alertClose}
              backdrop="static"
              keyboard={false}
              className="modal-alert"
            >
              <Modal.Body>
                <div className="text-center">
                  <img src={iconSuccess} className="icon-success" />
                  <h2>{alertTitle}</h2>
                  <p className="_font-medium-x _gray">{alertDesc}</p>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={error}
              onHide={errorClose}
              backdrop="static"
              keyboard={false}
              className="modal-alert"
            >
              <Modal.Body>
                <div className="text-center">
                  <img src={iconError} className="icon-error" />
                  <h2>{errorTitle}</h2>
                  <p className="_font-medium-x _gray">{errorDesc}</p>
                  <Button className="btn-nft mt-3" onClick={errorClose}>
                    TRY AGAIN
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </Container>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default SignUp;
