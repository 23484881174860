import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { useWeb3React } from "@web3-react/core";
import { ConnectorNames } from "../constants";
import logoMetaMask from "../assets/images/logo_meta_mask.webp";
import logoWalletConnect from "../assets/images/logo_wallet_connect.webp";
import logoCoinBaseWallet from "../assets/images/logo_coinbase_wallet.webp";
import logoGlow from "../assets/images/glow.svg";
import logoPhantomWallet from "../assets/images/logo_phantom_wallet.svg";
import { CHAIN_ID } from "../constants";
import { connectorsByName } from "../utils/web3Connect";
import { UnsupportedChainIdError } from "@web3-react/core";
import { useGlowContext } from "@glow-app/glow-react";
import { useConnectPhantomWallet } from "../hooks/useConnectPhantomWallet";
import {
  useAccountBalance,
  useGetAllTokenInAddress,
  useGetBalance,
} from "../hooks/useAccountBalance";
import {
  networkMap,
  supportedChainlist,
  networkMapForMetamask,
} from "../utils/web3Connect";

const providerWalletList = [
  {
    logo: logoMetaMask,
    title: "MetaMask",
    popular: true,
    connector: ConnectorNames.Injected,
  },
  // {
  //   logo: logoWalletConnect,
  //   title: "Wallet Connect",
  //   popular: false,
  //   connector: ConnectorNames.WalletConnect,
  // },
  // {
  //   logo: logoCoinBaseWallet,
  //   title: "Coinbase Wallet",
  //   popular: false,
  //   connector: ConnectorNames.CoinbaseWallet,
  // },
  // {
  //   logo: logoPhantomWallet,
  //   title: "Phantom",
  //   popular: false,
  //   connector: ConnectorNames.PhantomWallet,
  // },
  // {
  //   logo: logoGlow,
  //   title: "Glow",
  //   popular: false,
  //   connector: ConnectorNames.Glow,
  // },
];

const setupNetwork = async (chainId) => {
  if (window.ethereum) {
    try {
      if (window.ethereum.request) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [networkMapForMetamask[chainId]],
        });
      }

      return true;
    } catch (error) {
      if (error.code == -32602) {
        return true;
      }
      console.error("Failed to setup the network in Metamask: toche ", error);
      return false;
    }
  } else {
    console.error(
      "Can't setup the BSC network on metamask because window.ethereum is undefined"
    );
    return false;
  }
};

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [walletActive, setWalletActive] = useState();
  const [address, setAddress] = useState("");
  const [currentConnector, setCurrentConnector] = useState();
  const { library, chainId, account, activate, active, deactivate, error } =
    useWeb3React();
  const { user, signIn, signOut } = useGlowContext();
  const { connectHandler, disconnectHandler, connected, addressWallet } =
    useConnectPhantomWallet();

  const { getAllTokenInAddress } = useGetAllTokenInAddress();
  const [tokenBalanceList, setTokenBalanceList] = useState([]);
  const [balance, setBalance] = useState();
  const [currentChainIdHasSupport, setCurrentChainIdHasSupport] = useState();

  useEffect(() => {
    const connectorName = window?.localStorage?.getItem("connectorName");
    if (
      connectorName &&
      connectorName != undefined &&
      connectorName != "undefined"
    ) {
      connectWallet(connectorName);
    }
  }, []);

  useEffect(() => {
    window?.localStorage?.setItem("connectorName", currentConnector);
  }, [currentConnector]);

  useEffect(() => {
    updateAllTokenAdderss();
  }, [address]);

  useEffect(() => {
    if (user) {
      setAddress(user.address);
      setWalletActive(true);
    }
  }, [user]);

  useEffect(() => {
    if (account) {
      setAddress(account);
      setWalletActive(true);
    }
  }, [account]);

  useEffect(() => {
    if (addressWallet) {
      setAddress(addressWallet);
      setWalletActive(true);
    }
  }, [addressWallet]);

  const connectWallet = async (connectorName) => {
    if (connectorName) {
      const connector = connectorsByName[connectorName];
      switch (connectorName) {
        case ConnectorNames.PhantomWallet:
          connectHandler();
          break;
        case ConnectorNames.Glow:
          signIn();
          break;
        default:
          await activate(
            connector,
            async (error) => {
              if (error instanceof UnsupportedChainIdError) {
                const hasSetup = await setupNetwork();
                if (hasSetup) {
                  await activate(connector);
                  setCurrentConnector(connectorName);
                  setCurrentChainIdHasSupport(true);
                } else {
                  setCurrentChainIdHasSupport(false);
                }
              } else {
              }
            },
            false
          );
          setCurrentConnector(connectorName);
          setCurrentChainIdHasSupport(true);
      }
    }
  };

  const disconnectWallet = async () => {
    window?.localStorage?.clear();
    setCurrentChainIdHasSupport(null);
    setCurrentConnector(null);
    setWalletActive(false);
    switch (currentConnector) {
      case ConnectorNames.PhantomWallet:
        disconnectHandler();
        break;
      case ConnectorNames.Glow:
        signOut();
        break;
      default:
        deactivate();
    }
  };

  const updateAllTokenAdderss = async () => {
    if (address) {
      const tokenBalanceList = await getAllTokenInAddress(address);
      setTokenBalanceList(tokenBalanceList);
    }
  };

  const switchNetwork = async (chainId) => {
    if (window.ethereum.networkVersion !== chainId) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: networkMap[chainId].chainId }],
        });
        if (currentConnector) {
          connectWallet(currentConnector);
          return true;
        }
        return true;
      } catch (err) {
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          const hasSetup = setupNetwork();
          if (hasSetup && currentConnector) {
            connectWallet(currentConnector);
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    }
  };

  return (
    <WalletContext.Provider
      value={{
        walletActive,
        address,
        providerWalletList,
        currentConnector,
        connectWallet,
        disconnectWallet,
        tokenBalanceList,
        switchNetwork,
        currentChainIdHasSupport,
        setupNetwork,
        setCurrentChainIdHasSupport,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
