import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
  Modal,
  Form,
  Button,
  CloseButton,
} from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import "../assets/sass/airdrop_campaign.scss";
import companyLogo from "../assets/images/campaign/tcc-icon-elite.png";
import iconEye from "../assets/images/campaign/icon-eye.png";
import iconUserAdd from "../assets/images/campaign/icon-user-add.png";
import iconFavorite from "../assets/images/campaign/icon-favorite.png";
import btnClaim from "../assets/images/campaign/btn-claim.png";
import iconGroupOne from "../assets/images/campaign/icon-group-one.png";
import iconGroupTwo from "../assets/images/campaign/icon-group-two.png";
import iconGroupThree from "../assets/images/campaign/icon-group-three.png";
import iconGroupFour from "../assets/images/campaign/icon-group-four.png";
import api from "../api";
import _ from "lodash";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import iconSuccess from "../assets/images/icon-success.svg";
import iconError from "../assets/images/icon-error.svg";
import { WalletContext } from "../providers/WalletProvider";
import { useAuthen } from "../hooks/useAuthen";
import Cookies from "js-cookie";
import env from "../env";
import DocumentMeta from "react-document-meta";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
} from "react-share";

const ProductDetail = () => {
  const { id, collection_id } = useParams();
  let navigate = useNavigate();
  const {
    walletActive,
    address,
    tokenBalanceList,
    disconnectWallet,
    currentConnector,
  } = useContext(WalletContext);
  const { checkAddressHasConnectedEmail, clearToken, walletConnectTheConcert } =
    useAuthen();
  const [product, setProduct] = useState(null);
  const [productImage, setProductImage] = useState();
  const [error, setError] = useState(null);
  const [errorTitle, setErrorTitle] = useState();
  const [showModal, setShowModal] = useState(false);
  const [errorDesc, setErrorDesc] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessCodeModal, setSuccessCodeModal] = useState(false);
  const [showInvalidcodeModal, setShowInvalidcodeModal] = useState(false);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [shareURL, setShareURL] = useState();
  const [isFavorite, setIsFavorite] = useState(false);
  const nl2br = require("react-nl2br");

  useEffect(() => {
    getProduct(id);
    setShareURL(env.FRONT_URL + `product/${id}`);
  }, product);

  useEffect(() => {
    if (currentConnector && address) {
      const request = walletConnectTheConcert(currentConnector, true);
      if (request) {
        walletConnect(request);
      }
    }
  }, [address, currentConnector]);

  const walletConnect = (request) => {
    api.wallets.walletConnect(
      (response) => {
        let access_token = _.get(response, "data.data.access_token");
        let refresh_token = _.get(response, "data.data.refresh_token");
        if (access_token && refresh_token) {
          sessionStorage.setItem("access_token", access_token);
          sessionStorage.setItem("refresh_token", refresh_token);
          Cookies.set("access_token", access_token, {
            domain: window.location.hostname,
          });
          Cookies.set("refresh_token", refresh_token, {
            domain: window.location.hostname,
          });
        }
      },
      (error) => {
        let statusCode = _.get(error, "data.error.code");
        let message = _.get(error, "data.error.message");

        if (statusCode === 400) {
          setErrorMessage(message);
        }
      },
      request
    );
  };

  const getProduct = (id) => {
    let request = { collection_id: collection_id };
    api.products.getProductDetail(
      (response) => {
        let data = response.data.data;

        setProduct(data);
        let getIsFavorite = data.isFavorite;
        setIsFavorite(data.is_favorite);

        var productImage = _.first(data.images);

        setProductImage(productImage);
      },
      (error) => {
        setErrorTitle("");
        setErrorDesc("");
        errorShow();
      },
      {},
      id
    );
  };

  const handleShowPrivateCode = () => setShowModal(true);

  const handleClose = () => setShowModal(false);

  const handleCloseInvalidcodeModel = () => setShowInvalidcodeModal(false);

  const handleCloseSuccessCodeModal = () => setSuccessCodeModal(false);
  const errorShow = () => setError(true);
  const errorClose = () => setError(false);
  const [showModalShare, setShowModalShare] = useState(false);
  const closeModalShare = () => setShowModalShare(false);

  const handleToMyNFT = () => {
    navigate("/profile?tab=nft");
  };

  const shareToSocial = () => {
    setShowModalShare(true);
  };

  const favoriteProduct = () => {
    let access_token = Cookies.get("access_token", { domain: window.location.hostname });
    if (access_token == "null" || access_token === undefined) {
    } else {
      setIsFavorite(isFavorite ? false : true);
      let request = {
        product_id: id,
        action: !isFavorite ? "add" : "remove",
      };

      api.users.userFavorites(
        (response) => {
          let data = response.data.data;
        },
        (error) => {
          setErrorTitle("");
          setErrorDesc("");
          errorShow();
        },
        request
      );
    }
  };

  const meta = {
    title: _.get(product, "model") + " | THECONCERT.IO",
    description: _.get(product, "description"),
    canonical: env.FRONT_URL + `product/${id}`,
    meta: {
      charset: "utf-8",
      name: {
        keywords: "Collection," + _.get(product, "model"),
      },
      property: {
        "og:title": _.get(product, "model") + " | THECONCERT.IO",
        "og:url": env.FRONT_URL + `product/${id}`,
        "og:image": _.get(productImage, "url"),
        "og:description": _.get(product, "description.description"),
        "og:type": "website",
        "og:image:width": "1200",
        "og:image:height": "630",
        "og:site_name": "theconcert.io",

        "twitter:card": "summary_large_image",
        "twitter:title": _.get(product, "model"),
        "twitter:description": _.get(product, "description.description"),
        "twitter:image": _.get(productImage, "url"),
        "twitter:site": "theconcert.io",
        "twitter:creator": "theconcert.io",
        "twitter:domain": env.FRONT_URL + `product/${id}`,
      },
    },
  };

  return (
    <Layout>
      <DocumentMeta {...meta}>
        <div className="main-section">
          <div className="main-section-top">
            <Container>
              <div className="top-main-logo-airdrop">
                <Row>
                  <Col md={5}>
                    {productImage == null ? null : (
                      <img src={productImage.url} className="tcclogo-group" />
                    )}
                  </Col>
                  <Col md={7}>
                    <div className="top-info-box">
                      <div className="item">
                        <p>
                          <span className="tcc-title">
                            {product == null || product === undefined
                              ? ""
                              : product.model ?? ""}
                          </span>
                        </p>
                        <p>
                          <span className="tcc-sub-title">
                            Collection : {_.get(product, "collection.name")}
                          </span>
                        </p>
                        <p>
                          <span className="tcc-sub-title-2">
                            by :{" "}
                            {product == null || product === undefined
                              ? ""
                              : product.store.name ?? ""}
                          </span>
                        </p>
                      </div>
                    </div>
                    <Row className="justify-content-center colection-info">
                      <Col className="text-center align-content-middle">
                        <img src={iconEye} className="viewers" />
                        <p className="viewers">
                          {_.get(product, "viewed", 0)} views
                        </p>
                      </Col>
                      <div className="v-separator"></div>
                      <Col className="text-center align-content-middle">
                        <img src={iconUserAdd} className="viewers" />
                        <p className="viewers">
                          {_.get(product, "follower", 0)} followers
                        </p>
                      </Col>
                      <div className="v-separator"></div>
                      <Col className="text-center align-content-middle">
                        <img src={iconFavorite} className="viewers" />
                        <p className="viewers">
                          {_.get(product, "favorited", 0)} favorites
                        </p>
                      </Col>
                    </Row>
                    <div className="shares-box">
                      {isFavorite ? (
                        <i
                          className="_icon _icon-fav-active"
                          onClick={favoriteProduct}
                        ></i>
                      ) : (
                        <i
                          className="_icon _icon-fav"
                          onClick={favoriteProduct}
                        ></i>
                      )}

                      <i
                        className="_icon _icon-share"
                        onClick={shareToSocial}
                      ></i>
                      <TwitterShareButton
                        title={_.get(product, "model")}
                        url={shareURL}
                        hashtags={[
                          _.get(product, "model")
                            ? _.get(product, "model").replace(/\s/g, "")
                            : null,
                          "Theconcert",
                        ]}
                      >
                        <i className="_icon _icon-tweet"></i>
                      </TwitterShareButton>
                    </div>

                    {/* <div className="claim-airdrop">
                                            <Row className="align-items-center" md={2}>
                                                <Col xs={6} className="text-center"><h2>AIRDROP</h2></Col>
                                                <Col xs={6} ><img src={btnClaim} onClick={handleShowPrivateCode}

                                                /></Col>
                                            </Row>
                                        </div> */}
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <div className="main-section-bottom">
            <Container>
              <div className="description-box">
                <Row>
                  <h2>DETAILS</h2>
                  <p className="detail-box">
                    {product == null
                      ? ""
                      : nl2br(product.description.description)}
                  </p>
                </Row>
              </div>
            </Container>
          </div>
        </div>

        <Modal
          show={showSuccessCodeModal}
          onHide={handleCloseSuccessCodeModal}
          backdrop="static"
          keyboard={false}
          className="modal-alert"
        >
          <Modal.Body>
            <div className="text-center">
              <img src={iconSuccess} className="icon-success" />
              <h2>Congratulations</h2>
              <p className="_font-medium-x _gray">
                Your AIRDROP CLAIM is successful{" "}
                {product == null || product === undefined
                  ? ""
                  : product.model ?? ""}{" "}
                appear in your wallet in MY NFTS WALLET section
              </p>
              <Button className="btn-green mt-3" onClick={handleToMyNFT}>
                GO TO MY NFTS
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showInvalidcodeModal}
          onHide={handleCloseInvalidcodeModel}
          backdrop="static"
          keyboard={false}
          className="modal-alert"
        >
          <Modal.Body>
            <div className="text-center">
              <img src={iconError} className="icon-error" />
              <h2>INVALID CODE</h2>
              <p className="_font-medium-x _gray">
                Unfortunately, you are not eligible for a claim
              </p>
              <Button
                className="btn-nft mt-3"
                onClick={handleCloseInvalidcodeModel}
              >
                TRY AGAIN
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showModal}
          onHide={handleClose}
          className="modal-private-code"
        >
          <Modal.Header>
            <Modal.Title>PLEASE INPUT YOUR PRIVATE CODE</Modal.Title>
            <i className="_icon _icon-close" onClick={handleClose}></i>
          </Modal.Header>
          <Modal.Body>
            <div className="box-private-code">
              {/* <Form onSubmit={handleSubmit(submitPrivateCode)}>
                                <Form.Group
                                    className="mb-5 mt-3"
                                    controlId="Form.ControlInput1"
                                >
                                    <Form.Label>PRIVATE CODE</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        autoFocus
                                        className="input-private-code"
                                        name="claim_code"
                                        {...register("claim_code", {
                                            required: true,
                                            minLength: 3,
                                            maxLength: 30,
                                        })}
                                    />
                                    <div className="text-danger">
                                        {errors.claim_code?.message}
                                        {errors.claim_code?.type === "required" &&
                                            "Claim code is required"}

                                    </div>
                                </Form.Group>
                                <Button className="btn-nft" type="submit">
                                    SUBMIT
                                </Button>
                            </Form> */}
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={error}
          onHide={errorClose}
          backdrop="static"
          keyboard={false}
          className="modal-alert"
        >
          <Modal.Body>
            <div className="text-center">
              <img src={iconError} className="icon-error" />
              <h2>{errorTitle}</h2>
              <p className="_font-medium-x _gray">{errorDesc}</p>
              <Button className="btn-nft mt-3" onClick={errorClose}>
                TRY AGAIN
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={showModalShare}
          onHide={closeModalShare}
          backdrop="static"
          keyboard={false}
          className="modal-alert"
        >
          <Modal.Header>
            <Modal.Title>Share This Product</Modal.Title>
            <CloseButton variant="white" onClick={closeModalShare} />
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Link</Form.Label>
              <Form.Control type="text" value={shareURL} disabled readonly />
            </Form.Group>
            <div>
              <h3>Share</h3>
              <FacebookShareButton
                url={shareURL}
                quote={_.get(product, "model")}
                hashtag={
                  _.get(product, "model")
                    ? "#" + _.get(product, "model").replace(/\s/g, "")
                    : null
                }
                description={_.get(product, "description")}
              >
                <i className="_icon-share-footer _icon-share-footer-facebook"></i>
              </FacebookShareButton>
              <TwitterShareButton
                title={_.get(product, "model")}
                url={shareURL}
                hashtags={[
                  _.get(product, "model")
                    ? _.get(product, "model").replace(/\s/g, "")
                    : null,
                  "Theconcert",
                ]}
              >
                <i className="_icon-share-footer _icon-share-footer-twitter"></i>
              </TwitterShareButton>
              <TelegramShareButton
                url={shareURL}
                title={_.get(product, "model")}
              >
                <i className="_icon-share-footer _icon-share-footer-telegram"></i>
              </TelegramShareButton>
            </div>
          </Modal.Body>
        </Modal>
      </DocumentMeta>
    </Layout>
  );
};

export default ProductDetail;
