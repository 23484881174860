import env from "../env";
export const CHAIN_ID = Number(env.REACT_APP_CHAIN_ID_DEFAULT || 1);

export const RPC_URL = env.REACT_APP_RPC_URL || "";

export const ConnectorNames = {
  Injected: "Injected",
  WalletConnect: "WalletConnect",
  CoinbaseWallet: "CoinbaseWallet",
  PhantomWallet: "PhantomWallet",
  Glow: "Glow",
};
