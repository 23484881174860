import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormControl,
  Tabs,
  Modal,
  CloseButton,
} from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import "../assets/sass/collection_detail.scss";
import api from "../api";
import { Tab } from "bootstrap";
import _ from "lodash";
import {
  useParams,
  useNavigate,
  useSearchParams,
  Link,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import DocumentMeta from "react-document-meta";
import Cookies from "js-cookie";
import { useWeb3React } from "@web3-react/core";
import { useAuthen } from "../hooks/useAuthen";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { WalletContext } from "../providers/WalletProvider";
import defaultAvatar from "../assets/images/default-avatar.png";
import { ProfileItemNft } from "../components/ProfileItemNft";
import { FavoritesNft } from "../components/FavoritesNft";
import { useGetNFTs } from "../hooks/useGetNFTs";
import { networkMap, supportedChainlist } from "../utils/web3Connect";
import AlertBox from "../components/AlertBox";
import UserActivity from "../components/UserActivity";
import { networkMapFromFrontend } from "../utils/web3Connect";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
} from "react-share";
import env from "../env";
const delay = 3;

const PublicProfile = () => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { chainId } = useWeb3React();
  const [copysuccess, showCopySuccess] = useState(false);

  const { uid } = useParams();
  const [collection, setCollection] = useState(null);
  const [error, setError] = useState(null);
  const [cover, setCover] = useState("");
  const [showModalShare, setShowModalShare] = useState(false);
  const closeModalShare = () => setShowModalShare(false);
  const [shareURL, setShareURL] = useState();
  const [query, setQeury] = useState("");
  const [type, setType] = useState("recently_created");
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState();
  const [isSearchItem, setIsSearchItem] = useState(false);
  const nl2br = require("react-nl2br");
  const [fullname, setFullname] = useState();
  const [bio, setBio] = useState();
  const [avatar, setAvatar] = useState();
  const [publicusername, setPublicUsername] = useState();
  const [profile, setProfile] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const { getAllNftInAddress } = useGetNFTs();
  const [nftsTotal, setNftsTotal] = useState(0);
  const [publicUserAddress, setPublicUserAddress] = useState();
  // -------
  const [nftList, setNftList] = useState([]);
  const [masterNftList, setMasterNftList] = useState([]);
  const [userId, setUserId] = useState();

  const [queries] = useSearchParams();
  const [hasLoadMore, setHasLoadMore] = useState(false);
  const [cursorPage, setCursorPage] = useState("");
  const [loading, setLoading] = useState(false);
  const [cursorPageByIndex, setCursorPageByIndex] = useState({});

  const {
    walletActive,
    address,
    tokenBalanceList,
    disconnectWallet,
    currentConnector,
  } = useContext(WalletContext);
  const { checkAddressHasConnectedEmail, clearToken, walletConnectTheConcert } =
    useAuthen();

  const queryUrl = queries.get("tab");

  let tabParam = queryUrl;
  let tabActive = "items";

  if (queryUrl === "watchlist") {
    tabActive = "watchlist";
  } else if (queryUrl === "favourited") {
    tabActive = "favourited";
  }

  let navigate = useNavigate();

  useEffect(() => {
    let timer = setTimeout(() => {
      getNFTlist();

      getProfile();
    }, 200);

    // this will clear Timeout
    // when component unmount like in willComponentUnmount
    // and show will not change to true
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const getProfile = () => {
    let request =
      uid.length > 10 ? `?uid=${uid}&limit=1` : `?id=${uid}&limit=1`;

    api.users.getPublicProfile(
      (response) => {
        let data = response.data.data.record[0];

        let firstName = data.first_name;
        let lastName = data.last_name;
        let getUsername = data.username;

        let bio = data.bio;
        let userAvatar = data.avatar;
        let fullname = firstName + " " + lastName;

        if (chainId !== undefined) {
          let chainName = networkMapFromFrontend[chainId].chainName;
          // console.log("chainName", chainName);
          let getPublicUserAddress = _.filter(
            data.connects,
            function (connect) {
              return connect.service === chainName;
            }
          )[0];
          setPublicUserAddress(getPublicUserAddress.uid);
        }

        if (!_.isEmpty(data.avatar)) {
          let user_avatar =
            env.RES_URL +
            "/w_120,h_120,c_thumb/" +
            data.avatar.id +
            "/" +
            data.avatar.name;
          sessionStorage.setItem("user_avatar", user_avatar);
        }
        if (!_.isEmpty(data.cover)) {
          let userCover = data.data.cover.url;
          setCover(userCover);
        }
        setProfile(data);
        setFullname(firstName && lastName ? fullname : undefined);
        setBio(bio);
        setAvatar(userAvatar);
        setPublicUsername(
          getUsername === null || getUsername === undefined ? "" : getUsername
        );
        setUserId(uid);
        setShareURL(env.FRONT_URL + `profile/${uid}`);
      },
      (error) => {
        setError(error);
      },
      request
    );
  };

  const handleShowModalError = () => setShowModalError(true);
  const handleCloseModalError = () => {
    setShowModalError(false);
    disconnectWallet();
    clearToken();
    navigate("/login", { replace: true });
  };

  const shareToSocial = () => {
    setShowModalShare(true);
  };

  useEffect(() => {}, [query]);

  useEffect(() => {
    if (chainId && uid) {
      setShareURL(env.FRONT_URL + `profile/${uid ?? ""}`);
      getNFTlist();
    }
  }, []);

  useEffect(() => {
    setNftList([]);
    setMasterNftList([]);
    setNftsTotal(0);
    if (chainId && uid) {
      setShareURL(env.FRONT_URL + `profile/${uid ?? ""}`);
      getNFTlist();
    }
  }, [chainId, uid]);

  const getNFTlist = async () => {
    if (loading) {
      return;
    }
    if (chainId === undefined) {
      var allNft = [];

      for (let index in supportedChainlist) {
        let chainHexId1 = "0x" + supportedChainlist[index].toString(16);
        setLoading(true);
        const { data } = await getAllNftInAddress(uid, chainHexId1, "");
        setLoading(false);
        setHasLoadMore(nftList.length < data.total && data.cursor != null);
        setCursorPage(data.cursor);
        allNft = allNft.concat(data.result);
        setNftList((oldArray) => [...oldArray, ...allNft]);
        setMasterNftList((oldArray) => [...oldArray, ...allNft]);
        setNftsTotal(nftList.length);
      }
    } else {
      setLoading(true);
      const { data } = await getAllNftInAddress(
        uid,
        networkMap[chainId].chainId,
        cursorPage
      );
      console.log("list", data);
      setLoading(false);
      setHasLoadMore(nftList.length < data.total && data.cursor != null);
      setCursorPage(data.cursor);

      setNftList(data.result);
      setMasterNftList(data.result);
      setNftsTotal(data.result.length);
    }
  };

  const searchNftFromQuery = (query) => {
    let matchingStrings = [];

    masterNftList.forEach((list) => {
      if (
        list.name.toLocaleLowerCase().search(query.toLocaleLowerCase()) > -1
      ) {
        matchingStrings.push(list);
      }
    });

    setNftList([]);
    setNftsTotal(0);
    setNftList(matchingStrings);
    setNftsTotal(matchingStrings.length);
  };

  const handleSearchChange = (event) => {
    const queryString = event.target.value;
    setQeury(queryString);
    let handleLength = queryString.length;
    setIsSearchItem(handleLength > 0 ? true : false);

    if (handleLength === 0 && isSearchItem === true) {
      resetNFTlist();
    }
  };

  const resetNFTlist = () => {
    setNftList([]);
    setNftsTotal(0);
    setNftList(masterNftList);
    setNftsTotal(masterNftList.length);
  };

  const SubmitSearch = async (data) => {
    setIsSearchItem(true);

    searchNftFromQuery(data["search_product"]);
  };

  const meta = {
    title: fullname ? fullname : "UNNAMED" + "| THECONCERT.IO",
    description: bio,
    canonical: env.FRONT_URL + `profile/` + publicusername,
    meta: {
      charset: "utf-8",
      name: {
        keywords: "TCC, TheConcert",
      },
    },
  };

  useEffect(() => {
    console.log("lastCursorPage", cursorPage);
  }, [cursorPage]);

  const loadFunc = async () => {
    console.log("loadFunc");
    if (loading == false) {
      // getNFTlist();
    }
  };

  return (
    <React.Fragment>
      <DocumentMeta {...meta}>
        <Layout>
          <Col>
            <div
              className="header-section"
              style={{ backgroundImage: `url("${cover}")` }}
            >
              <Container className="header-section-content">
                <Row>
                  <Col xs={12} sm={3}>
                    <img
                      className="collection-avatar"
                      src={
                        _.isEmpty(avatar)
                          ? defaultAvatar
                          : env.RES_URL +
                            "/w_285,h_285,c_thumb/" +
                            avatar.id +
                            "/" +
                            avatar.name
                      }
                    />
                  </Col>
                  <Col sm={9}>
                    <div>
                      <h2 className="collection-title">
                        {fullname == null || fullname === undefined
                          ? "unnamed"
                          : fullname}{" "}
                      </h2>

                      <div className="collection-desc">
                        {bio == null ? "" : nl2br(bio)}
                      </div>
                      <div className="shares mt-3">
                        <i
                          className="_icon _icon-share"
                          onClick={shareToSocial}
                        ></i>
                        <TwitterShareButton
                          title={publicusername}
                          url={shareURL}
                          hashtags={[publicusername, "Theconcert"]}
                        >
                          <i className="_icon _icon-tweet"></i>
                        </TwitterShareButton>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="box-collection-info">
              <Container>
                <Tabs
                  defaultActiveKey={queryUrl === tabParam ? tabActive : "items"}
                  id="user-collection-nft"
                >
                  <Tab eventKey="items" title="COLLECTED">
                    <Container className="filter-section">
                      <Row>
                        <Col md={12} className="_search-collection">
                          <Form
                            className="_search-item"
                            onSubmit={handleSubmit(SubmitSearch)}
                          >
                            <i className="icon-search-item"></i>
                            <FormControl
                              type="search"
                              placeholder="SEARCH BY NAME"
                              className="input-search"
                              aria-label="SEARCH BY NAME"
                              name="search_product"
                              {...register("search_product", {
                                onChange: (e) => {
                                  handleSearchChange(e);
                                },
                              })}
                            />
                          </Form>
                        </Col>
                      </Row>
                    </Container>

                    <div className="section-control-item">
                      <Row className="justify-content-md-center">
                        <Col xs={6}>
                          <span className="sum-items">
                            {nftList.length} items
                          </span>
                        </Col>
                        <Col xs={6}></Col>
                      </Row>
                    </div>

                    <Container className="item-section mt-5 mb-5">
                      <InfiniteScroll
                        dataLength={nftList.length}
                        next={loadFunc}
                        hasMore={hasLoadMore}
                        loader={<h4>Loading...</h4>}
                      >
                        <Row>
                          <ProfileItemNft nftList={nftList} />
                        </Row>
                      </InfiniteScroll>
                    </Container>
                  </Tab>
                  <Tab eventKey="favourited" title="FAVOURITED">
                    <div className="mt-5 mb-5">
                      <Row>
                        {userId !== undefined && userId.length > 10 ? (
                          <FavoritesNft address={userId} />
                        ) : (
                          <div className="p-5 text-center"> No Item</div>
                        )}
                      </Row>
                    </div>
                  </Tab>

                  {/* <Tab eventKey="watchlist" title="WATCHLIST">
                    <div className="p-5 text-center"> No Item</div>
                  </Tab> */}

                  <Tab eventKey="activity" title="ACTIVITY">
                    {userId !== undefined && userId.length > 10 ? (
                      <UserActivity address={userId} />
                    ) : (
                      <div className="p-5 text-center"> No Item</div>
                    )}
                  </Tab>
                </Tabs>
              </Container>
            </div>
          </Col>
          <Modal
            show={showModalShare}
            onHide={closeModalShare}
            backdrop="static"
            keyboard={false}
            className="modal-alert"
          >
            <Modal.Header>
              <Modal.Title>Share This Profile</Modal.Title>
              <CloseButton variant="white" onClick={closeModalShare} />
            </Modal.Header>
            <Modal.Body>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Link</Form.Label>
                <Form.Control type="text" value={shareURL} disabled readonly />
              </Form.Group>
              <div>
                <h3>Share</h3>
                <FacebookShareButton
                  url={shareURL}
                  quote={publicusername}
                  hashtag={publicusername}
                  description={publicusername}
                >
                  <i className="_icon-share-footer _icon-share-footer-facebook"></i>
                </FacebookShareButton>
                <TwitterShareButton
                  title={publicusername}
                  url={shareURL}
                  hashtags={[publicusername, "Theconcert"]}
                >
                  <i className="_icon-share-footer _icon-share-footer-twitter"></i>
                </TwitterShareButton>
                <TelegramShareButton url={shareURL} title={publicusername}>
                  <i className="_icon-share-footer _icon-share-footer-telegram"></i>
                </TelegramShareButton>
              </div>
            </Modal.Body>
          </Modal>
          {copysuccess ? (
            <AlertBox title="COPY ADDRESS SUCCESS" variant="success"></AlertBox>
          ) : null}
        </Layout>
      </DocumentMeta>
    </React.Fragment>
  );
};

export default PublicProfile;
