import React from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Button,
  Form,
  CloseButton,
  Modal,
} from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import AlertBox from "../components/AlertBox";
import api from "../api";
import _ from "lodash";
import MyWallet from "../components/MyWallet";
import GeneralSetting from "../components/GeneralSetting";
import "../assets/sass/profile_page.scss";
import { WalletContext } from "../providers/WalletProvider";
import { useContext, useEffect, useState } from "react";
import KycBox from "../components/KycBox";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useAuthen } from "../hooks/useAuthen";
import iconError from "../assets/images/icon-error.svg";
import iconSuccess from "../assets/images/icon-success.svg";
import env from "../env";
import { useWeb3React } from "@web3-react/core";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
const delay = 3;

const SettingsPage = () => {
  const { chainId } = useWeb3React();
  let navigate = useNavigate();
  const [copysuccess, showCopySuccess] = useState(false);
  const [fullname, setFullname] = useState();
  const [bio, setBio] = useState();
  const [isVerfy, setVerify] = useState(false);
  const [avatar, setAvatar] = useState();
  const [profile, setProfile] = useState();
  const [error, setError] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [alert, setAlert] = useState(false);

  const errorClose = () => setError(false);
  const errorShow = () => setError(true);
  const alertClose = () => setAlert(false);
  const alertShow = () => setAlert(true);
  const [alertTitle, setAlertTitle] = useState();
  const [alertDesc, setAlertDesc] = useState();
  const [errorTitle, setErrorTitle] = useState();
  const [errorDesc, setErrorDesc] = useState();
  const [shareURL, setShareURL] = useState();

  const handleCopy = (e) => {
    showCopySuccess(!copysuccess);
    navigator.clipboard.writeText(address);
    let timer1 = setTimeout(() => showCopySuccess(false), delay * 1000);
    return () => {
      clearTimeout(timer1);
    };
  };

  useEffect(() => {
    let access_token = Cookies.get("access_token", { domain: window.location.hostname });
    if (
      access_token === "null" ||
      access_token === undefined ||
      access_token === null
    ) {
      navigate("/login", { replace: true });
    } else {
      getProfile(true);
    }
  }, []);

  const {
    walletActive,
    address,
    tokenBalanceList,
    disconnectWallet,
    currentConnector,
  } = useContext(WalletContext);
  const { checkAddressHasConnectedEmail, clearToken, walletConnectTheConcert } =
    useAuthen();
  const [textDropdown, settextDropdown] = useState("");
  const handleSelect = (e) => {
    settextDropdown(e);
  };

  const getProfile = (isInit) => {
    api.users.getProfile(
      (response) => {
        let data = response.data;

        setProfile(data.data);
        let firstName = data.data.first_name;
        let lastName = data.data.last_name;

        let bio = data.data.bio;
        let userAvatar = data.data.avatar;
        let userCover = data.data.cover.url;
        let fullname = firstName + " " + lastName;
        setFullname(firstName && lastName ? fullname : undefined);
        setBio(bio);
        setAvatar(userAvatar);
        setCover(userCover);
        setShareURL(env.FRONT_URL + `/profile/`);
      },
      (error) => {
        if (isInit) {
          setError(error);
        }
      },
      []
    );
  };

  useEffect(() => {
    if (currentConnector && address) {
      const request = walletConnectTheConcert(currentConnector, true);
      if (request) {
        walletConnect(request);
      }
    }
  }, [address, currentConnector]);

  const walletConnect = (request) => {
    api.wallets.walletConnect(
      (response) => {
        let access_token = _.get(response, "data.data.access_token");
        let refresh_token = _.get(response, "data.data.refresh_token");
        if (access_token && refresh_token) {
          sessionStorage.setItem("access_token", access_token);
          sessionStorage.setItem("refresh_token", refresh_token);
          Cookies.set("access_token", access_token, {
            domain: window.location.hostname,
          });
          Cookies.set("refresh_token", refresh_token, {
            domain: window.location.hostname,
          });
          navigate("/settings", { replace: true });
        }
      },
      (error) => {
        let statusCode = _.get(error, "data.error.code");
        let message = _.get(error, "data.error.message");

        if (statusCode === 400) {
          setErrorMessage(message);
          handleShowModalError();
        }
      },
      request
    );
  };

  const handleShowModalError = () => setShowModalError(true);
  const handleCloseModalError = () => {
    setShowModalError(false);
    disconnectWallet();
    clearToken();
    navigate("/login", { replace: true });
  };

  // Upload Avatar
  const [isSelectAvatar, setIsSelectAvatar] = useState(false);
  const [isSelectCover, setIsSelectCover] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [coverSelectedFile, setCoverSelectedFile] = useState();
  const [coverPreview, setCoverPreview] = useState();
  const [cover, setCover] = useState();
  const [preview, setPreview] = useState();
  const [showModalShare, setShowModalShare] = useState(false);
  const closeModalShare = () => setShowModalShare(false);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      // setAvatar(undefined);
      setIsSelectAvatar(false);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    setAvatar(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    if (!coverSelectedFile) {
      setCoverPreview(undefined);
      // setCover(undefined);
      setIsSelectCover(false);
      return;
    }

    const objectUrl = URL.createObjectURL(coverSelectedFile);
    setCoverPreview(objectUrl);
    setCover(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [coverSelectedFile]);

  const onSelectFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (!event.target.files || event.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setIsSelectAvatar(true);
    const file = event.target.files[0];
    const frontDoc = await convertBase64(file);

    var ext = file.name.split(".").pop();
    setSelectedFile(file);

    let randomName = (Math.random() + 1).toString(36).substring(7);
    let request = {
      file_name: randomName + "." + checkFileName(ext),
      file_data: frontDoc,
    };
    api.users.uploadAvatar(
      (response) => {
        setAlertTitle("Succeed");
        setAlertDesc("update avatar profile successfully");
        alertShow();
        setTimeout(() => {
          alertClose();
        }, 1000);

        // window.location.reload(false);
      },
      (error) => {
        setErrorTitle("Failed to upload avatar");
        setErrorDesc("");
        errorShow();
      },
      request
    );
  };

  function checkFileName(ext) {
    switch (ext) {
      case "JPG":
        return "jpg";
      case "PNG":
        return "png";
      default:
        return ext;
    }
  }

  const onSelectCoverFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (!event.target.files || event.target.files.length === 0) {
      setCoverSelectedFile(undefined);
      return;
    }
    setIsSelectCover(true);
    const file = event.target.files[0];
    const frontDoc = await convertBase64(file);

    var ext = file.name.split(".").pop();

    setCoverSelectedFile(file);

    let randomName = (Math.random() + 1).toString(36).substring(7);
    let request = {
      file_name: randomName + "." + checkFileName(ext),
      file_data: frontDoc,
    };
    api.users.uploadCover(
      (response) => {
        setAlertTitle("Succeed");
        setAlertDesc("update cover profile successfully");
        alertShow();
        setTimeout(() => {
          alertClose();
        }, 1000);

        // window.location.reload(false);
      },
      (error) => {
        setErrorTitle("Failed to upload cover");
        setErrorDesc("");
        errorShow();
      },
      request
    );
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result.split(",").pop());
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const Submit = async (data) => {

    if(!_.get(profile, 'phone')){
      if(_.get(data, 'phoneNumber') && !_.get(profile, 'phone')){
        setErrorTitle("Failed");
        setErrorDesc("Your mobile number has not been verified");
        errorShow();
        return;
      };
    }

    let request = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      username: data.username,
      bio: data.bio,
    };
    let firstName = data.first_name;
    let lastName = data.last_name;
    let fullname = data.first_name + " " + data.last_name;
    setFullname(firstName && lastName ? fullname : undefined);
    setBio(data.bio);

    api.users.updataProfile(
      (response) => {
        let message = _.get(response, "data.data.message");
        setAlertTitle("Succeed");
        setAlertDesc("Update profile successfully");
        alertShow();
        setTimeout(() => {
          alertClose();
        }, 1000);
      },
      (error) => {
        setErrorTitle("Update Failed");
        setErrorDesc(error.data.error.message);
        errorShow();
      },
      request
    );
  };

  const shareToTwitter = () => {
    var url = env.FRONT_URL + `profile/`;
    window.open(env.REACT_SHARE_TWITTER + encodeURIComponent(url));
  };

  const shareToFacebook = () => {
    var url = env.FRONT_URL + `profile/`;
    window.open(env.REACT_SHARE_FACEBOOK + encodeURIComponent(url));
  };

  const shareToTelegram = () => {
    var url = env.FRONT_URL + `profile/`;
    window.open(env.REACT_SHARE_TELEGRAM + encodeURIComponent(url));
  };

  const shareToSocial = () => {
    setShowModalShare(true);
  };

  return (
    <Layout>
      {walletActive == true || walletActive == undefined ? (
        <div></div>
      ) : (
        <AlertBox
          title="WARNING! YOU MUST LOGIN FIRST"
          variant="warning"
        ></AlertBox>
      )}
      {copysuccess ? (
        <AlertBox title="COPY ADDRESS SUCCESS" variant="success"></AlertBox>
      ) : null}
      showResults
      <div className="tab-profile">
        <Container>
          <div className="breadcrumb">
            <span className="first">
              {" "}
              <Link to={"/"}>HOME </Link>
            </span>{" "}
            <KeyboardArrowRightIcon /> GENNERAL SETTING
          </div>

          <Tab.Container id="left-tabs-profile1" defaultActiveKey="general">
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column left-tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="wallet">My Wallet</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="general">Edit Profile</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="kyc">KYC</Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="wallet">
                    <MyWallet
                      address={address}
                      clickCopy={handleCopy}
                      tokenBalanceList={tokenBalanceList}
                    />
                  </Tab.Pane>

                  <Tab.Pane eventKey="general">
                    <GeneralSetting
                      onSelectFile={onSelectFile}
                      avatar={avatar}
                      preview={preview}
                      isSelectAvatar={isSelectAvatar}
                      selectedFile={selectedFile}
                      onSelectCoverFile={onSelectCoverFile}
                      cover={cover}
                      coverPreview={coverPreview}
                      isSelectCover={isSelectCover}
                      coverSelectedFile={coverSelectedFile}
                      Submit={Submit}
                    />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="kyc">
                    <KycBox />
                  </Tab.Pane> */}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </div>
      <Modal
        show={showModalError}
        onHide={handleCloseModalError}
        backdrop="static"
        keyboard={false}
        className="modal-alert"
      >
        <Modal.Body>
          <div className="text-center">
            <img src={iconError} className="icon-error" />
            <h2>WARNING</h2>
            <p className="_font-medium-x _gray">{errorMessage}</p>
            <Button className="btn-nft mt-3" onClick={handleCloseModalError}>
              Login with new wallet
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={alert}
        onHide={alertClose}
        backdrop="static"
        keyboard={false}
        className="modal-alert"
      >
        <Modal.Body>
          <div className="text-center">
            <img src={iconSuccess} className="icon-success" />
            <h2>{alertTitle}</h2>
            <p className="_font-medium-x _gray">{alertDesc}</p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={error}
        onHide={errorClose}
        backdrop="static"
        keyboard={false}
        className="modal-alert"
      >
        <Modal.Body>
          <div className="text-center">
            <img src={iconError} className="icon-error" />
            <h2>{errorTitle}</h2>
            <p className="_font-medium-x _gray">{errorDesc}</p>
            <Button className="btn-nft mt-3" onClick={errorClose}>
              TRY AGAIN
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModalShare}
        onHide={closeModalShare}
        backdrop="static"
        keyboard={false}
        className="modal-alert"
      >
        <Modal.Header>
          <Modal.Title>Share This User</Modal.Title>

          <CloseButton variant="white" onClick={closeModalShare} />
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Link</Form.Label>
            <Form.Control
              type="text"
              value={shareURL}
              disabled
              readonly
            />
          </Form.Group>
          <div>
            <h3>Share</h3>
            <i
              className="_icon-share-footer _icon-share-footer-facebook"
              onClick={shareToFacebook}
            ></i>
            <i
              className="_icon-share-footer _icon-share-footer-twitter"
              onClick={shareToTwitter}
            ></i>
            <i
              className="_icon-share-footer _icon-share-footer-telegram"
              onClick={shareToTelegram}
            ></i>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default SettingsPage;
