import React from "react";
import { Navbar, Container ,Row, Col} from "react-bootstrap";
import imageNumberOne from "../assets/images/number-text-1.webp";
import logoTtcNft from "../assets/images/logo-ttc-nft.webp";
import infoTccNft from "../assets/images/info-tcc-nft.webp";
import imageCategory from "../assets/images/category.webp";


const Footer = () => {

  return (
    <React.Fragment>
      <>
      <div className="home-content"> 
        <Container>
          <div className="content-inner">
            <div className="text-center mt-5">
              <h2 className="text-center _font-large specail-color-text _white">CATEGORY</h2>
              <img className="info-category-nft mb-3" src={imageCategory}/>
              <img className="tcc-nft-logo" src={logoTtcNft}/>
              <h2>THECONCERT.IO</h2>
              <p className="sub-title"><span className="d-block">NFT Wallet & Platform </span>For Music, Concert And NightLife Community</p> 
            </div>
            <Row className="justify-content-md-center">
              <Col md="3">
                <div className="tcc-brand-slogan">
                  <img src={imageNumberOne}/>
                </div>
              </Col>
              <Col md="7">
                <div  className="sub-content">
                  <p>
                    The Concert Application, Thailand's number 1 guide to concerts and nightclubs, invites you to take a step to the future and witness the transformation of the entertainment industry before anyone else with THECONCERT.IO
                  </p>
                  <p>
                    The one-stop destination for utility-driven NFTs from nightclubs, music labels, and artists where entertainment industry and cryptocurrency converge. Buy, sell, and get the latest NFT airdrops in a few simple steps.
                  </p>
                  <p>
                    THECONCERT.IO NFT platform provides the opportunity for creators to create and distribute their productions independently with ease whether it's art, music, or even concert tickets.
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-md-center mt-3">
              <Col md="6">
                <div className="tcc-info-content">
                  <img src={infoTccNft}/>
                </div>
              </Col>
              <Col md="6">
                <div className="why-content">
                  <h2 className="text-center">WHY CHOOSE THECONCERT.IO?</h2>
                  <ul>
                    <li>
                      <span className="title-list">CORE TEAM OF BLOCKCHAIN EXPERTS</span>
                      Blockchain professionals with a deep understanding of various blockchain protocols and NFT standards are part of our team of experts.                    </li>
                    <li>
                      <span className="title-list">NFT SMART CONTRACT DEVELOPMENT AND AUDIT</span>
                      Providing NFT smart contract development and audit services to verify that the smart contract are error-free, ensuring seamless automation in NFT transactions.                    </li>
                    <li>
                      <span className="title-list">USER-FRIENDLY PLATFORM</span>
                      Easy to use NFT marketplace with funcions to make your NFT experience smooth from non-technical to tech savvy users. Navigate, buy and sell NFT assets eithin a few simple clicks.                    </li>
                    <li>
                      <span className="title-list">BIRDGING THE COMMUNITY</span>
                      Find unique NFTs you love and support your favorite artists directly without intermediaries bringing artists and their loyal fans closer than ever. 
                    </li>
                    <li>
                      <span className="title-list">EXCLUSIVE BENEFITS</span>
                      Always be the first in line and enjoy exclusive perks from utility-driven NFTs Curated for music and nightlife not found anywhere else.  
                    </li>
                    <li>
                      <span className="title-list">EARN PASSIVE INCOME</span>
                    Creators can set their collection fee and earn steady income from royalties every time their NFTs has been sold.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>

            <div className="mt-5 pb-5 how-to-content"> 
              <h2 className="text-center mb-5">HOW TO GET START</h2>
              <Row className="justify-content-md-center">
                <Col md="3">
                  <div className="card-box">
                    <i className="icon icon-wallet-c"></i>
                    <h3>connect wallet</h3>
                    Set up your wallet of choice, connect it to The Concert NFT Marketplace by clicking the wallet icon in the top right Corner.
                  </div>
                </Col>
                <Col md="3">
                  <div className="card-box">
                    <i className="icon icon-collection-c "></i>
                    <h3>connect collection</h3>
                    Click Create and Space between your collection. Add social links, a description, profile & banner images, and set a secondary sales fee.
                  </div>
                </Col>
                <Col md="3">
                  <div className="card-box">
                    <i className="icon icon-image-c "></i>
                    <h3>Add nft</h3>
                    Upload your work (image, audio, video, 3D art or concert tickets), add a title, description, and customize your NFTs with properties, copyrights, and special privileges. 
                  </div>
                </Col>
                <Col md="3">
                  <div className="card-box">
                    <i className="icon icon-bag-c "></i>
                    <h3>List them for sale</h3>
                    Easily sell the NFTs you created, traded or earned from airdrops paving a new way to earn in the digital age.                  
                  </div>
                </Col>                                
              </Row>
              <h2 className="text-center mt-5 mb-2 _text-quot">EMPOWER THE FUTURE OF VIRTUAL ASSET WITH THECONCERT.IO</h2>
              <div className="box-partner">
                <h3>PARTNERSHIP</h3>
                <p>We are happy to develop strategic partnerships integrating our NFT platform or other Blockchain technologies if you see a potential for us to work with you. Please use the form below to get in touch with us.</p>
                <button type="button" className="btn-nft">CONTACT US</button>
              </div>
            </div>
          </div>
        </Container>
      </div>
      </>
    </React.Fragment>
  );
};

export default Footer;
