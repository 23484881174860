import React from "react";
import {Alert} from "react-bootstrap";

const AlertBox = (props) => {
  return (
    <>
      <Alert key={props.variant} variant={props.variant} className="box-alert-title">
          <div className="alert-title">{props.title}</div>
      </Alert>
    </>
  );
};

export default AlertBox;
