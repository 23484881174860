import React, { useEffect, useState, useRef } from "react";
import "../assets/sass/table_events.scss";
import { Col, Row, Table } from "react-bootstrap";
import _ from "lodash";
import moment from "moment";
import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import { useMoralisWeb3Api } from "react-moralis";
import { useWeb3React } from "@web3-react/core";
import env from "../env";

const CollectionActivity = ({ address }) => {
  const { chainId } = useWeb3React();
  const Web3Api = useMoralisWeb3Api();

  const [record, setRecord] = useState([]);
  const [error, setError] = useState(null);

  useOnceCall(() => {
    console.log("load activity");
    let timer = setTimeout(() => {
        fetchNFTTransfers();
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  });

  function useOnceCall(cb, condition = true) {
    const isCalledRef = useRef(false);

    useEffect(() => {
      if (condition && !isCalledRef.current) {
        isCalledRef.current = true;
        cb();
      }
    }, [cb, condition]);
  }

  const fetchNFTTransfers = async () => {
    if (address === undefined) {
      return;
    }

    const options = {
        chain: env.REACT_APP_CHAIN,
        address: address,
        limit: "20",
    };
  
    const transfersNFT = await Web3Api.token.getContractNFTTransfers(options);
    console.log(transfersNFT)
    setRecord(transfersNFT.result ?? []);


  };

  function handleEvnentString(status) {
    if (status == "0x0000000000000000000000000000000000000000") {
      return (
        <div className="d-flex event-icon">
          <ChildFriendlyIcon /> <span>Minted</span>
        </div>
      );
    } else {
      return (
        <div className="d-flex event-icon">
          <MultipleStopIcon /> <span>Transfer</span>
        </div>
      );
    }
  }

  function handleAddress(address) {
    if (address === "0x0000000000000000000000000000000000000000")
      return "nullAddress";
    if (address === null) return "nullAddress";
    return address;
  }

  function timeAgo(time) {
    return moment(time).fromNow();
  }

  const RowItem = () => {
    return record.map((item, index) => {
      return (
        <tr key={`item-row-${index}`}>
          <td scope="row">{handleEvnentString(_.get(item, "from_address"))}</td>
          <td>
            <div className="d-flex">
              {/* <img src={avatar} alt="Avatar" className="activity_avatar"></img>  */}
              <p>{handleAddress(_.get(item, "from_address"))}</p>
            </div>
          </td>
          <td>
            <div className="d-flex">
              {/* <img src={avatar} alt="Avatar" className="activity_avatar"></img>  */}
              <p>{handleAddress(_.get(item, "to_address"))}</p>
            </div>
          </td>
          <td>
            <p>{timeAgo(_.get(item, "block_timestamp"))}</p>
          </td>
        </tr>
      );
    });
  };

  return (
    <div>
      <Table striped hover responsive variant="dark" className="event-table">
        <thead>
          <tr>
            <th scope="col">Events</th>
            <th scope="col">From</th>
            <th scope="col">To</th>
            <th scope="col">Time</th>
          </tr>
        </thead>
        <tbody>
          <RowItem />
        </tbody>
      </Table>
    </div>
  );
};

export default CollectionActivity;
