let env = "alpha";

const domain = window.location.hostname;
const env_domain = {
  localhost: "local",
  "alpha-www.theconcert.io": "alpha",
  "www.theconcert.io": "production",
};
if (env_domain[domain]) {
  env = env_domain[domain];
} else {
  console.log("error not domain map");
}

const env_config = {
  local: {
    APP: "LOCAL",
    API_URL: "https://alpha-api.theconcert.io/",
    FRONT_URL: "https://alpha-www.theconcert.io/",
    RES_URL: "https://alpha-res.theconcert.com",
    DELAY: 800,
    REACT_SHARE_TWITTER: "http://twitter.com/share?url=",
    REACT_SHARE_FACEBOOK: "https://www.facebook.com/sharer.php?u=",
    REACT_SHARE_TELEGRAM: "https://t.me/share/url?url=",
    REACT_APP_SENTRY_DNS:
      "https://5ba824017cfc4276a564fa2a64abed5b@sentry.io/1447769",
    REACT_APP_SENTRY_ENVIRONMENT: "local-nft",
    REACT_APP_CHAIN_ID_DEFAULT: 137,
    REACT_APP_CHAIN: "mumbai",
    REACT_APP_RPC_URL:
      "https://rpc-mumbai.maticvigil.com/v1/52d06030286f622d4cbb79f8385103ed301d2fbc",
    REACT_APP_INFURA_KEY: "ea08ce518f3a47fd87f50104f15199f7",
    REACT_APP_MORALIS_API_KEY:
      "z9hZukDH9ZNAkVvEmePkKfbDE1wULp2iywR2rkRc",
    REACT_APP_MORALIS_ENDPOINT_URL: "https://deep-index.moralis.io",
    REACT_APP_MORALIS_SERVER_URL:
      "https://ebvbfvrd3uvj.grandmoralis.com:2053/server",
    REACT_APP_MORALIS_APP_ID: "gZZPincAFM7hVACiGmP7ldh4vT7P6Z9XKbruWdnj",
    REACT_APP_GA_TRACKING_NO: "UA-133219660-7",
    GOOGLE_CAPTCHA_KEY: "6LfhRMEhAAAAAHOUjQ3u5gNchgjZ3biYv60lUeUf",
    GOOGLE_CAPTCHA_SECRET_KEY: "6LfhRMEhAAAAAPB5uwIF4s3SBw0bm4k7TJHPLg0Z",
    METAMASK_DEEPLINK: "https://metamask.app.link/dapp/alpha-www.theconcert.io",
  },
  alpha: {
    APP: "ALPHA",
    API_URL: "https://alpha-api.theconcert.io/",
    FRONT_URL: "https://alpha-www.theconcert.io/",
    RES_URL: "https://alpha-res.theconcert.com",
    DELAY: 800,
    REACT_SHARE_TWITTER: "http://twitter.com/share?url=",
    REACT_SHARE_FACEBOOK: "https://www.facebook.com/sharer.php?u=",
    REACT_SHARE_TELEGRAM: "https://t.me/share/url?url=",
    REACT_APP_SENTRY_DNS:
      "https://5ba824017cfc4276a564fa2a64abed5b@sentry.io/1447769",
    REACT_APP_SENTRY_ENVIRONMENT: "alpha-nft",
    REACT_APP_CHAIN_ID_DEFAULT: 137,
    REACT_APP_CHAIN: "mumbai",
    REACT_APP_RPC_URL:
      "https://rpc-mumbai.maticvigil.com/v1/52d06030286f622d4cbb79f8385103ed301d2fbc",
    REACT_APP_INFURA_KEY: "ea08ce518f3a47fd87f50104f15199f7",
    REACT_APP_MORALIS_API_KEY:
      "z9hZukDH9ZNAkVvEmePkKfbDE1wULp2iywR2rkRc",
    REACT_APP_MORALIS_ENDPOINT_URL: "https://deep-index.moralis.io",
    REACT_APP_MORALIS_SERVER_URL:
      "https://ebvbfvrd3uvj.grandmoralis.com:2053/server",
    REACT_APP_MORALIS_APP_ID: "gZZPincAFM7hVACiGmP7ldh4vT7P6Z9XKbruWdnj",
    REACT_APP_GA_TRACKING_NO: "UA-133219660-7",
    GOOGLE_CAPTCHA_KEY: "6LfHycEhAAAAAM-TqWao3KWE7rZR72sFUwbwWvTx",
    GOOGLE_CAPTCHA_SECRET_KEY: "6LfHycEhAAAAAGu_AUycl96dWEMHjV6YAlOKJdQ9",
    METAMASK_DEEPLINK: "https://metamask.app.link/dapp/alpha-www.theconcert.io",
  },
  production: {
    APP: "PRODUCTION",
    API_URL: "https://api.theconcert.io/",
    FRONT_URL: "https://www.theconcert.io/",
    RES_URL: "https://res.theconcert.io",
    DELAY: 800,
    REACT_SHARE_TWITTER: "http://twitter.com/share?url=",
    REACT_SHARE_FACEBOOK: "https://www.facebook.com/sharer.php?u=",
    REACT_SHARE_TELEGRAM: "https://t.me/share/url?url=",
    REACT_APP_SENTRY_DNS:
      "https://5ba824017cfc4276a564fa2a64abed5b@sentry.io/1447769",
    REACT_APP_SENTRY_ENVIRONMENT: "production-nft",
    REACT_APP_CHAIN_ID_DEFAULT: 137,
    REACT_APP_CHAIN: "polygon",
    REACT_APP_RPC_URL:
      "https://rpc-mumbai.maticvigil.com/v1/1a709dc6bacfad1c26d41f5421b810760c0befa0",
    REACT_APP_INFURA_KEY: "ea08ce518f3a47fd87f50104f15199f7",
    REACT_APP_MORALIS_API_KEY:
      "1PNTSOmzlrIJhEKEKde5X5vkdGOb3ws3abjZIy5p",
    REACT_APP_MORALIS_ENDPOINT_URL: "https://deep-index.moralis.io",
    REACT_APP_MORALIS_SERVER_URL:
      "https://3jbkv0htg4lx.grandmoralis.com:2053/server",
    REACT_APP_MORALIS_APP_ID: "MonxvYwTIS2MEZs7mstR5EyK427QC0znZKy64cvN",
    REACT_APP_GA_TRACKING_NO: "UA-133219660-8",
    GOOGLE_CAPTCHA_KEY: "6LfUycEhAAAAAPCdJlbFQKS8xKcmw0q8eGQ1VrNg",
    GOOGLE_CAPTCHA_SECRET_KEY: "6LfUycEhAAAAAJ8ofwOyMAunzWvTu5Ph_i1RhpFv",
    METAMASK_DEEPLINK: "https://metamask.app.link/dapp/www.theconcert.io",
  },
}[env];

export default env_config;
