import React, { useEffect, useRef } from "react";
import {
  Navbar,
  Container,
  Form,
  FormControl,
  Nav,
  NavDropdown,
  Button,
  Modal,
  Row,
  Col,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { useState, useContext } from "react";
import "../assets/sass/header.scss";
import AlertBox from "../components/AlertBox";
import iconETH from "../assets/images/icon-eth.svg";
import iconProfile from "../assets/images/icon-profile.svg";
import avatarDefault from "../assets/images/icon-user.svg";
import { WalletContext } from "../providers/WalletProvider";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CheckIcon from "@mui/icons-material/Check";
import LogoutIcon from "@mui/icons-material/Logout";
import StarIcon from "@mui/icons-material/Star";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { networkMap, supportedChainlist } from "../utils/web3Connect";
import { useWeb3React } from "@web3-react/core";
import { useAuthen } from "../hooks/useAuthen";
import AppsIcon from "@mui/icons-material/Apps";
import useToken from "../providers/useToken";
import Cookies from "js-cookie";
import _ from "lodash";
import CollectionsBookmarkRoundedIcon from "@mui/icons-material/CollectionsBookmarkRounded";
import MyWallet from "../components/MyWallet";
import CloseIcon from '@mui/icons-material/Close';
import iconWatting from "../assets/images/icon-waiting.svg";

const HeaderMain = (props) => {
  const { authed, clearToken } = useAuthen();
  const { getToken } = useToken();
  const navigate = useNavigate();
  const { chainId, active, error } = useWeb3React();
  const {
    address,
    tokenBalanceList,
    disconnectWallet,
    walletActive,
    switchNetwork,
    currentChainIdHasSupport,
    setupNetwork,
  } = useContext(WalletContext);

  const dataAddress = "Address : " + address ?? "";
  const [totalBalance, setTotalBalance] = useState();
  const [show, setShow] = useState(false);
  const [showModalSupportNetwork, setShowModalSupportNetwork] = useState(false);
  const [query, setQeury] = useState("");
  const [avatar, setAvatar] = useState();
  const [showModal, setShowModal] = useState(false);
  const [copysuccess, showCopySuccess] = useState(false);
  const [showProcess, setProcess] = useState(false);
  const [verified, setVerified] = useState();

  const delay = 3;

  let location = useLocation();
  const activeNav = (str) => {
    return location.pathname.search(str);
  };

  useEffect(() => {
    let user_avatar = sessionStorage.getItem("user_avatar");
    let user_verify = sessionStorage.getItem("is_user_verify");
    
    if (_.isEmpty(user_avatar)) {
      setAvatar(avatarDefault);
    } else {
      setAvatar(user_avatar);
    }
    if (!_.isEmpty(user_verify)) {
      setVerified(user_verify)
    }
    console.log(verified)
  });

  const handleCloseModalSupportNetwork = () =>
    setShowModalSupportNetwork(false);
  const handleShowModalSupportNetwork = () => setShowModalSupportNetwork(true);

  const handleCloseModal = () => setShowModal(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (walletActive) {
      setShow(true);
    } else {
      navigate("/login", { replace: true });
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const onCopy = () => {
    handleClose();
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setQeury(query);
  };

  const onSearchSubmit = (e) => {
    if (query) {
      if (location.pathname !== "/search") {
        navigate("/search?q=" + query);
      } else {
        navigate("/search/filter?q=" + query);
      }
    }
  };

  const connectWalletHandler = () => {
    if (window.ethereum) {
      return true;
    } else {
      return false;
    }
  };

  const handleCloseProcess = () => setProcess(false);
  const handleToMyNFT = () => {
    navigate("/profile?tab=nft");
  };

  useEffect(() => {
    let result = tokenBalanceList.reduce(function (acc, obj) {
      return acc + parseFloat(obj.usd);
    }, 0);
    let resultFormatNumber = result.toLocaleString("en-US");
    setTotalBalance(resultFormatNumber);
  }, [tokenBalanceList]);

  useEffect(() => {
    if (currentChainIdHasSupport === false) {
      handleShowModalSupportNetwork();
    }
  }, [currentChainIdHasSupport]);

  if (
    Cookies.get("access_token", { domain: window.location.hostname }) &&
    connectWalletHandler()
  ) {
    window.ethereum.on("accountsChanged", (accounts) => {
      console.log("Disconnect");
      if (!accounts.length) {
        logout();
      }
    });
  }

  function getCookie() {
    let access_token = Cookies.get("access_token", { domain: window.location.hostname });
    if (
      access_token === "null" ||
      access_token === undefined ||
      access_token === null
    ) {
      return false;
    }
    return true;
  }

  const logout = () => {
    disconnectWallet();
    clearToken();
    sessionStorage.removeItem("user_avatar");
    sessionStorage.removeItem("is_user_verify");
    navigate("/login", { replace: true });
  };

  const handleSwitchNetwork = async (changeChangId) => {
    console.log("handleSwitchNetwork", changeChangId);
    if (chainId === changeChangId) {
      navigate("/profile", { replace: true });
    } else {
      const hasSetup = await setupNetwork(changeChangId);
      if (hasSetup) {
        switchNetwork(changeChangId);
      } else {
        handleShowModalSupportNetwork();
      }
    }
  };

  const closeAndSwitchNetwork = async (changeChangId) => {
    const hasSetup = await setupNetwork(changeChangId);
    if (hasSetup) {
      handleCloseModalSupportNetwork();
      handleSwitchNetwork(changeChangId);
    } else {
      window.open("https://chainlist.org/chain/" + changeChangId);
    }
  };

  const closeAndDisconnectWallet = () => {
    handleCloseModalSupportNetwork();
    disconnectWallet();
    navigate("/");
  };

  const handleCopy = (e) => {
    showCopySuccess(!copysuccess);
    navigator.clipboard.writeText(address);
    let timer1 = setTimeout(() => showCopySuccess(false), delay * 1000);
    return () => {
      clearTimeout(timer1);
    };
  };

  useEffect(() => {
    if (activeNav("profile") !== -1 || activeNav("settings") !== -1) {
      window.$(".nav-top-profile").addClass("active");
    }
  }, []);

  return (
    <React.Fragment>
      <>
        <Navbar expand="lg" className="navbar" fixed="top">
          <Container>
            <Navbar.Brand href="/">
              <i className="_icon _icon-tcc-io"></i>{" "}
              <span className="nft-brand">THECONCERT.IO</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse
              id="navbarScroll"
              className="nav-overlay-box justify-content-end"
            >
              <Form className="d-flex _search-d" onSubmit={onSearchSubmit}>
                <i className="_icon _icon-search"></i>
                <FormControl
                  id="main_search"
                  type="search"
                  placeholder=""
                  className="me-2"
                  aria-label="Search"
                  onChange={handleSearchChange}
                />
              </Form>
              <Nav navbarScroll>
                {activeNav("collection") !== -1 ||
                activeNav("discover") !== -1 ||
                activeNav("campaign") !== -1 ||
                activeNav("product") !== -1 ? (
                <Link to="/discover" className="_discover active">
                  <span className="discover active">
                      <AppsIcon /> DISCOVER
                  </span>
                </Link>
                ) : (
                <Link to="/discover">
                  <span className="discover">
                      <AppsIcon /> DISCOVER
                  </span>
                </Link>

                )}
                <Navbar.Collapse id="navbar-dark" expand="false">
                  <Nav className="nav-profile">
                    <NavDropdown
                      className="active"
                      id="nav-dropdown-dark"
                      title={
                        <span className="nav-top-profile">
                          {getToken() && (verified === undefined || verified === 'undefined' || verified === '') ?(<span className="noti">1</span>):null}
                          <i
                            className="_icon _icon-user"
                            style={{ backgroundImage: `url("${avatar}")` }}
                          ></i>
                          <span className="user-profile">
                            PROFILE & SETTINGS
                          </span>
                        </span>
                      }
                      menuVariant="light"
                    >
                      <NavDropdown.Item href="/profile">
                        <PersonIcon />
                        Profile 
                        {getToken() && (verified === undefined || verified === 'undefined' || verified === '') ?(<span className="noti">1</span>):null}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/profile">
                        <CollectionsBookmarkRoundedIcon />
                        Collected
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/profile?tab=favourited">
                        <FavoriteIcon />
                        Favourited
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/profile?tab=watchlist">
                        <StarIcon />
                        Watchlist
                      </NavDropdown.Item>

                      <NavDropdown.Item href="/settings">
                        <SettingsIcon />
                        Settings
                      </NavDropdown.Item>
                      {getToken() ? <NavDropdown.Divider /> : ""}
                      {getToken() ? (
                        <NavDropdown.Item href="#" onClick={logout}>
                          <LogoutIcon />
                          Logout
                        </NavDropdown.Item>
                      ) : (
                        ""
                      )}
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>

                <Navbar.Collapse id="navbar-dark" expand="false">
                  <Nav className="nav-profile">
                    {getCookie() && address ? (
                      <NavDropdown
                        href="#"
                        onClick={handleShowModal}
                        id="nav-dropdown-dark"
                        title={
                          <span>
                            {address ? (
                              <i className="_icon _icon-wallet-active"></i>
                            ) : (
                              <i className="_icon _icon-wallet"></i>
                            )}
                            <span className="user-wallet">CONNECTED</span>
                          </span>
                        }
                        menuVariant="light"
                        className="_network-available"
                      ></NavDropdown>
                    ) : (
                      <a href="/login">
                        <span>
                          <i className="_icon _icon-wallet"></i>
                          <span className="user-wallet">CONNECT WALLET</span>
                        </span>
                      </a>
                    )}
                  </Nav>
                </Navbar.Collapse>

                {/* <div onClick={handleShow}>
                  <i className="_icon _icon-wallet"></i>
                  <span className="user-wallet">WALLET</span>
                </div> */}
              </Nav>
            </Navbar.Collapse>
            {showProcess?
            <div className="process-modal d-flex cursor-pointer">
              <div onClick={handleToMyNFT}><img src={iconWatting} className="icon-waiting rotate" />
              <span className="process-text">You have <span className="_red"> 2 </span> pending transactions. Please wait, you may experience a delay due to high traffic.</span> </div>
              <div onClick={handleCloseProcess} className="cursor-pointer"><CloseIcon fontSize="small"/></div>
            </div>: null }
          </Container>
        </Navbar>

        {copysuccess ? (
          <AlertBox title="COPY ADDRESS SUCCESS" variant="success"></AlertBox>
        ) : null}

        <Modal show={show} onHide={handleClose} className="modal-private-code">
          <Modal.Header>
            <div>
              <img src={iconProfile} alt="Profile"></img>
            </div>
            <Modal.Title>MY WALLET</Modal.Title>
            <i className="_icon _icon-close" onClick={handleClose}></i>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className="address">
                <div className="address-text">{dataAddress}</div>
                <CopyToClipboard onCopy={() => onCopy()} text={address}>
                  <ContentCopyIcon className="_font-medium _copy" />
                </CopyToClipboard>
              </div>
            </Container>
            <Container>
              <div className="currency-box">
                <Form>
                  <Form.Group
                    className="mb-5 mt-3"
                    controlId="Form.ControlInput1"
                  >
                    <Form.Label>Total balance</Form.Label>
                    <div className="currency-total">
                      $ {totalBalance ?? ""} USD
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </Container>
            <Container>
              {tokenBalanceList?.map((token, index) => (
                <div className="currency-box" key={index}>
                  <Row>
                    <Col xs="2" className="d-flex">
                      <img className="token" alt="Token" src={iconETH}></img>
                    </Col>
                    <Col xs="5" className="box-currency-name">
                      <div className="ETH">{token.symbol}</div>
                      <div className="Ethereum">{token.name}</div>
                    </Col>
                    <Col md="5">
                      <div className="currency">{token.balance}</div>
                      <div className="currency-usd ">
                        $ {token.usd ?? ""} USD
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </Container>
          </Modal.Body>
        </Modal>

        <Modal
          show={showModalSupportNetwork}
          onHide={handleCloseModalSupportNetwork}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>
              <span className="text-modal-title">Network Not Supported</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span className="text-modal show-grid">
              Presently, this store does not support this network and supports
              only the below-mentioned network(s). Please select one of the
              below-mentioned networks by clicking on its button below.
            </span>
            <p />
            <Container>
              <Row>
                {supportedChainlist.map((id, index) => {
                  return (
                    <Col md={12} key={id}>
                      <Button
                        onClick={() => closeAndSwitchNetwork(id)}
                        variant="light"
                        className="btn-optional w-100 mb-2"
                      >
                        <span>{networkMap[id].chainName}</span>
                      </Button>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={closeAndDisconnectWallet}
              variant="dark"
              className="w-100 _font-medium-x"
            >
              <span>Logout and Disconnect wallet</span>
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal wallet */}
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          className="modal-private-code _wallet"
        >
          <Modal.Header>
            <i
              className="_icon _icon-close cursor-pointer"
              onClick={handleCloseModal}
            ></i>
          </Modal.Header>
          <Modal.Body>
            <div className="list-wallet-menu">
              <div className="d-flex justify-content-between">
                <span className="header">
                  <i className="_icon _icon-wallet"></i>My Wallet
                </span>
              </div>

              {/* <div className="d-flex justify-content-between">
                <span><i className="_icon _icon-metamask"></i>MetaMask <i className="_icon _icon-small-check"></i></span>
                <span><RefreshIcon /></span>
              </div> */}

              <div className="d-flex justify-content-between">
                <span>
                  <img
                    className="token circle mr-3"
                    alt="Token"
                    src={_.get(networkMap[chainId], "logo")}
                  ></img>
                  {_.get(networkMap[chainId], "chainName")}
                </span>
                <DropdownButton
                  id="dropdown-button-dark-example2"
                  variant="secondary"
                  title=""
                >
                  {supportedChainlist?.map((id, index) => {
                    return (
                      <div key={index}>
                        <Dropdown.Item onClick={() => handleSwitchNetwork(id)}>
                          <img
                            className="token circle"
                            alt="Token"
                            src={networkMap[id]?.logo}
                          ></img>
                          <span>
                            {" "}
                            {networkMap[id].chainName?.replace(
                              " Mainnet",
                              ""
                            )}{" "}
                          </span>

                          {chainId === id ? <CheckIcon /> : <></>}
                        </Dropdown.Item>
                      </div>
                    );
                  })}
                </DropdownButton>
              </div>
            </div>
            <div className="box-address">
              <MyWallet
                address={address}
                clickCopy={handleCopy}
                tokenBalanceList={tokenBalanceList}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={logout} variant="secondary" className="btn-logout">
              LOG OUT
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </React.Fragment>
  );
};

export default HeaderMain;
