import React, { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import "../assets/sass/login_wallet.scss";
import { Container, Form, Button, Modal } from "react-bootstrap";

import "../assets/sass/modal.scss";
import iconSuccess from "../assets/images/icon-success.svg";
import iconError from "../assets/images/icon-error.svg";
import WalletProviderList from "../components/WalletProviderList";
import Cookies from "js-cookie";
import { useAuthen } from "../hooks/useAuthen";
import { useForm } from "react-hook-form";
import api from "../api";
import _ from "lodash";
import { WalletContext } from "../providers/WalletProvider";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let query = useQuery();
  let hasReturn = query.get("return");
  const navigate = useQuery();

  const { walletActive, address } = useContext(WalletContext);
  const { authed, clearToken } = useAuthen();

  const [loginEmail, setLogin] = useState(false);
  const loginEmailInactive = () => setLogin(false);
  const loginEmailActive = () => setLogin(true);

  const [forgotPwd, setPwd] = useState(false);
  const forgotPwdActive = () => setPwd(true);
  const forgotPwdInactive = () => setPwd(false);

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const errorClose = () => setError(false);
  const errorShow = () => setError(true);
  const alertClose = () => setAlert(false);
  const alertShow = () => setAlert(true);
  const [alertTitle, setAlertTitle] = useState();
  const [alertDesc, setAlertDesc] = useState();
  const [errorTitle, setErrorTitle] = useState();
  const [errorDesc, setErrorDesc] = useState();

  const [inputEmail, setInputEmail] = useState();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  if (urlParams.get("action") == "login" && loginEmail == false) {
    setLogin(true);
  }

  let email = "";
  let password = "";
  const setInputValueEmail = (event) => {
    email = event.target.value;
    // setInputEmail(email)
  };
  const setInputValuePassword = (event) => {
    password = event.target.value;
  };

  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    let request = {
      email: email,
      password: password,
    };
    api.users.authen(
      (response) => {
        let access_token = _.get(response, "data.data.access_token");
        let refresh_token = _.get(response, "data.data.refresh_token");
        if (access_token && refresh_token) {
          sessionStorage.setItem("access_token", access_token);
          sessionStorage.setItem("refresh_token", refresh_token);
          Cookies.set("access_token", access_token, { domain: window.location.hostname });
          Cookies.set("refresh_token", refresh_token, {
            domain: window.location.hostname,
          });
          setAlertTitle("Succeed");
          setAlertDesc("Logging in");
          alertShow();
          setTimeout(() => {
            if (hasReturn === "true") {
              navigate(-1);
            } else {
              window.location.href = "/profile";
            }
          }, 1000);
          // alert('เข้าสู่ระบบสำเร็จ')
        }
      },
      (error) => {
        setErrorTitle("Failed");
        setErrorDesc("Invalid username or password");
        errorShow();
        //setInputEmail(email)
        console.log(error);
      },
      request
    );
  };

  const SubmitForgetPassword = async (data) => {
    // e.preventDefault();
    let request = {
      email: data.email,
    };
    api.users.userForgotPassword(
      (response) => {
        setAlertTitle("Succeed");
        setAlertDesc("Email send successful, please check your email");
        alertShow();
        setTimeout(() => {
          window.location.href = "/login";
        }, 1000);
        // alert('เข้าสู่ระบบสำเร็จ')
      },
      (error) => {
        setErrorTitle("Failed");
        setErrorDesc("Invalid Email");
        errorShow();
        //setInputEmail(email)
        console.log(error);
      },
      request
    );
  };

  return (
    <React.Fragment>
      <Layout>
        <div className="login-section">
          <Container>
            {loginEmail ? (
              // <EmailLogin />
              <div>
                {forgotPwd ? (
                  <div id="emailLogin" className="email-login">
                    <div className="box-login-select">
                      <div className="box-policy text-center">
                        <h2 className="_font-large-x">FORGOT PASSWORD</h2>
                        <span className="policy-title">
                          go back to{" "}
                          <a
                            href="javascript:void(0)"
                            onClick={forgotPwdInactive}
                          >
                            login
                          </a>
                        </span>
                      </div>
                      <div className="card-center">
                        <Form
                          className="login-email-form"
                          onSubmit={handleSubmit(SubmitForgetPassword)}
                        >
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Email address</Form.Label>

                            <Form.Control
                              type="text"
                              placeholder="Enter email"
                              {...register("email", {
                                required: true,
                                minLength: 3,
                                maxLength: 200,
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: "invalid email address",
                                },
                              })}
                            />
                            <div className="text-danger">
                              {errors.email?.message}
                              {errors.email?.type === "required" &&
                                "Email is required"}
                              {errors.email?.type === "minLength" &&
                                "Enter a email that is at least 3 letters "}
                              {errors.email?.type === "maxLength" &&
                                "Value must be less than 50 characters"}
                            </div>
                          </Form.Group>
                          <div className="text-center pt-3">
                            <Button className="btn-nft" type="submit">
                              SEND RESET LINK
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div id="emailLogin" className="email-login">
                    <div className="box-login-select">
                      <div className="box-policy text-center">
                        <h2 className="_font-large-x">SIGN IN</h2>
                        <span className="policy-title">
                          don’t have an account? <a href="/signup">sign up</a>
                        </span>
                      </div>
                      <div className="card-center">
                        <Form
                          className="login-email-form"
                          onSubmit={handleSubmitLogin}
                        >
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Email address</Form.Label>
                            <input
                              type="email"
                              name="email"
                              value={inputEmail}
                              placeholder="Enter email"
                              className="form-control"
                              onChange={setInputValueEmail}
                            />
                            {/* <Form.Text className="text-muted _red">
                    We'll never share your email with anyone else.
                  </Form.Text> */}
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              type="password"
                              name="password"
                              placeholder="Password"
                              onChange={setInputValuePassword}
                            />
                          </Form.Group>
                          <p>
                            <a
                              href="javascript:void(0)"
                              onClick={forgotPwdActive}
                            >
                              FORGOT PASSWORD
                            </a>
                          </p>

                          <div className="text-center pt-3">
                            <Button className="btn-nft" type="submit">
                              SIGN IN
                            </Button>
                            <div className="box-policy mt-3 _font-medium">
                              <a
                                href="javascript:void(0)"
                                onClick={loginEmailInactive}
                              >
                                Click here
                              </a>{" "}
                              to login via metamask or any other wallet
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                    <Modal
                      show={alert}
                      onHide={alertClose}
                      backdrop="static"
                      keyboard={false}
                      className="modal-alert"
                    >
                      <Modal.Body>
                        <div className="text-center">
                          <img src={iconSuccess} className="icon-success" />
                          <h2>{alertTitle}</h2>
                          <p className="_font-medium-x _gray">{alertDesc}</p>
                        </div>
                      </Modal.Body>
                    </Modal>
                    <Modal
                      show={error}
                      onHide={errorClose}
                      backdrop="static"
                      keyboard={false}
                      className="modal-alert"
                    >
                      <Modal.Body>
                        <div className="text-center">
                          <img src={iconError} className="icon-error" />
                          <h2>{errorTitle}</h2>
                          <p className="_font-medium-x _gray">{errorDesc}</p>
                          <Button className="btn-nft mt-3" onClick={errorClose}>
                            TRY AGAIN
                          </Button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                )}
              </div>
            ) : (
              <div className="box-login-select">
                <div className="box-policy">
                  <h2 className="mb-2">Connect your wallet</h2>
                  {/* <label className="container-checkbox">
                        <input type="checkbox" defaultChecked={true}/>
                        <span className="checkmark"></span>
                    </label> */}

                  <span className="policy-title">
                    <span>
                      Connect with one of our available{" "}
                      <span className="_green">wallet</span> Providers Connect
                      or create a new one.
                      {/* by signing into this platform, you agree to the following
                        <a href="/terms">
                          <span className="text-style-1">
                            {" "}
                            terms and conditions
                          </span>
                        </a>
                        <span className="text-style-2">
                          {" "}
                          and{" "}
                          <a href="/privacy">
                            <span className="text-style-1">privacy policy</span>
                          </a>
                          .CLICK HERE TO LOGIN VIA EMAIL
                        </span> */}
                    </span>
                  </span>
                </div>
                <div className="card-center">
                  <WalletProviderList isLogin={false} />
                </div>
                {/* <div className="box-policy mt-3 _font-large">
                  <a href="javascript:void(0)" onClick={loginEmailActive}>
                    Click here
                  </a>{" "}
                  to login via Email
                </div> */}
              </div>
            )}
          </Container>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default LoginPage;
