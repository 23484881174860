import iconETH from "../assets/images/icon-eth.svg";

import { Row, Col, Container, Button } from "react-bootstrap";
import "../assets/sass/profile_page.scss";
import { useMoralisWeb3Api } from "react-moralis";
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";
import _ from "lodash";
import { networkMapFromFrontend } from "../utils/web3Connect";
import { useNavigate } from "react-router-dom";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import env from "../env";

export const CardNFT = ({ data }) => {
  const navigate = useNavigate();
  const { chainId } = useWeb3React();
  const [newImage, setNewImage] = useState();
  const [newName, setNewName] = useState();
  const Web3Api = useMoralisWeb3Api();
  const [openDetail, setOpenDetail] = useState("");
  const [collection, setCollection] = useState();
  const [newVideo, setNewVideo] = useState();
  const [newAudio, setNewAudio] = useState();

  useEffect(() => {
    const metaData = _.get(data, "metadata");

    if (metaData != undefined) {
      try {
        const model = JSON.parse(metaData);

        var image = _.get(model, "image");

        if (image != undefined) {
          image = wrapIPFSContent(image);
          setNewImage(image);
        }

        var name = _.get(model, "name");

        if (name != undefined) {
          setNewName(model.name);
        }

        var animationUrl = _.get(model, "animation_url");
        if (animationUrl != undefined) {
          animationUrl = wrapIPFSContent(animationUrl);
          setNewVideo(animationUrl);
        }

        var audioUrl = _.get(model, "audio_url");
        if (audioUrl != undefined) {
          audioUrl = wrapIPFSContent(audioUrl);
          setNewAudio(audioUrl);
        }
      } catch (error) {}
    }
  }, []);

  useEffect(() => {
    if (chainId === undefined) {
    } else {
      setTokenAddress(chainId);
    }
  }, [chainId, openDetail]);

  const reSyncMetadata = async () => {
    let id = data.token_id;
    let tokenAddress = data.token_address;
    let chainHexId = "0x" + chainId.toString(16);

    fetch(
      `${env.REACT_APP_MORALIS_ENDPOINT_URL}/api/v2/nft/${tokenAddress}/${id}/metadata/resync?chain=${chainHexId}&flag=uri&mode=sync`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": env.REACT_APP_MORALIS_API_KEY,
        },
      }
    ).then((response) => {
      getTokenIdMetadata();
    });
  };

  const getTokenIdMetadata = async () => {
    let chainHexId = "0x" + chainId.toString(16);

    const options = {
      address: data.token_address,
      token_id: data.token_id,
      chain: chainHexId,
    };
    const tokenIdMetadata = await Web3Api.token.getTokenIdMetadata(options);
    const metaData = JSON.parse(tokenIdMetadata.metadata);
    setNewImage(metaData.image);
    setNewName(metaData.name);
  };

  const setTokenAddress = (chain) => {
    const tokenAddress = _.get(data, "token_address");
    const collectionName = _.get(data, "name");
    setCollection(collectionName);
    const tokenId = _.get(data, "token_id");
    setOpenDetail(
      "/assets/" +
        networkMapFromFrontend[chain].assets +
        "/" +
        tokenAddress +
        "/" +
        tokenId
    );
  };

  const handleOpenDetail = () => {
    navigate(openDetail);
  };

  const wrapIPFSContent = (ipfs) => {
    //ipfs://QmcNmNc3sEPg2HgFGR9mTvuoivzHyVQeL9mLaQBHy1oPZS/7311.png
    //https://ipfs.io/ipfs/Qma57xRpAWcwZk7soXWvChto55wBrDdkGCsTbK8kugyMQL
    //https://ipfs.io/ipfs/

    var image = ipfs;
    if (image.startsWith("ipfs://ipfs/")) {
      image = image.replace("ipfs://", "https://cloudflare-ipfs.com/");
    }
    if (image.startsWith("ipfs://")) {
      image = image.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/");
    }
    if (image.startsWith("https://ipfs.io/ipfs/")) {
      image = image.replace(
        "https://ipfs.io/ipfs/",
        "https://cloudflare-ipfs.com/ipfs/"
      );
    }
    return image;
  };

  return (
    <div>
      {newImage == undefined ? (
        <div className="card-box hover">
          <div className="_image-airdrop">
            <div className="_image-nft-untitled">
              <div className="p-3">
                <p>Look like we couldn’t process metadata for this NFT</p>
                <Button variant="light" onClick={reSyncMetadata}>
                  Refresh
                </Button>
              </div>
            </div>
          </div>

          <div className="_name-nft">Untited</div>
        </div>
      ) : (
        <div>
          <div className="card-box hover" onClick={handleOpenDetail}>
            <div className="_image-airdrop">
              <img className="_image-nft " src={newImage} />
            </div>

            <div className="_name-nft mb-0">{newName}</div>
            <div>
              {collection ? collection : null}{" "}
              {collection ? <i className="_icon _icon-verify"></i> : null}
            </div>
            {data.amount === "1" ? (
              ""
            ) : (
              <span className="amount-nft">{"x" + data.amount}</span>
            )}
            {newVideo || newAudio ? (
              <PlayCircleFilledWhiteIcon className="icon-play" />
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};
