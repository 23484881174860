import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import Cookies from "js-cookie";
import api from "../api";
import _ from "lodash";
import {
  networkMap,
  supportedChainlist,
  networkMapFromFrontend,
} from "../utils/web3Connect";
import { connectorsByName } from "../utils/web3Connect";
import { ConnectorNames } from "../constants";

export const useAuthen = () => {
  const [authed, setAuthed] = useState(false);
  const { library, chainId, account, activate, active, deactivate, error } =
    useWeb3React();

  useEffect(() => {
    const { access_token, refresh_token } = getCurrentToken();
    if (!access_token) {
      setAuthed(false);
    } else {
      setAuthed(access_token && access_token.length > 0);
    }
  }, []);

  const clearToken = () => {
    Cookies.remove("access_token", { domain: window.location.hostname });
    Cookies.remove("refresh_token", { domain: window.location.hostname });
  };

  const getCurrentToken = () => {
    let access_token = Cookies.get("access_token", { domain: window.location.hostname });
    let refresh_token = Cookies.get("refresh_token", { domain: window.location.hostname });

    return { access_token: access_token, refresh_token: refresh_token };
  };

  const walletConnectTheConcert = (connector, isLogin) => {
    const { access_token, refresh_token } = getCurrentToken();
    var chainName = "polygon";
    var connectorName = "";

    supportedChainlist.forEach((id) => {
      if (chainId == networkMap[id].chainId) {
        chainName = networkMapFromFrontend[id].chainName;
      }
    });

    switch (connector) {
      case ConnectorNames.Injected:
        connectorName = "metamask";
        break;
      case ConnectorNames.CoinbaseWallet:
        connectorName = "coinbasewallet";
        break;
      case ConnectorNames.WalletConnect:
        connectorName = "walletconnect";
        break;
    }

    let request = {
      uid: account,
      service: chainName.toLowerCase(),
      account: connectorName,
      is_login: isLogin,
    };

    if (connector && account && chainName) {
      return request;
    } else {
      return null;
    }
  };

  return {
    authed,
    clearToken,
    walletConnectTheConcert,
  };
};
