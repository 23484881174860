import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Connection, PublicKey, clusterApiUrl } from "@solana/web3.js";

export const useConnectPhantomWallet = () => {
  const [walletAvail, setWalletAvail] = useState(false);
  const [provider, setProvider] = useState();
  const [connected, setConnected] = useState(false);
  const [addressWallet, setAddress] = useState();
  const solWindow = window;

  useEffect(() => {
    if ("solana" in window) {
      const solWindow = window;
      if (solWindow?.solana?.isPhantom) {
        setProvider(solWindow.solana);
        setWalletAvail(true);
        // Attemp an eager connection
        // solWindow.solana.connect({ onlyIfTrusted: false });
      }
    }
  }, []);

  useEffect(() => {
    provider?.on("connect", (publicKey) => {
      setConnected(true);
      setAddress(publicKey);
    });
    provider?.on("disconnect", () => {
      setConnected(false);
      setAddress(null);
    });
  }, [provider]);

  const connectHandler = (event) => {
    solWindow.solana.connect({ onlyIfTrusted: false });
    provider?.connect().catch((err) => {
      console.error("connect ERROR:", err);
    });
  };

  const disconnectHandler = (event) => {
    provider?.disconnect().catch((err) => {
      console.error("disconnect ERROR:", err);
    });
  };

  return { connected, connectHandler, disconnectHandler, addressWallet };
};
