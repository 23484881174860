import React,{ useEffect, useState } from "react";
import { Container, Row, Col, Form } from 'react-bootstrap'
import api from "../api"
import "../assets/sass/home_page.scss";
import _ from "lodash"
import Layout from "../components/Layout/Layout";
import ic_heart from '../assets/images/icon-heart.svg'
import ic_heart_active from '../assets/images/icon-heart-active.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSearchParams, Link, useNavigate } from 'react-router-dom'

const SearchResult = () => {
  const [ q ]  = useSearchParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [collections, setCollections] = useState([]);
  const [categories, setCategories] = useState([]);
  const [blockchains, setBlockchains] = useState([]);
  const [error, setError] = useState(null);
  const [isIconActive, setIconAtive] = useState(false);
  const [isCatActive, setCatAtive] = useState(false);
  const [isChainActive, setChainAtive] = useState(false);

  const handleToggle = () => setIconAtive(current => !current);
  const handleCategory = () => setCatAtive(current => !current);
  const handleChain = () => setChainAtive(current => !current);

  const handleCheckboxChange = (e) => {
    let query     = q.get('q');
    query = (query !== 'null'?query:'')
    let blockchain_ids_str = '';
    let category_ids_str = '';
    let collection_ids_str = '';

    let blockchain_ids = window.$('input[name="blockchain_ids"]:checked');
    let category_ids = window.$('input[name="category_ids"]:checked');
    let collection_ids = window.$('input[name="collection_ids"]:checked');

    if (blockchain_ids.length > 0) {

      let chain_id = '';
      blockchain_ids.map((chain, i) => { 
        chain_id = chain_id + window.$(i).attr('value') + ',';
      });

      blockchain_ids_str = '&blockchain_ids=' + chain_id.replace(/,\s*$/, "");
    };

    if (category_ids.length > 0) {
      let cat_id = '';
      category_ids.map((cat, i) => { 
        cat_id = cat_id + window.$(i).attr('value') + ',';
      });

      category_ids_str = '&category_ids=' + cat_id.replace(/,\s*$/, "");
    };

    if (collection_ids.length > 0) {
      let collection_id = '';
      collection_ids.map((name, i) => { 
        collection_id = collection_id + window.$(i).attr('value') + ',';
      });

      collection_ids_str = '&collection_ids=' + collection_id.replace(/,\s*$/, "");
    };
  
    let url = blockchain_ids_str +  collection_ids_str  + category_ids_str;
    navigate("/search?q="+ query + url);
  }

  let keyword       = q.get('q');
  const category_id   = q.get('category_ids');
  const collection_id = q.get('collection_ids');
  const blockchain_id = q.get('blockchain_ids');
  let query_request = '';
  
  keyword = (keyword !== 'null'?keyword:'')
  window.$('input[id="main_search"]').attr('value', keyword);

  if (category_id) {
    query_request = query_request + '&category_ids=' + category_id;

  } else if (collection_id){
    query_request = query_request + '&collection_ids=' + collection_id;

  } else if(blockchain_id) {
    query_request = query_request + '&blockchain_ids=' + blockchain_id;

  }

  React.useEffect( ()=> {
    let keyword = q.get('q');
    let request = {q: `${keyword}${query_request}`};
    api.products.getProducts(
        (response) => {
          setProducts(response.data.data.record);
        },
        (error) => {
          setError(error);
        }, request)
      
  },[category_id]);

  React.useEffect( ()=> {
    let keyword = q.get('q');
    let request = {q: `${keyword}${query_request}`};
    api.products.getProducts(
        (response) => {
          setProducts(response.data.data.record);
        },
        (error) => {
          setError(error);
        }, request)
  },[blockchain_id]);
  
  React.useEffect( ()=> {
    let keyword = q.get('q');
    let request = {q: `${keyword}${query_request}`};
    api.products.getProducts(
        (response) => {
          setProducts(response.data.data.record);
        },
        (error) => {
          setError(error);
        }, request)
  },[collection_id]);
  
  React.useEffect( ()=> {
    let keyword = q.get('q');
    if (keyword) { 
      let request = {q: `${keyword}`};

      api.products.getProducts(
          (response) => {
            setProducts(response.data.data.record);
          },
          (error) => {
            setError(error);
          }, request)
      }
   
    api.collections.getCollections(
      (response) => {
          setCollections(_.get(response,'data.data.record'))
      },
      (error) => {

      })
 
    api.categories.getCategories(
      (response) => {
          let data = _.get(response,'data.data.record');
          setCategories(data)
      },
      (error) => {

        })
        
    api.blockchains.getBlockchains(
      (response) => {
        let data = _.get(response,'data.data.record');
        setBlockchains(data)
      },
      (error) => {

      })
  },[])
  
  function imageSrc(data){
    var logo = _.first(data);
    return _.get(logo, 'url')
  }

  return (
    <Layout>
      <div className="search-section">
        <Container>
          <Row>
            <Col md={3}>
              <div className="search-result">Filter</div>
              <div className="box-filter">
                <div className="filter">
                  <div className="d-flex justify-content-between">
                    <h5>Chain</h5>
                    <span onClick={handleToggle} className="d-block">
                      { isIconActive?
                        <KeyboardArrowDownIcon/> :<KeyboardArrowUpIcon/>
                      }</span>
                  </div>
                  <div className={isIconActive?'d-none':'d-block'}>
                    <Form>
                        <div key={`blockchain`} className="mb-2">
                        {   blockchains?
                            blockchains.map((chain, i) => { 
                              return( 
                                <Form.Check
                                key={`blockchain-${_.get(chain, 'id')}`} 
                                type='checkbox'
                                id={`blockchain-${i}`}
                                label={`${chain.name}`}
                                name='blockchain_ids'
                                value={`${chain.id}`}
                                onChange={e => handleCheckboxChange(e)}
                              />
                              )
                            }):
                            null
                          }
                        </div>
                    </Form>
                  </div>
                </div>
                <hr/>
                <div className="filter">
                  <div className="d-flex justify-content-between">
                    <h5>Category</h5>
                    <span onClick={handleCategory} className="d-block">
                      { isCatActive?
                        <KeyboardArrowUpIcon/>:<KeyboardArrowDownIcon/>
                      }</span> 
                  </div>
                  <div className={isCatActive?'d-block':'d-none'}>
                    <Form>
                        <div key={`category-checkbox`} className="mb-2">
                        {   categories?
                            categories.map((cat, i) => { 
                              return( 
                                <Form.Check 
                                key={`category-${_.get(cat, 'id')}`} 
                                type='checkbox'
                                id={`category-${i}`}
                                label={`${cat.name.th}`}
                                name='category_ids'
                                value={`${cat.id}`}
                                onChange={e => handleCheckboxChange(e)}
                              />
                              )
                            }):
                            null
                          }
                        </div>
                    </Form>
                  </div>
                </div>
                <hr/>
                <div className="filter">
                  <div className="d-flex justify-content-between">
                    <h5>Collections</h5>
                    <span onClick={handleChain} className="d-block">
                      { isChainActive?
                        <KeyboardArrowUpIcon/>:<KeyboardArrowDownIcon/>
                      }</span> 
                  </div>
                  <div className={isChainActive?'d-block':'d-none'}>
                    <Form>
                        <div key={`collection-checkbox`} className="mb-2">
                          {
                            collections.map((item, index) => { 
                              return( 
                                <Form.Check 
                                key={`collection-${_.get(item, 'id')}`} 
                                type='checkbox'
                                id={`collection-${index}`}
                                label={`${item.name}`}
                                name='collection_ids'
                                value={`${item.id}`}
                                onChange={e => handleCheckboxChange(e)}
                              />
                              )
                            })
                          }
                        </div>
                    </Form>
                  </div>
                </div>

              </div>
            </Col>

            <Col md={9}> 
              <div className="search-result">Search Results</div>
              <Row className="search-column">
                {
                  products.length > 0?
                  products.map((data, index) => { 
                    return(
                        <Col xs={12} md={4} lg={4} key={`product-${_.get(data, 'id')}`} >
                          <Link to={"/campaign/" + _.get(data, 'collection.blockchain_id') + "/" + _.get(data, 'id')} >
                            <div className="box-item">
                              <img src={imageSrc(_.get(data, 'images'))} className="nft-image"></img>
                              <p className="nft-name">{data.model}</p>
                              <div className="d-flex justify-content-between align-items-center">
                                  <Col xs="5" className="airdrop align-self-center text-center"> AIRDROP</Col>
                                  <Col xs="4">
                                    <div  className="favorite">
                                      <img src={ic_heart}></img>
                                      <span className='favorite-sum'>10</span>
                                    </div>
                                  </Col>
                                  <Col xs="3">
                                    <button className="btn-claim">CLAIM</button>
                                  </Col>
                              </div>
                            </div>
                          </Link>
                        </Col>
                  )
                }): <div className="text-center mt-5 _font-medium-x">No Result</div>
                }
                </Row>
            </Col>
          </Row>  

        </Container>
      </div>
    </Layout>
  );
};

export default SearchResult;