import { useEffect, useState, useRef, useContext, useMemo } from "react";
import axios from "axios";
import { useMoralisWeb3Api } from "react-moralis";
import { getMetaDataFromTokenUri } from "../utils/ipfsHelper";

const getNftFromAddress = async (address, chainIdHex, Web3Api, cursor) => {
  const options = {
    chain: chainIdHex,
    address: address,
    limit: "20",
    cursor: cursor,
  };
  const polygonNFTs = await Web3Api.account.getNFTs(options);
  return { polygonNFTs };
};

const useGetNFTs = () => {
  const Web3Api = useMoralisWeb3Api();

  const getAllNftInAddress = async (address, chainIdHex, cursor) => {
    try {
      const { polygonNFTs } = await getNftFromAddress(
        address,
        chainIdHex,
        Web3Api,
        cursor
      );
      // const nftList = await getFullPathCollection(polygonNFTs.result);
      return { data: polygonNFTs };
    } catch (e) {
      return [];
    }
  };

  const getFullPathCollection = async (arrOfResults) => {
    const ipfsList = [];

    for await (const element of arrOfResults) {
      const index = arrOfResults.indexOf(element);
      const tokenUri = element.token_uri;

      try {
        if (tokenUri === null || tokenUri === undefined) {
          ipfsList.push({
            id: index,
            image: "",
            name: "",
            data: element,
          });
        } else {
          const jsonManifestBuffer = await getMetaDataFromTokenUri(tokenUri);

          if (jsonManifestBuffer.status == "success") {
            ipfsList.push({
              id: index,
              image: jsonManifestBuffer.image,
              name: jsonManifestBuffer.name,
              data: element,
            });
          }
        }
      } catch (e) {
        // console.error(e);
        continue;
      }
    }

    // const filterImage = ipfsList.filter((value) => value.image != "");
    return ipfsList;
  };

  return { getAllNftInAddress };
};

const useGetNFTMetaByTokenId = () => {
  const Web3Api = useMoralisWeb3Api();

  const getMetaByTokenId = async (address, tokenId, chainIdHex) => {
    const options = {
      chain: chainIdHex.replace("ethereum", "eth"),
      address: address,
      token_id: tokenId,
    };
    const response = await Web3Api.token.getTokenIdMetadata(options);
    return response;
  };

  return { getMetaByTokenId };
};

const useOwnerCountOfNft = () => {
  const Web3Api = useMoralisWeb3Api();

  const fetchTokenIdOwners = async (address, tokenId, chainIdHex) => {
    const options = {
      address: address,
      token_id: tokenId,
      chain: chainIdHex.replace("ethereum", "eth"),
    };
    const tokenIdOwners = await Web3Api.token.getTokenIdOwners(options);
    return tokenIdOwners;
  };

  return { fetchTokenIdOwners };
};

export { useGetNFTs, useGetNFTMetaByTokenId, useOwnerCountOfNft };
