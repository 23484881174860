import { InjectedConnector } from "@web3-react/injected-connector";

import { CHAIN_ID, ConnectorNames, RPC_URL } from "../constants";
import { ethers } from "ethers";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import env from "../env";

const Wallet = ethers.Wallet;

const POLLING_INTERVAL = 8000;
const chainId = CHAIN_ID;

export const supportedChainlist =
  env.APP === "PRODUCTION" ? [1, 137] : [1, 137, 80001];

export const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};

export const injected = new InjectedConnector({
  supportedChainIds: supportedChainlist,
});

export const walletConnector = new WalletConnectConnector({
  rpc: {
    1: "https://mainnet.infura.io/v3/",
    137: "https://polygon-rpc.com/",
    80001: "https://rpc-mumbai.matic.today/",
  },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
});

export const coinbaseConnector = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/${env.REACT_APP_INFURA_KEY}`,
  appLogoUrl: "https://www.theconcert.com/dist/images/logo/logo_red.png",
  appName: "TCC NFT",
  supportedChainIds: supportedChainlist,
});

export const connectorsByName = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletConnector,
  [ConnectorNames.CoinbaseWallet]: coinbaseConnector,
  [ConnectorNames.PhantomWallet]: "",
  [ConnectorNames.Glow]: "",
};

export const networkMap = {
  1: {
    chainId: "0x1",
    chainName: "Ethereum Mainnet",
    nativeCurrency: { name: "tETH", symbol: "tETH", decimals: 18 },
    rpcUrls: ["https://mainnet.infura.io/v3/"],
    blockExplorerUrls: ["https://etherscan.io"],
    logo: "https://chainlist.org/_next/image?url=https%3A%2F%2Fdefillama.com%2Fchain-icons%2Frsz_ethereum.jpg&w=64&q=75",
  },
  137: {
    chainId: "0x89", //
    chainName: "Polygon Mainnet",
    nativeCurrency: { name: "tMATIC", symbol: "tMATIC", decimals: 18 },
    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorerUrls: ["https://polygonscan.com"],
    logo: "https://chainlist.org/_next/image?url=https%3A%2F%2Fdefillama.com%2Fchain-icons%2Frsz_polygon.jpg&w=64&q=75",
  },
  80001: {
    chainId: "0x13881",
    chainName: "Matic(Polygon) Mumbai Testnet",
    nativeCurrency: { name: "tMATIC", symbol: "tMATIC", decimals: 18 },
    rpcUrls: ["https://rpc-mumbai.matic.today/"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
    logo: "https://chainlist.org/_next/image?url=https%3A%2F%2Fdefillama.com%2Fchain-icons%2Frsz_polygon.jpg&w=64&q=75",
  },
};

export const networkMapForMetamask = {
  1: {
    chainId: "0x1",
    chainName: "Ethereum Mainnet",
    nativeCurrency: { name: "tETH", symbol: "tETH", decimals: 18 },
    rpcUrls: ["https://mainnet.infura.io/v3/"],
    blockExplorerUrls: ["https://etherscan.io"],
  },
  137: {
    chainId: "0x89", //
    chainName: "Polygon Mainnet",
    nativeCurrency: { name: "tMATIC", symbol: "tMATIC", decimals: 18 },
    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorerUrls: ["https://polygonscan.com"],
  },
  80001: {
    chainId: "0x13881",
    chainName: "Matic(Polygon) Mumbai Testnet",
    nativeCurrency: { name: "tMATIC", symbol: "tMATIC", decimals: 18 },
    rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
  },
};

export const networkMapFromFrontend = {
  1: {
    chainId: "0x1",
    chainName: "ethereum",
    assets: "ethereum",
  },
  137: {
    chainId: "0x89", //
    chainName: "polygon",
    assets: "polygon",
  },
  80001: {
    chainId: "0x13881",
    chainName: "polygon",
    assets: "mumbai",
  },
};
