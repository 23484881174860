import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Modal,
  Form,
  Button,
  CloseButton,
  Spinner,
} from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import "../assets/sass/airdrop_campaign.scss";
import iconEye from "../assets/images/campaign/icon-eye.png";
import iconUserAdd from "../assets/images/campaign/icon-user-add.png";
import iconFavorite from "../assets/images/campaign/icon-favorite.png";
import api from "../api";
import _ from "lodash";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import iconSuccess from "../assets/images/icon-success.svg";
import iconError from "../assets/images/icon-error.svg";
import { WalletContext } from "../providers/WalletProvider";
import { useAuthen } from "../hooks/useAuthen";
import Cookies from "js-cookie";
import {
  useGetNFTMetaByTokenId,
  useOwnerCountOfNft,
} from "../hooks/useGetNFTs";
import { getMetaDataFromTokenUri } from "../utils/ipfsHelper";
import env from "../env";
import ReactPlayer from "react-player";

const PublicProductDetail = () => {
  const { getMetaByTokenId } = useGetNFTMetaByTokenId();
  const { fetchTokenIdOwners } = useOwnerCountOfNft();

  const { chain, address_token, id } = useParams();
  const [metaData, setMetaData] = useState();
  const [metaDataFromUri, setMetaDataFromUri] = useState();
  let navigate = useNavigate();
  const {
    walletActive,
    address,
    tokenBalanceList,
    disconnectWallet,
    currentConnector,
  } = useContext(WalletContext);
  const { checkAddressHasConnectedEmail, clearToken, walletConnectTheConcert } =
    useAuthen();
  const [product, setProduct] = useState(null);
  const [productImage, setProductImage] = useState();
  const [error, setError] = useState(null);
  const [errorTitle, setErrorTitle] = useState();
  const [showModal, setShowModal] = useState(false);
  const [errorDesc, setErrorDesc] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessCodeModal, setSuccessCodeModal] = useState(false);
  const [showInvalidcodeModal, setShowInvalidcodeModal] = useState(false);
  const [loadError, setLoadError] = useState(null);

  const [shareURL, setShareURL] = useState();
  const [isFavorite, setIsFavorite] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ownerCount, setOwnerCount] = useState();
  const [newVideo, setNewVideo] = useState();
  const [newAudio, setNewAudio] = useState();

  useOnceCall(() => {
    console.log("loadMetadata");
    let timer = setTimeout(() => {
      loadMetaData();
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  });

  useEffect(() => {
    if (currentConnector && address) {
      const request = walletConnectTheConcert(currentConnector, true);
      if (request) {
        walletConnect(request);
      }
    }
  }, [address, currentConnector]);

  function useOnceCall(cb, condition = true) {
    const isCalledRef = useRef(false);

    useEffect(() => {
      if (condition && !isCalledRef.current) {
        isCalledRef.current = true;
        cb();
      }
    }, [cb, condition]);
  }

  const loadMetaData = async () => {
    getMetaByTokenId(address_token, id, chain)
      .then(async (result) => {
        try {
          let tokenUri = _.get(result, "token_uri");
          if (tokenUri) {
            let metadataString = _.get(result, "metadata");
            if (metadataString) {
              var metaModel = JSON.parse(metadataString);
              metaModel.image = metaModel?.image.replace(
                "ipfs://",
                "https://cloudflare-ipfs.com/"
              );
              var videoUrl = _.get(metaModel, "animation_url");
              if (videoUrl != undefined) {
                videoUrl = wrapIPFSContent(videoUrl);
                console.log("videoUrl", videoUrl);
                setNewVideo(videoUrl);
              }
              var audioUrl = _.get(metaModel, "audio_url");
              if (audioUrl != undefined) {
                audioUrl = wrapIPFSContent(audioUrl);
                console.log("audioUrl", audioUrl);
                setNewAudio(audioUrl);
              }

              console.log("metaModel", metaModel);
              setMetaDataFromUri(metaModel);
            }
          }

          const owners = await fetchTokenIdOwners(address_token, id, chain);
          setOwnerCount(owners.total ?? 0);
        } catch (e) {
          // setError(e);
        }
        setMetaData(result);
      })
      .catch((error) => {
        // setLoadError(error);
      });
  };

  const walletConnect = (request) => {
    api.wallets.walletConnect(
      (response) => {
        let access_token = _.get(response, "data.data.access_token");
        let refresh_token = _.get(response, "data.data.refresh_token");
        if (access_token && refresh_token) {
          sessionStorage.setItem("access_token", access_token);
          sessionStorage.setItem("refresh_token", refresh_token);
          Cookies.set("access_token", access_token, {
            domain: window.location.hostname,
          });
          Cookies.set("refresh_token", refresh_token, {
            domain: window.location.hostname,
          });
        }
      },
      (error) => {
        let statusCode = _.get(error, "data.error.code");
        let message = _.get(error, "data.error.message");

        if (statusCode === 400) {
          setErrorMessage(message);
        }
      },
      request
    );
  };

  const handleShowPrivateCode = () => setShowModal(true);

  const handleClose = () => setShowModal(false);
  const handleCloseInvalidcodeModel = () => setShowInvalidcodeModal(false);
  const handleCloseSuccessCodeModal = () => setSuccessCodeModal(false);
  const errorShow = () => setError(true);
  const errorClose = () => setError(false);
  const [showModalShare, setShowModalShare] = useState(false);
  const closeModalShare = () => setShowModalShare(false);

  const handleToMyNFT = () => {
    navigate("/profile?tab=nft");
  };

  const shareToTwitter = () => {
    var url = shareURL;
    window.open(env.REACT_SHARE_TWITTER + encodeURIComponent(url));
  };

  const shareToFacebook = () => {
    var url = shareURL;
    window.open(env.REACT_SHARE_FACEBOOK + encodeURIComponent(url));
  };

  const shareToTelegram = () => {
    var url = shareURL;
    window.open(env.REACT_SHARE_TELEGRAM + encodeURIComponent(url));
  };

  const shareToSocial = () => {
    let url = window.location.pathname;
    setShareURL(env.FRONT_URL + url);
    setShowModalShare(true);
  };

  const favoriteProduct = () => {
    let access_token = Cookies.get("access_token", { domain: window.location.hostname });
    if (access_token == "null" || access_token === undefined) {
    } else {
      setIsFavorite(isFavorite ? false : true);
      let request = {
        product_id: id,
        action: !isFavorite ? "add" : "remove",
      };

      api.users.userFavorites(
        (response) => {
          let data = response.data.data;
        },
        (error) => {
          setErrorTitle("");
          setErrorDesc("");
          errorShow();
        },
        request
      );
    }
  };

  const wrapIPFSContent = (ipfs) => {
    //ipfs://QmcNmNc3sEPg2HgFGR9mTvuoivzHyVQeL9mLaQBHy1oPZS/7311.png
    //https://ipfs.io/ipfs/Qma57xRpAWcwZk7soXWvChto55wBrDdkGCsTbK8kugyMQL
    //https://ipfs.io/ipfs/

    var image = ipfs;
    if (image.startsWith("ipfs://ipfs/")) {
      image = image.replace("ipfs://", "https://cloudflare-ipfs.com/");
    }
    if (image.startsWith("ipfs://")) {
      image = image.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/");
    }
    if (image.startsWith("https://ipfs.io/ipfs/")) {
      image = image.replace(
        "https://ipfs.io/ipfs/",
        "https://cloudflare-ipfs.com/ipfs/"
      );
    }
    return image;
  };

  return (
    <Layout>
      {loadError != null ? (
        <div className="page-not-found">
          <Container>
            <h2 className="not-found">404</h2>
            <h2>PAGE NOT FOUND</h2>
            <p>Sorry, the page you're looking for doesn't exist.</p>
            <a href="/" className="btn-nft">
              RETURN HOME
            </a>
          </Container>
        </div>
      ) : (
        <div>
          {metaData === undefined || metaDataFromUri === undefined ? (
            <div className="main-section">
              <div className="main-section-top text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </div>
          ) : (
            <div className="main-section">
              <div className="main-section-top">
                <Container>
                  <div className="top-main-logo-airdrop">
                    <Row>
                      <Col md={5}>
                        {newVideo || newAudio ? (
                          <ReactPlayer
                            controls
                            muted={true}
                            loop={true}
                            playing={true}
                            width={473}
                            height={newVideo ? 360 : 60}
                            url={newVideo ? newVideo : newAudio}
                          />
                        ) : null}

                        {metaDataFromUri?.image === undefined ||
                        newVideo ||
                        newAudio ? null : (
                          <img
                            src={metaDataFromUri.image}
                            className="tcclogo-group"
                          />
                        )}
                      </Col>
                      <Col md={7}>
                        <div className="top-info-box">
                          <div className="item">
                            <p className="mb-0">
                              <span className="tcc-sub-title">
                                Collection : {_.get(metaData, "name")}
                              </span>
                            </p>
                            <p>
                              <span className="tcc-title">
                                {metaDataFromUri === null ||
                                metaDataFromUri === undefined
                                  ? ""
                                  : metaDataFromUri.name ?? ""}
                              </span>
                            </p>
                            <p>
                              <span className="tcc-sub-title-2">
                                by :{" "}
                                {metaData == null || metaData === undefined
                                  ? ""
                                  : metaData.name ?? ""}
                              </span>
                            </p>
                          </div>
                        </div>
                        <Row className="justify-content-center colection-info">
                          <Col className="text-center align-content-middle">
                            <img src={iconEye} className="viewers" />
                            <p className="viewers">
                              {_.get(product, "viewed", 0)} views
                            </p>
                          </Col>
                          <div className="v-separator"></div>
                          <Col className="text-center align-content-middle">
                            <img src={iconUserAdd} className="viewers" />
                            <p className="viewers">{ownerCount} owners</p>
                          </Col>
                          <div className="v-separator"></div>
                          <Col className="text-center align-content-middle">
                            <img src={iconFavorite} className="viewers" />
                            <p className="viewers">
                              {_.get(product, "favorited", 0)} favorites
                            </p>
                          </Col>
                        </Row>
                        <div className="shares-box">
                          <i
                            className="_icon _icon-share"
                            onClick={shareToSocial}
                          ></i>
                          <i
                            className="_icon _icon-tweet"
                            onClick={shareToTwitter}
                          ></i>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Container>
              </div>
              <div>
                {_.get(metaDataFromUri, "description") === undefined ? (
                  <div className="main-section-bottom">
                    <Container></Container>
                  </div>
                ) : (
                  <div className="main-section-bottom">
                    <Container>
                      <div className="description-box">
                        <Row>
                          <h2>DETAILS</h2>
                          <p className="detail-box">
                            {metaDataFromUri == null
                              ? ""
                              : metaDataFromUri.description}
                          </p>
                        </Row>
                      </div>
                    </Container>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <Modal
        show={showSuccessCodeModal}
        onHide={handleCloseSuccessCodeModal}
        backdrop="static"
        keyboard={false}
        className="modal-alert"
      >
        <Modal.Body>
          <div className="text-center">
            <img src={iconSuccess} className="icon-success" />
            <h2>Congratulations</h2>
            <p className="_font-medium-x _gray">
              Your AIRDROP CLAIM is successful{" "}
              {product == null || product === undefined
                ? ""
                : product.model ?? ""}{" "}
              appear in your wallet in MY NFTS WALLET section
            </p>
            <Button className="btn-green mt-3" onClick={handleToMyNFT}>
              GO TO MY NFTS
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showInvalidcodeModal}
        onHide={handleCloseInvalidcodeModel}
        backdrop="static"
        keyboard={false}
        className="modal-alert"
      >
        <Modal.Body>
          <div className="text-center">
            <img src={iconError} className="icon-error" />
            <h2>INVALID CODE</h2>
            <p className="_font-medium-x _gray">
              Unfortunately, you are not eligible for a claim
            </p>
            <Button
              className="btn-nft mt-3"
              onClick={handleCloseInvalidcodeModel}
            >
              TRY AGAIN
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModal}
        onHide={handleClose}
        className="modal-private-code"
      >
        <Modal.Header>
          <Modal.Title>PLEASE INPUT YOUR PRIVATE CODE</Modal.Title>
          <i className="_icon _icon-close" onClick={handleClose}></i>
        </Modal.Header>
        <Modal.Body>
          <div className="box-private-code">
            {/* <Form onSubmit={handleSubmit(submitPrivateCode)}>
                            <Form.Group
                                className="mb-5 mt-3"
                                controlId="Form.ControlInput1"
                            >
                                <Form.Label>PRIVATE CODE</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    autoFocus
                                    className="input-private-code"
                                    name="claim_code"
                                    {...register("claim_code", {
                                        required: true,
                                        minLength: 3,
                                        maxLength: 30,
                                    })}
                                />
                                <div className="text-danger">
                                    {errors.claim_code?.message}
                                    {errors.claim_code?.type === "required" &&
                                        "Claim code is required"}

                                </div>
                            </Form.Group>
                            <Button className="btn-nft" type="submit">
                                SUBMIT
                            </Button>
                        </Form> */}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={error}
        onHide={errorClose}
        backdrop="static"
        keyboard={false}
        className="modal-alert"
      >
        <Modal.Body>
          <div className="text-center">
            <img src={iconError} className="icon-error" />
            <h2>{errorTitle}</h2>
            <p className="_font-medium-x _gray">{errorDesc}</p>
            <Button className="btn-nft mt-3" onClick={errorClose}>
              TRY AGAIN
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModalShare}
        onHide={closeModalShare}
        backdrop="static"
        keyboard={false}
        className="modal-alert"
      >
        <Modal.Header>
          <Modal.Title>Share This Product</Modal.Title>
          <CloseButton variant="white" onClick={closeModalShare} />
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Link</Form.Label>
            <Form.Control type="text" value={shareURL} disabled readonly />
          </Form.Group>
          <div>
            <h3>Share</h3>
            <i
              className="_icon-share-footer _icon-share-footer-facebook"
              onClick={shareToFacebook}
            ></i>
            <i
              className="_icon-share-footer _icon-share-footer-twitter"
              onClick={shareToTwitter}
            ></i>
            <i
              className="_icon-share-footer _icon-share-footer-telegram"
              onClick={shareToTelegram}
            ></i>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default PublicProductDetail;
