import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
  Modal,
  Form,
  Button,
  CloseButton,
  Spinner,
} from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import "../assets/sass/airdrop_campaign.scss";
import iconEye from "../assets/images/campaign/icon-eye.png";
import iconUserOwned from "../assets/images/campaign/icon_user_owned.png";
import iconFavorite from "../assets/images/campaign/icon-favorite.png";
import btnClaim from "../assets/images/btnClaim.png";
import btnSoldout from "../assets/images/btnSold.png";
import iconGroupOne from "../assets/images/campaign/icon-group-one.png";
import iconGroupTwo from "../assets/images/campaign/icon-group-two.png";
import iconGroupThree from "../assets/images/campaign/icon-group-three.png";
import iconGroupFour from "../assets/images/campaign/icon-group-four.png";
import iconWatting from "../assets/images/icon-waiting.svg";
import api from "../api";
import _ from "lodash";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import iconError from "../assets/images/icon-error.svg";
import { WalletContext } from "../providers/WalletProvider";
import { useAuthen } from "../hooks/useAuthen";
import Cookies from "js-cookie";
import env from "../env";
import DocumentMeta from "react-document-meta";
import ReactPlayer from "react-player";
import { useMediaQuery } from "react-responsive";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
} from "react-share";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { Link } from "react-router-dom";

const AirDropCampaign = (props) => {
  const { library, chainId } = useWeb3React();
  const { id, collection_id } = useParams();
  let navigate = useNavigate();
  const { address, currentConnector, switchNetwork, setupNetwork } =
    useContext(WalletContext);
  const { walletConnectTheConcert } = useAuthen();
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [notConnectWallet, setNotConnectWallet] = useState(null);
  const [errorTitle, setErrorTitle] = useState();
  const [showModal, setShowModal] = useState(false);
  const [errorDesc, setErrorDesc] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessCodeModal, setSuccessCodeModal] = useState(false);
  const [showInvalidcodeModal, setShowInvalidcodeModal] = useState(false);
  const [showWattingModal, setWaitingModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [profile, setProfile] = useState();
  const [cover, setCover] = useState();
  const [media, setMedia] = useState();
  const [status, setStatus] = useState();
  const [chainUrl, setChainUrl] = useState();
  const [transaction, setTransaction] = useState();
  const nl2br = require("react-nl2br");
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [statusErrors, setStatusErrors] = useState();
  const [order, setOrder] = useState();
  const [orderId, setOrderId] = useState();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [shareURL, setShareURL] = useState();
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    getProduct(id);
    setShareURL(env.FRONT_URL + `campaign/${collection_id}/${id}`);
  }, []);

  useEffect(() => {
    if (currentConnector && address) {
      const request = walletConnectTheConcert(currentConnector, true);
      if (request) {
        walletConnect(request);
      }
    }
  }, [address, currentConnector]);

  useEffect(() => {
    if (status !== undefined && _.get(order, "status") === undefined && transaction !== undefined) {
      const interval = setInterval(() => {
        getOrderStatus(orderId);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [status, order, transaction]);

  const walletConnect = (request) => {
    api.wallets.walletConnect(
      (response) => {
        let access_token = _.get(response, "data.data.access_token");
        let refresh_token = _.get(response, "data.data.refresh_token");
        if (access_token && refresh_token) {
          sessionStorage.setItem("access_token", access_token);
          sessionStorage.setItem("refresh_token", refresh_token);
          Cookies.set("access_token", access_token, {
            domain: window.location.hostname,
          });
          Cookies.set("refresh_token", refresh_token, {
            domain: window.location.hostname,
          });
          getProfile();
        }
      },
      (error) => {
        let statusCode = _.get(error, "data.error.code");
        let message = _.get(error, "data.error.message");

        if (statusCode === 400) {
          setErrorMessage(message);
        }
      },
      request
    );
  };

  const getProduct = (id) => {
    let request = { collection_id: collection_id, increase_view: true };
    api.products.getProductDetail(
      (response) => {
        let data = response.data.data;
        let itemMedia = data.images;
        let coverImage = _.filter(itemMedia, { tag: "cover" })[0];
        let media = _.filter(itemMedia, { tag: "file" })[0];
        setMedia(media);
        setCover(coverImage);

        setProduct(data);
        setIsFavorite(data.is_favorite);
      },
      (error) => {
        setErrorTitle("");
        setErrorDesc("");
        errorShow();
        console.log(error);
      },
      request,
      id
    );
  };

  const getProfile = () => {
    api.users.getProfile(
      (response) => {
        let profile = _.get(response, "data.data");
        if (profile) {
          setProfile(profile);
        }
      },
      (error) => {},
      []
    );
  };

  const handleShowPrivateCode = () => {
    let access_token = Cookies.get("access_token", { domain: window.location.hostname });
    if (
      access_token === "null" ||
      access_token === undefined ||
      access_token === null
    ) {
      navigate("/login?return=true", { replace: true });
    } else {
      setShowModal(true);
    }
  };

  const handleClose = () => setShowModal(false);
  const handleCloseInvalidcodeModel = () => setShowInvalidcodeModal(false);
  const handleCloseWaitingModal = () => setWaitingModal(false);

  const handleCloseSuccessCodeModal = () => setSuccessCodeModal(false);
  const errorShow = () => setError(true);
  const errorClose = () => setError(false);
  const errorNotConnectWalletShow = () => setNotConnectWallet(true);
  const errorNotConnectWalletClose = () => setNotConnectWallet(false);
  const [showModalShare, setShowModalShare] = useState(false);
  const closeModalShare = () => setShowModalShare(false);

  const tryAgain = () => {
    setShowInvalidcodeModal(false);
    setShowModal(true);
  };
  const handleToMyNFT = () => {
    navigate("/profile?tab=nft");
  };

  const closeWaiting = () => {
    setWaitingModal(false);
  };

  const handleCaseFail = () => {
    setSuccessCodeModal(false);
    setShowModal(true);
    setDisabled(false);
    setStatus(null);
    setOrder(null);
  };

  const getOrderStatus = (id) => {
    api.users.getOrderStatus(
      (response) => {
        let data = response.data.data;
        setOrder(data);
      },
      (error) => {
        setStatusErrors(error);
      },
      id
    );
  };

  const submitPrivateCode = async (data) => {
    const isProduction = env.APP == "PRODUCTION";
    const defaulChain = isProduction ? env.REACT_APP_CHAIN_ID_DEFAULT : 80001;
    if (chainId != defaulChain) {
      const hasSetup = await setupNetwork(defaulChain);
      if (hasSetup) {
        await switchNetwork(defaulChain);
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    }
    const message = await signMessage();

    setDisabled(true);
    setShowModal(false);

    if (address.length == 0 || address === undefined) {
      errorNotConnectWalletShow();
      setShowModal(false);
    } else if (message == undefined) {
      setErrorTitle("Warning");
      setErrorDesc(
        "MetaMask Message Signature: User denied message signature."
      );
      errorShow();
      setDisabled(false);
    } else {
      setWaitingModal(true);

      let request = {
        product_id: id,
        code: data.claim_code,
        address: address,
        signature_token: message,
      };

      api.users.userClaim(
        (response) => {
          let data = response.data.data;

          let status = data.status;
          if (status !== undefined && (status === "correct" || status === "pending")) {
            setStatus(data.order.status);
            setOrderId(data.order.id);
            setTransaction(data.order.transaction_hash);
            setChainUrl(data.order.scan);

            setSuccessCodeModal(true);
          } else if (status === "verify_signature_false") {
            setErrorTitle("Warning");
            setErrorDesc(
              "MetaMask Message Signature: User message signature not correct."
            );
            errorShow();
            setDisabled(false);
          } else {
            setShowInvalidcodeModal(true);
            setDisabled(false);
          }
          setShowModal(false);
          setDisabled(false);
          setWaitingModal(false);
        },
        (error) => {
          setErrorTitle("");
          setErrorDesc("");
          errorShow();
          setDisabled(false);
          setWaitingModal(false);
        },
        request
      );
    }
  };

  const handleToProfile = () => {
    navigate("/profile", { replace: true });
  };

  const shareToSocial = () => {
    setShowModalShare(true);
  };

  const favoriteProduct = () => {
    let access_token = Cookies.get("access_token", { domain: window.location.hostname });
    if (access_token == "null" || access_token === undefined) {
    } else {
      setIsFavorite(isFavorite ? false : true);
      let request = {
        product_id: id,
        action: !isFavorite ? "add" : "remove",
      };

      api.users.userFavorites(
        (response) => {
          let data = response.data.data;
        },
        (error) => {
          setErrorTitle("");
          setErrorDesc("");
          errorShow();
        },
        request
      );
    }
  };

  const meta = {
    title: _.get(product, "model") + " | THECONCERT.IO",
    description: _.get(product, "description.description"),
    canonical: env.FRONT_URL + `product/${id}`,
    meta: {
      charset: "utf-8",
      name: {
        keywords: "Collection," + _.get(product, "model"),
      },
      property: {
        "og:title": _.get(product, "model"),
        "og:url": env.FRONT_URL + `product/${id}`,
        "og:image": _.get(cover, "url"),
        "og:description": _.get(product, "description.description"),
        "og:type": "website",
        "og:image:width": "1200",
        "og:image:height": "630",
        "og:site_name": "theconcert.io",

        "twitter:card": "summary_large_image",
        "twitter:title": _.get(product, "model"),
        "twitter:description": _.get(product, "description.description"),
        "twitter:image": _.get(cover, "url"),
        "twitter:site": "theconcert.io",
        "twitter:creator": "theconcert.io",
        "twitter:domain": env.FRONT_URL + `product/${id}`,
      },
    },
  };

  const signMessage = async () => {
    let message = undefined;
    try {
      let messageID = `TCCIO_${profile.id}`;
      let messageHash = ethers.utils.id(messageID);

      let messageHashBytes = ethers.utils.arrayify(messageHash);

      message = await library?.getSigner().signMessage(messageHashBytes);
    } catch (e) {
    } finally {
      return message;
    }
  };

  function GetStatus(status) {
    if (status.status === 2) {
      return <span className="_green">Confirmed</span>;
    } else if (status.status === 3) {
      return <span className="_red">Failed</span>;
    } else {
      return <span className="_yellow">Processing</span>;
    }
  }
  const ModalErrorCode = (props) => {
    return (
      <Modal
        {...props}
        backdrop="static"
        keyboard={false}
        className="modal-alert"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <img src={iconError} className="icon-error" />
            <h2>{errorTitle}</h2>
            <p className="_font-medium-x _gray">{errorDesc}</p>
            <Button className="btn-nft mt-3" onClick={errorClose}>
              TRY AGAIN
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const ModalWatting = (props) => {
    return (
      <Modal
        {...props}
        backdrop="static"
        keyboard={false}
        className="modal-alert _waiting-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <img src={iconWatting} className="icon-waiting rotate" />
            <h2>Heavy Congestion</h2>
            <p className="_font-medium-x _gray">
              Your minting experience may be slow due to heavy congestion on
              blockchain network.
            </p>
            {/* <Button className="btn-nft mt-3" onClick={closeWaiting}>
              Close
            </Button> */}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const ModalInvalid = (props) => {
    return (
      <Modal
        {...props}
        backdrop="static"
        keyboard={false}
        className="modal-alert"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <img src={iconError} className="icon-error" />
            <h2>INVALID CODE</h2>
            <p className="_font-medium-x _gray">
              Unfortunately, you are not eligible for a claim
            </p>
            <Button className="btn-nft mt-3" onClick={tryAgain}>
              TRY AGAIN
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const ModalNotConnect = (props) => {
    return (
      <Modal
        {...props}
        backdrop="static"
        keyboard={false}
        className="modal-alert"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <img src={iconError} className="icon-error" />
            <h2>Please connect wallet</h2>

            <Button className="btn-nft mt-3" onClick={handleToProfile}>
              GO TO PROFILE
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const ModalSuccessCode = (props) => {
    return (
      <Modal
        {...props}
        backdrop="static"
        keyboard={false}
        className="modal-alert _nft-claim-success"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-center">
            {/* <img src={iconSuccess} className="icon-success" />
            <h2>Congratulations</h2> */}
            <div className="nft-item-cliam">
              <img src={_.get(cover, "url")} />
            </div>
            <p className="_font-medium-x _gray mt-2">
              Your claim is successfully registered and in process.{" "}
              <span className="_white">
                {product == null || product === undefined
                  ? ""
                  : product.model ?? ""}{" "}
              </span>
              will be sent to your wallet address within 24 hours.
            </p>
            <div className="box-hash-confirm">
              <div className="p-2">
                <div className="_gray">Status</div>
                <GetStatus status={_.get(order, "status")} />
              </div>
              <div className="p-2">
                <div className="_gray">Transaction hash</div>
                <div className="hash-address cursor-pointer">
                  <a href={chainUrl} target="_blank">
                    {transaction}
                  </a>
                </div>
              </div>
            </div>

            {_.get(order, "status") === 3 ? (
              <Button className="btn-nft mt-3" onClick={handleCaseFail}>
                Try Again
              </Button>
            ) : null}

            {_.get(order, "status") === 1 ||
            _.get(order, "status") === undefined ? (
              <Button className="mt-3 btn-loading" disabled variant="secondary">
                <div className="loader">Loading...</div>
                <span className="wait-text">Wait for Index</span>
              </Button>
            ) : null}

            {_.get(order, "status") === 2 ? (
              <Button
                className="btn-green mt-3 btn-go-nft"
                onClick={handleToMyNFT}
              >
                GO TO MY NFTS
              </Button>
            ) : null}

            {/* {_.get(order, "status") !== 2 ? (
              <Button className="w-50 mt-3" disabled variant="secondary">
                <div className="loader">Loading...</div>
              </Button>
            ) : (
              <Button className="btn-green mt-3" onClick={handleToMyNFT}>
                GO TO MY NFTS
              </Button>
            )} */}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const ModalShare = (props) => {
    return (
      <Modal
        {...props}
        show={showModalShare}
        onHide={closeModalShare}
        backdrop="static"
        keyboard={false}
        className="modal-alert"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Share This Product</Modal.Title>
          <CloseButton variant="white" onClick={closeModalShare} />
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Link</Form.Label>
            <Form.Control type="text" value={shareURL} disabled readonly />
          </Form.Group>
          <div>
            <h3>Share</h3>
            <FacebookShareButton
              url={shareURL}
              quote={_.get(product, "model")}
              hashtag={
                _.get(product, "model")
                  ? "#" + _.get(product, "model").replace(/\s/g, "")
                  : null
              }
              description={_.get(product, "description")}
            >
              <i className="_icon-share-footer _icon-share-footer-facebook"></i>
            </FacebookShareButton>
            <TwitterShareButton
              title={_.get(product, "model")}
              url={shareURL}
              hashtags={[
                _.get(product, "model")
                  ? _.get(product, "model").replace(/\s/g, "")
                  : null,
                "Theconcert",
              ]}
            >
              <i className="_icon-share-footer _icon-share-footer-twitter"></i>
            </TwitterShareButton>
            <TelegramShareButton url={shareURL} title={_.get(product, "model")}>
              <i className="_icon-share-footer _icon-share-footer-telegram"></i>
            </TelegramShareButton>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  // useEffect(() => {
  //   if (window.ethereum) {
  //     handleEthereum();
  //   } else {
  //     window.addEventListener("ethereum#initialized", handleEthereum, {
  //       once: true,
  //     });
  //     setTimeout(handleEthereum, 3000); // 3 seconds
  //   }
  // }, []);

  // function handleEthereum() {
  //   const { ethereum } = window;
  //   if (ethereum && ethereum.isMetaMask) {
  //     console.log("Ethereum successfully detected!");
  //   } else {
  //     console.log("Please install MetaMask!");
  //   }
  // }

  return (
    <Layout>
      <DocumentMeta {...meta}>
        <div className="main-section">
          <div className="main-section-top">
            <Container>
              <div className="top-main-logo-airdrop">
                <Row>
                  <Col md={5}>
                    {_.get(product, "is_type") === "image" ||
                    _.get(product, "is_type") === "audio" ? (
                      <img
                        src={_.get(cover, "url")}
                        className="tcclogo-group"
                      />
                    ) : null}
                    {_.get(product, "is_type") === "video" ||
                    _.get(product, "is_type") === "audio" ? (
                      <ReactPlayer
                        controls
                        muted={true}
                        loop={true}
                        playing={true}
                        width={isMobile ? 325 : 473}
                        height={
                          _.get(product, "is_type") === "video" ? 360 : 60
                        }
                        url={_.get(media, "url")}
                      />
                    ) : null}
                  </Col>
                  <Col md={7}>
                    <div className="top-info-box">
                      <div className="item">
                        <p className="mb-0">
                          <span className="tcc-sub-title">
                            Collection :{" "}
                            <Link
                              to={`/collection/${_.get(
                                product,
                                "collection.id"
                              )}`}
                            >
                              {_.get(product, "collection.name")}{" "}
                              <i className="_icon _icon-verify"></i>
                            </Link>
                          </span>
                        </p>
                        <p className="mb-0">
                          <span className="tcc-title">
                            {product == null || product === undefined
                              ? ""
                              : product.model ?? ""}
                          </span>
                        </p>

                        <p>
                          <span className="tcc-sub-title-2">
                            by :{" "}
                            {product == null || product === undefined
                              ? ""
                              : product.store.name ?? ""}
                          </span>
                        </p>
                      </div>
                    </div>
                    <Row className="justify-content-center colection-info">
                      <Col className="text-center align-content-middle">
                        <img src={iconEye} className="viewers" />
                        <p className="viewers">
                          {_.get(product, "viewed", 0)} views
                        </p>
                      </Col>
                      <div className="v-separator"></div>
                      <Col className="text-center align-content-middle">
                        <img src={iconUserOwned} className="viewers" />
                        <p className="viewers">
                          {_.get(product, "owner", 0)} OWNED
                        </p>
                      </Col>
                      <div className="v-separator"></div>
                      <Col className="text-center align-content-middle">
                        <img src={iconFavorite} className="viewers" />
                        <p className="viewers">
                          {_.get(product, "favorited", 0)} favorites
                        </p>
                      </Col>
                    </Row>
                    <div className="shares-box">
                      {isFavorite ? (
                        <i
                          className="_icon _icon-fav-active"
                          onClick={favoriteProduct}
                        ></i>
                      ) : (
                        <i
                          className="_icon _icon-fav"
                          onClick={favoriteProduct}
                        ></i>
                      )}

                      <i
                        className="_icon _icon-share"
                        onClick={shareToSocial}
                      ></i>
                      <TwitterShareButton
                        title={_.get(product, "model")}
                        url={shareURL}
                        hashtags={[
                          _.get(product, "model")
                            ? _.get(product, "model").replace(/\s/g, "")
                            : null,
                          "Theconcert",
                        ]}
                      >
                        <i className="_icon _icon-tweet"></i>
                      </TwitterShareButton>
                    </div>

                    <div className="claim-airdrop row align-items-center">
                      <div className="_airdrop col-6">
                        <h2>
                          AIRDROP
                          <span>
                            {" "}
                            {_.get(product, "stock")}/
                            {_.get(product, "quantity")} Supply
                          </span>
                        </h2>
                      </div>
                      <div className="col-6">
                        {_.get(product, "quantity") > 0 ? (
                          <img
                            src={btnClaim}
                            onClick={handleShowPrivateCode}
                            className="cursor-pointer"
                          />
                        ) : (
                          <img src={btnSoldout} />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <div className="main-section-bottom">
            <Container>
              <div className="description-box">
                <Row>
                  <h2>DETAILS</h2>
                  <p className="detail-box">
                    {product == null
                      ? ""
                      : nl2br(product.description.description)}
                  </p>
                </Row>
              </div>
            </Container>

            <Container>
              <div className="claim-box">
                <h2>HOW TO CLAIM AN AIRDROP </h2>
                <Row>
                  <Col md="6">
                    <Row>
                      <Col xs="2">
                        <img src={iconGroupOne} className="group-howto-claim" />
                      </Col>
                      <Col xs="10">
                        <h3>connect wallet</h3>
                        <p>
                          <span className="how-to-claim">
                            Set up your wallet of choice, connect it to The
                            Concert NFT Marketplace by clicking the wallet icon
                            in the top right corner.
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6">
                    <Row>
                      <Col xs="2">
                        <img src={iconGroupTwo} className="group-howto-claim" />
                      </Col>
                      <Col xs="10">
                        <h3>SELECT AIRDROP COLLECTION</h3>
                        <p>
                          <span className="how-to-claim">
                            Find the airdrop collection you are elligible for
                            claim.
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6">
                    <Row>
                      <Col xs="2">
                        <img
                          src={iconGroupThree}
                          className="group-howto-claim"
                        />
                      </Col>
                      <Col xs="10">
                        <h3>Input Private code</h3>
                        <p>
                          <span className="how-to-claim">
                            Input a priate code obtained from a special event to
                            verify and register to claim your airdrop.
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6">
                    <Row>
                      <Col xs="2">
                        <img
                          src={iconGroupFour}
                          className="group-howto-claim"
                        />
                      </Col>
                      <Col xs="10">
                        <h3>Claim your NFT</h3>
                        <p>
                          <span className="how-to-claim">
                            Once your claim is successful, the NFT will be
                            airdropped directly to your wallet accessible in My
                            NFTs Wallet.
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>

        <ModalWatting
          show={showWattingModal}
          onHide={handleCloseWaitingModal}
        ></ModalWatting>
        {/* setWaitingModal(true)disabled */}

        <ModalSuccessCode
          show={showSuccessCodeModal}
          onHide={handleCloseSuccessCodeModal}
        ></ModalSuccessCode>

        <ModalInvalid
          show={showInvalidcodeModal}
          onHide={handleCloseInvalidcodeModel}
        ></ModalInvalid>

        <Modal
          show={showModal}
          onHide={handleClose}
          className="modal-private-code"
        >
          <Modal.Header>
            <Modal.Title>PLEASE INPUT YOUR PRIVATE CODE</Modal.Title>
            <i
              className="_icon _icon-close cursor-pointer"
              onClick={handleClose}
            ></i>
          </Modal.Header>
          <Modal.Body>
            <div className="box-private-code">
              <Form onSubmit={handleSubmit(submitPrivateCode)}>
                <Form.Group
                  className="mb-5 mt-3"
                  controlId="Form.ControlInput1"
                >
                  <Form.Label>PRIVATE CODE</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoFocus
                    className="input-private-code"
                    name="claim_code"
                    maxLength={20}
                    {...register("claim_code", {
                      required: true,
                      minLength: 3,
                      maxLength: 20,
                    })}
                  />
                  <div className="text-danger">
                    {errors.claim_code?.message}
                    {errors.claim_code?.type === "required" &&
                      "Claim code is required"}
                    {errors.claim_code?.type === "minLength" &&
                      "Enter a code that is at least 3 letters "}
                    {errors.claim_code?.type === "maxLength" &&
                      "Value must be less than 20 characters"}
                  </div>
                </Form.Group>
                <Button
                  className="btn-nft"
                  type="submit"
                  disabled={disabled ? true : false}
                >
                  {disabled ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null}{" "}
                  SUBMIT
                </Button>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        <ModalErrorCode show={error} onHide={errorClose}></ModalErrorCode>

        <ModalNotConnect
          show={notConnectWallet}
          onHide={errorNotConnectWalletClose}
        ></ModalNotConnect>

        <ModalShare show={showModalShare} onHide={closeModalShare}></ModalShare>
      </DocumentMeta>
    </Layout>
  );
};

export default AirDropCampaign;
