'use strict'

import _ from 'lodash'
const queryString = require('query-string');

export default {
    getContent(cb, errorCb, request = {}) {

        let params = {};
        let lang = _.get(request, 'lang');
      
        if (!_.isEmpty(request.group_code)) {
          params.group_code = request.group_code;
        }

        if (!_.isEmpty(request.page)) {
            params.page = request.page;
          }
  
        if (!_.isEmpty(request.slug)) {
          params.slug = request.slug;
        }
      
        let headers = {
          "Accept-Language": (typeof lang !== 'undefined') ? lang : 'en',
          'content-type': 'application/json',
        }
        let query = queryString.stringify(params)
        let result = {}

        // GET /v1.1/contents?group_code=page&status=true&page=1&slug=term-and-conditions

        window.axios.get('v1.1/contents?' + query, { headers: headers })
        .then(response => cb(response))
        .catch(err => {
            if (typeof errorCb === 'function') {
                errorCb(err.response)
            }
        })
    },

}
