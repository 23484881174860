import React from "react";
import { Navbar, Container, Row, Col} from "react-bootstrap";
import '../assets/sass/footer.scss'
import { Link } from "react-router-dom";

const Footer = () => {

  return (
    <React.Fragment>
      <>
      <div className="footer"> 
        <Container>
          <div className="footer-inner">
            <Row className="justify-content-md-center">
              <Col md="6" className="text-center">
                <h2><i className="_icon _icon-contact"></i> CONTACT US</h2>
                  <p>NFT WALLET & PLATFORM FOR MUSIC,<br/> CONCERT AND NIGHTLIFE COMMUNITY</p>
              </Col>
              <Col md="6" className="text-center">
                <h2><i className="_icon _icon-web-io"></i> THECONCERT.IO</h2>
                <p><a href="mailto:iryn@theconcert.com">IRYN@THECONCERT.COM</a></p>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="box-copyright">
          <Col>
            <div className="copyright">
              <span>Copyright © 2022 The Concert All rights reserved.</span> 
            </div>
          </Col>          
        </div>
      </div>
      </>
    </React.Fragment>
  );
};

export default Footer;
