import { useState } from 'react';
import Cookies from 'js-cookie'

export default function useToken() {
    const getToken = () => {
        return Cookies.get('access_token', { domain: window.location.hostname })
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        Cookies.set('access_token',userToken, { domain: window.location.hostname })
        setToken(userToken)
    };

    return {
        setToken: saveToken,
        token,
        getToken
    }
}
