import { BigNumber, ethers } from "ethers";
import { useMoralisWeb3Api } from "react-moralis";
import { useConvertToken } from "./useConvertToken";

const getTokenBalances = async (address, Web3Api) => {
  const options = {
    chain: "polygon",
    address: address,
  };
  const balance = await Web3Api.account.getTokenBalances(options);
  return { tokenBalance: balance };
};

const getNativeBalance = async (address, Web3Api) => {
  const options = {
    chain: "polygon",
    address: address,
  };
  const balance = await Web3Api.account.getNativeBalance(options);
  return { nativeBalance: balance };
};

const useGetAllTokenInAddress = () => {
  const Web3Api = useMoralisWeb3Api();
  const { convertPriceToUSD } = useConvertToken();

  const getAllTokenInAddress = async (address, chainId) => {
    const tokenBalanceList = [];
    const { price } = await convertPriceToUSD();
    const { tokenBalance } = await getTokenBalances(address, Web3Api);
    const { nativeBalance } = await getNativeBalance(address, Web3Api);

    if (nativeBalance) {
      const dataToFormat = ethers.utils.formatEther(nativeBalance.balance);
      const convertToFixedDecimal = parseFloat(dataToFormat);
      const convertToUSD = convertToFixedDecimal * price;

      tokenBalanceList.push({
        name: "MATIC",
        symbol: "MATIC",
        balance: convertToFixedDecimal.toFixed(4),
        usd: convertToUSD.toFixed(4),
      });
    }

    if (tokenBalance) {
      const mapToken = tokenBalance.map((data, index) => {
        const dataToFormat = ethers.utils.formatEther(data.balance);
        const convertToFixedDecimal = parseFloat(dataToFormat);
        const convertToUSD = convertToFixedDecimal * price;

        return {
          name: data.name,
          symbol: data.symbol,
          balance: convertToFixedDecimal.toFixed(4),
          usd: convertToUSD.toFixed(4),
        };
      });

      // setTokenBalanceList((value) => [...value, ...mapToken]);
      tokenBalanceList.push(...mapToken);
    }

    return tokenBalanceList;
  };

  return { getAllTokenInAddress };
};

export { useGetAllTokenInAddress };
