import React from "react";
import { useState, useEffect } from "react";
import "../assets/sass/profile_page.scss";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import api from "../api";
import _ from "lodash";
import { useForm } from "react-hook-form";
import OTPInput, { ResendOTP } from "otp-input-react";
import iconSuccess from "../assets/images/icon-success.svg";
import iconError from "../assets/images/icon-error.svg";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import iconVerify from "../assets/images/icon_check_circle.svg";
import Countdown from 'react-countdown';

const GeneralSetting = ({
  onSelectFile,
  avatar,
  preview,
  isSelectAvatar,
  selectedFile,
  onSelectCoverFile,
  cover,
  coverPreview,
  isSelectCover,
  coverSelectedFile,
  Submit,
  errorVerify
}) => {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [profile, setProfile]                   = useState();
  const [showModalVerify, setShowModalVerify]   = useState(false);
  const [showModalOtp, setShowModalOtp]         = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [reccord, setReccord] = useState([]);
  const [verified, setVerified] = useState();
  const [btnVerifyStatus, setBtnVerifyStatus] = useState(true)
  const [otpLimit, setOtpLimit] = useState(false)

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const errorClose = () => setError(false);
  const errorShow = () => setError(true);
  const alertClose = () => setAlert(false);
  const alertShow = () => setAlert(true);

  const handleShowModalVerify = () => {
    let phone = getValues("phoneNumber")
    if(phone.length < 9 || phone.length > 14){
      return;
    };
    setShowModalVerify(true)
  };
  const handleShowModalOtp = () => setShowModalOtp(true);
  const handleCloseModalVerify = () => setShowModalVerify(false);
  const handleCloseModalOtp = () => setShowModalOtp(false);
  const handleCloseSuccess = () => setShowModalSuccess(false);

  const wantedDelay = 120000; //120 s
  const [OTP, setOTP] = useState("");
  const getLocalStorageValue = (s) => localStorage.getItem(s);
  const [countdown, setCountdown] = useState(
    { date: Date.now(), delay: wantedDelay } //60 seconds
  );


  const showFlashSuccess = () => {
    setShowModalSuccess(true);
    let timer = setTimeout(() => {
      setShowModalSuccess(false);
      window.location.reload(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  useEffect(() => {
    let access_token = Cookies.get("access_token", { domain: window.location.hostname });
    if (access_token == "null" || access_token === undefined) {
    } else {
      getProfile();
      getSelectCountries()
    }
  
  }, []);

  const getSelectCountries = () => {
    api.users.getCountries(
      (response) => {
        if (response.status !== undefined && response.status === 200) { 
          setReccord(_.get(response,'data.data.record'));
        }
      },
      (error) => {
        setError(error);
      },
    );
  }

  useEffect(() => {}, [reccord]);

  const getProfile = (id) => {
    api.users.getProfile(
      (response) => {
        let data = response.data;

        setProfile(data.data);
        let firstName = data.data.first_name;
        let lastName = data.data.last_name;
        let email = data.data.email;
        let username = data.data.username;
        let bio = data.data.bio;
        let userAvatar = data.data.avatar.url;
        let phone = _.get(data, 'data.phone')
        setValue("first_name", firstName);
        setValue("last_name", lastName);
        setValue("phoneNumber", phone)
        setValue("email", email);
        setValue("username", username);
        setValue("bio", bio);
        setValue("country_code", "TH")
        setValue("phone_code", 66)
        setVerified(phone)
        // setAvatar(userAvatar);
      },
      (error) => {
        setError(error);
      },
      []
    );
  };

  async function onClickRequestOtp() {
    let params = "?phone=" + getValues("phoneNumber") + "&country_code=" + getValues("country_code") + "&type=verify"
    await api.otp.checkPhone( async (response)=>{
      if(response){

        let request = {
          "country_code": getValues("country_code"),
          "mobile": getValues("phoneNumber"),
          "channel": "sms",
          "digit": 4
        }
    
        await api.otp.requestOtp(
          (response) => {
            if(response){
              let otpData = _.get(response,'data.data');
              setValue("ref", _.get(otpData, "ref"));
              handleCloseModalVerify()
              handleShowModalOtp()
            }
          },
          (error) => {
            setError(error);
          },
          request
        );
      }
    }, (error)=>{
      setError(error)
      setValue("isDuplicate", true)
    }, params)
  }

  function startCountDown(){
    setCountdown({ date: Date.now(), delay: wantedDelay })
    localStorage.setItem(
      "end_date",
      JSON.stringify(Date.now() + wantedDelay)
    );
  }

  function onClikVerifyOtp(){
    setError()
    if(_.isEmpty(OTP) || OTP.length !== 4) {
      setError({data: {
        error: {
          message: "Please input OTP code"
        }
      }})
      return
    }

    let request = {
      "code": OTP,
      "country_code": getValues("country_code"),
      "mobile": getValues("phoneNumber")
    }
    
    api.otp.verifyOtp(
      (response) => {
        //The request is successful.
        sessionStorage.setItem("is_user_verify", true);
        handleCloseModalOtp()
        showFlashSuccess()

      },
      (error) => {
        setError(error);
        setValue("isWrong", true)
      },
      request
    );
  }

  async function onClickVerify(){
    setError()
    if(getValues("phoneNumber") === '' || getValues("phoneNumber") === null) return;
    let params = "?phone=" + getValues("phoneNumber") + "&country_code=" + getValues("country_code") + "&type=verify"
    await api.otp.checkPhone( async (response)=>{
      if(response){

        let request = {
          "country_code": getValues("country_code"),
          "mobile": getValues("phoneNumber"),
          "channel": "sms",
          "digit": 4
        }
    
        await api.otp.requestOtp(
          (response) => {
            if(response){
              let otpData = _.get(response,'data.data');
              setValue("ref", _.get(otpData, "ref"));
              // handleCloseModalVerify()
              handleShowModalOtp()
              startCountDown()
            }
          },
          (error) => {
            setError(error);
          },
          request
        );
      }
    }, (error)=>{
      setError(error)
      setValue("isDuplicate", true)
    }, params)

  }

  async function onclickResend(){

    setError()
    let request = {
      "country_code": getValues("country_code"),
      "mobile": getValues("phoneNumber"),
      "channel": "sms",
      "digit": 4
    }

    await api.otp.requestOtp(
      (response) => {
        if(response){
          let otpData = _.get(response,'data.data');
          setValue("ref", _.get(otpData, "ref"));
          handleCloseModalVerify()
          handleShowModalOtp()

          //Reset count down
          setCountdown({ date: Date.now(), delay: wantedDelay })
          localStorage.setItem(
            "end_date",
            JSON.stringify(Date.now() + countdown.delay)
          );
        }
      },
      (error) => {
        setError(error);
        checkOtpLimit()
      },
      request
    );
  }

  function checkOtpLimit(){
    if(_.get(error, 'data.error.message') === 'Your OTP request has a limit to per channel per day.'){
      setOtpLimit(true);
    }
  }

  const ModalSuccess = (props) => {
    return (
      <Modal
        {...props}
        className="modal-alert _flash-message"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <img src={iconSuccess} className="icon-success" />
            <h2>Verfication successful</h2>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  function onChangeCountry(e){
    let i = reccord.findIndex((v)=>{ return v.phone_code === e.target.value  })
    let country = reccord[i]
    setValue("country_code", _.get(country, 'iso_code', "TH"))
    setValue("phone_code", _.get(country, 'phone_code', 66))
  }

  const SelectContries = () => {
    return (
      <Form.Select aria-label="Default select" defaultValue={getValues("phone_code")} onChange={onChangeCountry} disabled={_.get(profile, 'phone')}>
        {    
         reccord.length > 0? reccord.map((item,index)=>(
          <option key={index} value={item.phone_code}>{item.iso_code} | {item.phone_code_text}</option>
          ))
          :null
        }
        </Form.Select>
    );
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {

    if(otpLimit){
      return <div></div>
    }
    
    if (completed) {
      return <a className="_red" href="#" style={{ textDecorationLine: 'underline' }} onClick={onclickResend}>Send OTP Again</a>;
    } else {
      return <span>{minutes > 9 ? minutes : '0' + minutes}:{seconds > 9 ? seconds : '0'+ seconds}</span>;
    }
  };

  const handleChangePhone = (e) => {
    let value = e.target.value;
    if(value.length > 4 && value.length < 16){
      setError()
      setBtnVerifyStatus(false)
    }else{
      setBtnVerifyStatus(true)
    }

  };

  useEffect(() => {
    const savedDate = getLocalStorageValue("end_date");
    if (savedDate != null && !isNaN(savedDate)) {
      const currentTime = Date.now();
      const delta = parseInt(savedDate, 10) - currentTime;

      //Do you reach the end?
      if (delta > wantedDelay) {
        //Yes we clear uour saved end date
        if (localStorage.getItem("end_date").length > 0)
          localStorage.removeItem("end_date");
      } else {
        //No update the end date  
        setCountdown({ date: currentTime, delay: delta });
      }
    }
  }, []);

  return (
    <React.Fragment>
      <h2 className="edit-profile">EDIT PROFILE {verified === null || verified === '' ?(<span><i className="_icon _icon-alert"></i> *Please verify your claim for rare and super rare NFT with an OTP code</span>):null}</h2> 
      <div className="address _settings">
        <div className="general-setting-box">
          <Form className="login-email-form" onSubmit={handleSubmit(Submit)}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>FIRST NAME</Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      placeholder="Enter Your First name"
                      {...register("first_name", {
                        required: true,
                        minLength: 3,
                        maxLength: 50,
                      })}
                    />
                    <div className="text-danger">
                      {errors.first_name?.message}
                      {errors.first_name?.type === "required" &&
                        "First name is required"}
                      {errors.first_name?.type === "minLength" &&
                        "Enter a first name that is at least 3 letters "}
                      {errors.first_name?.type === "maxLength" &&
                        "Value must be less than 50 characters"}
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>LAST NAME</Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      placeholder="Enter Your Last name"
                      {...register("last_name", {
                        required: true,
                        minLength: 3,
                        maxLength: 50,
                      })}
                    />
                    <div className="text-danger">
                      {errors.last_name?.message}
                      {errors.last_name?.type === "required" &&
                        "Last name is required"}
                      {errors.last_name?.type === "minLength" &&
                        "Enter a Last name that is at least 3 letters "}
                      {errors.last_name?.type === "maxLength" &&
                        "Value must be less than 50 characters"}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>USERNAME</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    placeholder="Enter Your Username"
                    {...register("username", {
                      required: true,
                      minLength: 3,
                      maxLength: 50,
                    })}
                  />
                  <div className="text-danger">
                    {errors.username?.message}
                    {errors.username?.type === "required" &&
                      "Username is required"}
                    {errors.username?.type === "minLength" &&
                      "Enter a username that is at least 3 letters "}
                    {errors.username?.type === "maxLength" &&
                      "Value must be less than 50 characters"}
                  </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>PHONE NUMBER</Form.Label>
                  <div className="d-flex">
                    <div className="_contries-list"> <SelectContries/></div>
                      <div className="phone-box">
                        <Form.Control
                          type="number"
                          placeholder="Enter Phone no."
                          {...register("phoneNumber", {
                            required: getValues("phoneNumber") ? true : false,
                            minLength: 9,
                            maxLength: 15,
                            disabled: !_.isEmpty(_.get(profile, 'phone')),
                            onChange: (value) => handleChangePhone(value)
                          })}
                        />

                        { 
                          _.get(profile, 'phone')
                            ? <img src={iconVerify} className="icon-verify"/>  
                            : <Button type="button" className="verify-btn" onClick={errors.phoneNumber  ? null : onClickVerify} disabled={btnVerifyStatus}>VERIFY</Button>
                        }
                      </div>
                    </div>
                      <div className="text-danger">
                          {errors.phoneNumber?.message}
                          {errors.phoneNumber?.type === "minLength" &&
                            "Enter a phone number that is at least 9 letters "}
                          {errors.phoneNumber?.type === "maxLength" &&
                            "Value must be less than 15 characters"}
                        </div>

                        { error && !showModalOtp
                          ? <p style={{ color: 'red', marginBottom: 0}}>{_.get(error,'data.error.message')}</p>
                          : null }

                        { !_.get(profile, 'phone') 
                          ? error 
                          ? null 
                          : showModalOtp ? null : <p style={{ color: 'red',  }}>*Verify your identity with OTP code</p>
                          : null }

                    </Form.Group>
                    

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>EMAIL ADDRESS</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    //   value={profile == null ? "" : profile.email}
                    {...register("email", {
                      required: true,
                      minLength: 3,
                      maxLength: 200,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address",
                      },
                    })}
                  />
                  <div className="text-danger">
                    {errors.email?.message}
                    {errors.email?.type === "required" && "Email is required"}
                    {errors.email?.type === "minLength" &&
                      "Enter a email that is at least 3 letters "}
                    {errors.email?.type === "maxLength" &&
                      "Value must be less than 50 characters"}
                  </div>
                </Form.Group>

                <Form.Group className="mt-3 mb-3" controlId="formBasicEmail">
                  <Form.Label>BIO</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="bio"
                    placeholder="BIO"
                    {...register("bio", {
                      required: false,
                      minLength: 3,
                      maxLength: 50,
                    })}
                  />
                </Form.Group>

              </Col>

              <Col md={6}>
              <p>AVATAR</p>
                {avatar == null ? (
                  selectedFile == null ? (
                    <div className="avatar-box">
                      <input
                        type="file"
                        id="imageUpload"
                        className="avatar-inputfile"
                        onChange={onSelectFile}
                      />
                      <label  htmlFor="imageUpload"></label>
                      <div className="avatar-box-upload"></div>
                    </div>
                  ) : (
                    <div className="avatar-box">
                      <input
                        type="file"
                        id="imageUpload"
                        className="avatar-inputfile"
                        onChange={onSelectFile}
                      />
                      <label  htmlFor="imageUpload"></label>
                      <img src={preview} className="avatar-box-img" />
                      {/* <div className="avatar-box-upload"></div> */}
                    </div>
                  )
                ) : isSelectAvatar ? (
                  <div className="avatar-box">
                    <input
                      type="file"
                      id="imageUpload"
                      className="avatar-inputfile"
                      onChange={onSelectFile}
                    />
                    <label  htmlFor="imageUpload"></label>
                    <img src={preview} className="avatar-box-img" />
                    {/* <div className="avatar-box-upload"></div> */}
                  </div>
                ) : (
                  <div className="avatar-box">
                    <input
                      type="file"
                      id="imageUpload"
                      className="avatar-inputfile"
                      onChange={onSelectFile}
                    />
                    <label htmlFor="imageUpload"></label>
                    <img src={avatar.url} className="avatar-box-img" />
                    {avatar.url? null :<div className="avatar-box-upload"></div>  }
                  </div>
                )}
                <div className="mt-2 --font-medium">
                    Recommended size: 250x250 px.<br/>
                    File type supported : jpg, png. max size: 5MB
                </div>

                <p className="mt-3 pt-2 border-top">COVER </p>
                {cover == null ? (
                  coverSelectedFile == null ? (
                    <div className="avatar-box">
                      <input
                        type="file"
                        id="coverUpload"
                        className="avatar-inputfile"
                        onChange={onSelectCoverFile}
                      />
                      <label  htmlFor="coverUpload"></label>
                      <div className="avatar-box-upload _cover"></div>
                    </div>
                  ) : (
                    <div className="cover-box">
                      <input
                        type="file"
                        id="coverUpload"
                        className="avatar-inputfile"
                        onChange={onSelectCoverFile}
                      />
                      <label htmlFor="coverUpload"></label>
                      <img src={coverPreview} className="cover-img" />
                    </div>
                  )
                ) : isSelectCover ? (
                  <div className="cover-box">
                    <input
                      type="file"
                      id="coverUpload"
                      className="avatar-inputfile"
                      onChange={onSelectCoverFile}
                    />
                    <label  htmlFor="coverUpload"></label>
                    <img src={coverPreview} className="cover-img" />
                  </div>
                ) : (
                  <div className="cover-box">
                    <input
                      type="file"
                      id="coverUpload"
                      className="avatar-inputfile"
                      onChange={onSelectCoverFile}
                    />
                    <label htmlFor="coverUpload"></label>
                    <img src={cover} className="cover-img" />
                  </div>
                )}

                <div className="mt-2 --font-medium">
                    Recommended size: 1400x350 px.<br/>
                    File type supported : jpg, png. max size: 5MB
                </div>

              </Col>
              <div className="text-center pt-3">
                <Button className="btn-nft" type="submit">
                  SAVE
                </Button>
              </div>
            </Row>

          </Form>
        </div>
      </div>

      {/* <ModalVerify show={showModalVerify} onHide={handleCloseModalVerify}></ModalVerify>
      <ModalOtp show={showModalOtp} onHide={handleCloseModalOtp}></ModalOtp> */}

      <Modal
        backdrop="static"
        keyboard={false}
        className="modal-verify"
        show={showModalOtp} onHide={()=>{
          handleCloseModalOtp()
          setOTP()
          setError()
        }}
      >
        <Modal.Header>
          <Modal.Title>Verify your identity with OTP code</Modal.Title>
          <i className="_icon _icon-close cursor-pointer" onClick={()=>{
            handleCloseModalOtp()
            setOTP()
            setError()
          }}></i>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <p className="_font-medium">
              An OTP code will be sent via SMS to the mobile number <br/>
              <span className="m-number d-block mt-2">{getValues("phoneNumber")}</span>
              Reference code: {getValues("ref")}
            </p>
            <div className="otp-input text-center">
              <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" inputClassName="otp" disabled={false}  />
            </div>

             {  error
                ? <div style={{ color: 'red' }}>{_.get(error,'data.error.message')}</div> 
                : null
              }

              {/* {
                (_.isEmpty(OTP) || OTP.length !== 4) ? <div className="_red">Please input OTP code</div> : null
              } */}

            <div className="otp-again _gray">
              If you haven’t received an identity  verification code, try pressing below 
              <span className="count-d-otp d-block _red mt-2">
                {/* <ResendOTP maxTime={120} onResendClick={ onclickResend } className="resend-otp" /> */}
                <Countdown 
                key={Date.now()} 
                date={countdown.date + countdown.delay}
                renderer={renderer} 
                autoStart={true}
                onStart={(delta) => {
                  checkOtpLimit()
                  //Save the end date
                  if (localStorage.getItem("end_date") == null)
                    localStorage.setItem(
                      "end_date",
                      JSON.stringify(countdown.date + countdown.delay)
                    );
                }}
                onComplete={() => {
                  if (localStorage.getItem("end_date") != null)
                    localStorage.removeItem("end_date");
                }}
                />
              </span>
              {/* <span className="send-otp-again d-block _red _font-medium-x">Send again</span> */}
            </div>
            <Button onClick={onClikVerifyOtp} className="btn-nft mt-3">
              CONFIRM
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal Request otp */}
      <Modal
        backdrop="static"
        keyboard={false}
        className="modal-verify"
        show={showModalVerify} 
        onHide={()=>{
          handleCloseModalVerify()
          setError()
        }}
      >
        <Modal.Header>
          <Modal.Title>Please confirm your identity to proceed</Modal.Title>
          <i className="_icon _icon-close cursor-pointer" onClick={()=>{
            handleCloseModalVerify()
            setError()
          }}></i>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <p className="_font-medium-x _gray">Your mobile number</p>
            <div className="mobile-number">
                  {getValues("phoneNumber")}
            </div>
            { error ? <div style={{ color: 'red' }}>{_.get(error,'data.error.message')}</div> : null}

            <Button onClick={onClickRequestOtp} className="btn-nft mt-3">
              Request verification code
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <ModalSuccess show={showModalSuccess} onHide={handleCloseSuccess}></ModalSuccess>

    </React.Fragment>
  );
};

export default GeneralSetting;
