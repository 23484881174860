"use strict";

import _ from "lodash";
import Cookies from "js-cookie";

export default {
  getCollections(cb, errorCb, request = "") {
    window.axios
      .get(`v1.1/collections${request}`)
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },

  getCollectionDetail(cb, errorCb, request = {}) {
    let header = {
      "Accept-Language": "en-US",
      "Content-Type": "application/json",
    };
    const id = _.get(request, "id");

    var params = {};
    let access_token = Cookies.get("access_token", { domain: window.location.hostname });
    if (access_token == "null" || access_token === undefined) {
    } else {
      let isLogin = { is_login: true };
      params = Object.assign({}, isLogin);
    }

    window.axios
      .get("v1.1/collections/" + id, { headers: header, params: params })
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },

  getMyCollections(cb, errorCb, request = "") {
    window.axios
      .get(`v1.1/users/my/collections${request}`)
      .then((response) => cb(response))
      .catch((err) => {
        if (typeof errorCb === "function") {
          errorCb(err.response);
        }
      });
  },
};
