import React from "react";
import { Container } from "react-bootstrap";
import HomeContent from "../components/HomeContent";
// import AirDropNft from "../pages/AirDropNft";
import "../assets/sass/home_page.scss";
import { Link } from "react-router-dom";
import Banner from "../components/Banner";
// import ReactGA from "react-ga";

const HomePage = () => {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname)
  // }, [])

  return (
    <div className="home-section">
      <div className="home-banner">
        <Container>
          <div className="nft-tcc-discover">
            <h2>THECONCERT.IO</h2>
            <p>
              <span className="d-block">NFT Wallet & Platform </span>
              For Music, Concert And NightLife Community
            </p>
            <Link to='/discover'>
              <button type="tutton" className="btn-discover">
                DISCOVER
            </button>
            </Link>

          </div>
          </Container>
        </div>

        {/* <Container> */}
          {/* <Countdown deadline={deadline} /> */}
          <Banner/>
          {/* <AirDropNft/> */}
      {/* </Container> */}
      <Container>
      <HomeContent />
      </Container>
    </div>
  );
};

export default HomePage;
