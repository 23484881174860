import React from "react"
import Header from "../HeaderMain";
import Footer from "../Footer";

class Layout extends React.Component {
  render(){
    return (
      <>
        <React.StrictMode>
          <Header />
          <main>{this.props.children}</main>
          <Footer />
        </React.StrictMode>
      </>
    )
  }
}
export default Layout;