import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { CardNFT } from "./CardNFT";
import "../assets/sass/profile_page.scss";

export const ProfileItemNft = ({ nftList }) => {
  const uniqueIds = new Set();

  const unique = nftList.filter((element) => {
    const isDuplicate = uniqueIds.has(element.token_hash);

    uniqueIds.add(element.token_hash);

    if (!isDuplicate) {
      return true;
    }

    return false;
  });

  if (unique.length == 0) {
    return (
      <Container>
        <div className="text-center mt-5 pt-5">No Item</div>
      </Container>
    );
  }

  return unique?.map((data, index) => (
    <Col xs={12} md={4} lg={4} key={index}>
      <CardNFT data={data} />
    </Col>
  ));
};
