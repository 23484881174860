import axios from "axios";
import React, { createContext, useEffect } from "react";
import env from "../env";

export const TheConcertApiClientContext = createContext({});

export const TheConcertApiClientProvider = ({
  requestInterceptors = [],
  responseInterceptors = [],
  children,
}) => {
  const moralisApi = axios.create({
    baseURL: `${env.REACT_APP_MORALIS_ENDPOINT_URL}`,
    timeout: 1000,
    headers: { "X-API-Key": env.REACT_APP_MORALIS_ENDPOINT_URL },
  });

  useEffect(() => {
    requestInterceptors.forEach((interceptor) => {
      moralisApi.current.interceptors.request.use(interceptor);
    });
    responseInterceptors.forEach((interceptor) => {
      moralisApi.current.interceptors.response.use(interceptor);
    });
  }, []);

  return (
    <TheConcertApiClientContext.Provider value={moralisApi}>
      {children}
    </TheConcertApiClientContext.Provider>
  );
};
