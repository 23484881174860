import React from "react";
import { Container, Row, Col, Tab, Tabs, Nav } from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import "../assets/sass/profile_page.scss";


const PageNotFound = () => {
  
  return (
    <Layout>
      <div className="page-not-found">
        <Container>
          <h2 className="not-found">404</h2>
           <h2>PAGE NOT FOUND</h2>
           <p>Sorry, the page you're looking for doesn't exist.</p>
           <a href="/" className="btn-nft">RETURN HOME</a>
        </Container>
      </div>
    </Layout>
  );
};

export default PageNotFound;
